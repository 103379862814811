
import {colors} from '@assets/colors'
function DistributionBar(props){

    return <div className="w-100 d-flex rounded overflow-hidden">
            {props.data?.map((obj, index)=>
            <div  style={{flex: obj.percentage/100, height:'6px', backgroundColor: colors[index]}}></div>)}
        </div>
}

export default DistributionBar