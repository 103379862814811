import React, { Suspense, useEffect } from "react";
import { Await } from "react-router-dom";
import IndeterminateCheckbox from "../IndeterminateCheckbox";
import { Skeleton, AspectRatio } from "@mui/joy";
import "./style.css";
import { useSearchParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
  flexRender,
} from "@tanstack/react-table";

import nextIcon from "@assets/common/tableNext.png";
import prevIcon from "@assets/common/tablePrevious.png";
import lastIcon from "@assets/common/tableLast.png";
import firstIcon from "@assets/common/tableFirst.png";
import ASCIcon from "@assets/common/ascSortArrowIcon.png";
import DSCIcon from "@assets/common/descSortArrowIcon.png";
import NoSortIcon from "@assets/common/noSort.png";

function BasicTableGridView(props) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const limit = Number(queryParams.get("limit"));
  const page = Number(queryParams.get("page"));

  const [searchParams, setSearchParams] = useSearchParams();
  const {
    gridId,
    fixedCols,
    handleSelect,
    data,
    length,
    columnsOptionPopUpRef,
    stopPagination
  } = props.props;
  const {
    columns,
    isEffectComplete,
    tableData,
    setTableData,
    sorting,
    setSorting,
    columnVisibility,
    setColumnVisibility,
  } = props;

  const [rowSelection, setRowSelection] = React.useState({});
  const [pagination, setPagination] = React.useState({
    pageIndex: page ? page : 0,
    pageSize: limit ? limit : 20,
  });
  const [expanded, setExpended] = React.useState({});
  const [scrollY, setScrollY]=React.useState({});

  const table = useReactTable({
    data:
      tableData?.data ??
      Array.from({ length: pagination.pageSize }, () => ({})),
    columns,
    columnResizeMode: "onChange",
    pageCount: tableData?.totalDocCount
      ? tableData.totalDocCount / tableData.pageLimit
      : 1,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    state: {
      rowSelection: rowSelection,
      pagination,
      sorting,
      columnVisibility,
      expanded,
    },
    enableSortingRemoval: false,
    sortDescFirst: false,
    onSortingChange: setSorting,
    onColumnVisibilityChange: setColumnVisibility,
    onExpandedChange: setExpended,
    getSubRows: (row) => row.data,
    meta: {
      updateData: (response) => {
        if (response.data) {
          setTableData(response);
        }
      },
    },

    manualPagination: true,
    enableRowSelection: true,
    onPaginationChange: stopPagination? ()=>{} : setPagination,
    onRowSelectionChange: setRowSelection,
    autoResetSelectedRows: true,
  });

  let selectedList = [];
  let colSizeList = [];

  useEffect(() => {
    table.resetRowSelection();
    if(!stopPagination){
      setSearchParams({
        page: Math.ceil(table.getState().pagination.pageIndex),
        limit: table.getState().pagination.pageSize,
      });
    } 
  }, [pagination]);

  useEffect(() => {
    table.getSelectedRowModel().flatRows.map((selection) => {
      selectedList.push(selection.original);
    });
    handleSelect(selectedList);
  }, [table.getSelectedRowModel().flatRows.length]);

  return (
    <>
      <div className="columnOption" ref={columnsOptionPopUpRef}>
        {table.getAllLeafColumns().map((column, index) => {
          return (
            <div key={index}>
              <input
                {...{
                  type: "checkbox",
                  checked: column.getIsVisible(),
                  onChange: column.getToggleVisibilityHandler(),
                }}
              />
              <span>{column.id}</span>
            </div>
          );
        })}
      </div>

      <div className="main">
        <div className="tableBody" 
        // onScroll={(e) => setScrollY(e.target.scrollLeft)}
        >
          <Suspense
            fallback={
              <div style={{ width: "100%" }}>
                {table.getHeaderGroups().map((headerGroup) => {
                  colSizeList = [];
                  return (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header, index) => {
                        const headerWidth = header.getSize();
                        colSizeList.push(headerWidth);
                      })}
                    </tr>
                  );
                })}
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((row, index) => (
                  <tr key={index}>
                    {[1, 2, 3, 4, 5, 6].map((cell, index) => (
                      <td
                        key={index}
                        style={{
                          width: "20%", // Set initial width or use dynamic size
                          //  left: index < fixedCols ? cell.column.getStart() : 0,
                          borderRight:
                            index === fixedCols - 1 ? "6px solid #f5f5f5" : "",
                          padding: 5,
                        }}
                      >
                        <AspectRatio ratio={"21/4"}>
                          <Skeleton></Skeleton>
                        </AspectRatio>
                      </td>
                    ))}
                  </tr>
                ))}
              </div>
            }
          >
            <Await resolve={data?.data?.res}>
              {(response) => {
                if (response && response.data) {
                  var isUpdated = table.options.meta.updateData(response);
                  return (
                    <div
                      className="overflow-x-auto"
                      style={{ height:'fit-content'}}
                    >
                      {table.getHeaderGroups().map((headerGroup) => {
                        colSizeList = [];
                        return (
                          <tr key={headerGroup.id}  
                          style={{position:"sticky", zIndex:200, top:0}}
                          >
                            {headerGroup.headers.map((header, index) => {
                              const headerWidth = header.getSize();
                              colSizeList.push(headerWidth);
                              return (
                                <th
                                  key={header.id}
                                  className={`${
                                    index === 0 ? "checkBoxCol" : ""
                                  }`}
                                  colSpan={header.colSpan}
                                  style={{
                                    width: header.getSize(),
                                    position:
                                      index < fixedCols ? "sticky" : "relative",
                                    left:
                                      index < fixedCols ? header.getStart()  : - scrollY,
                                    top:0,
                                    borderRight:
                                      index === fixedCols - 1
                                        ? "6px solid #f5f5f5"
                                        : "",
                                    zIndex: index < fixedCols ? 10 : 0,
                                    backgroundColor: "white",
                                    cursor: header.column.getCanSort()
                                      ? "pointer"
                                      : "default",
                                    overflow: "hidden",
                                    
                                  }}
                                >
                                  <div
                                    onClick={header.column.getToggleSortingHandler()}
                                    className="d-flex align-items-center"
                                  >
                                    <div style={{ flexShrink: 1 }}>
                                      {flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                      )}
                                    </div>
                                    <img
                                      className="sortIcon"
                                      src={
                                        header.column.getCanSort()
                                          ? header.column.getIsSorted()
                                            ? { asc: ASCIcon, desc: DSCIcon }[
                                                header.column.getIsSorted()
                                              ]
                                            : NoSortIcon
                                          : ""
                                      }
                                    ></img>
                                  </div>
                                  <div
                                    onMouseDown={header.getResizeHandler()}
                                    onTouchStart={header.getResizeHandler()}
                                    className="resizer"
                                  ></div>
                                </th>
                              );
                            })}
                          </tr>
                        );
                      })}
                      {table.getRowModel().rows.map((row, index) => (
                        <tr key={row.id} 
                        // style={index===0?{marginTop:'40px'}:{}}
                         >
                          {row.getCanExpand() ? (
                            //For the row group
                            <>
                              <td
                                className="checkBoxCol"
                                style={{
                                  width: row
                                    .getVisibleCells()[0]
                                    .column.getSize(), // Set initial width or use dynamic size
                                  position: 0 < fixedCols ? "sticky" : "static",
                                  left:
                                    0 < fixedCols
                                      ? row
                                          .getVisibleCells()[0]
                                          .column.getStart()
                                      : 0,
                                  borderRight:
                                    0 === fixedCols - 1
                                      ? "6px solid #f5f5f5"
                                      : "",
                                  zIndex: 0 < fixedCols ? 2 : "auto",
                                }}
                              >
                                <div className="showOnHover">
                                  {flexRender(
                                    row.getVisibleCells()[0].column.columnDef
                                      .cell,
                                    row.getVisibleCells()[0].getContext()
                                  )}
                                </div>
                              </td>
                              <td
                                className="checkBoxCol"
                                style={{
                                  width:
                                    row.getVisibleCells()[1].column.getSize() +
                                    row.getVisibleCells()[2].column.getSize(), // Set initial width or use dynamic size
                                  position: 1 < fixedCols ? "sticky" : "static",
                                  left:
                                    1 < fixedCols
                                      ? row
                                          .getVisibleCells()[1]
                                          .column.getStart()
                                      : 0,
                                  borderRight:
                                    1 === fixedCols - 1
                                      ? "6px solid #f5f5f5"
                                      : "",
                                  zIndex: 1 < fixedCols ? 2 : "auto",
                                }}
                              >
                                <div className="showOnHover">
                                  {flexRender(
                                    row.getVisibleCells()[1].column.columnDef
                                      .cell,
                                    row.getVisibleCells()[1].getContext()
                                  )}
                                </div>
                              </td>
                            </>
                          ) : (
                            row.getVisibleCells().map((cell, index) => (
                              <td
                                key={cell.id}
                                className={`${
                                  index === 0 ? "checkBoxCol" : ""
                                }`}
                                style={{
                                  width: cell.column.getSize(), // Set initial width or use dynamic size
                                  position:
                                    index < fixedCols ? "sticky" : "static",
                                  left:
                                    index < fixedCols
                                      ? cell.column.getStart()
                                      : 0,
                                  borderRight:
                                    index === fixedCols - 1
                                      ? "6px solid #f5f5f5"
                                      : "",
                                  zIndex: index < fixedCols ? 2 : "auto",
                                }}
                              >
                                <div className="showOnHover">
                                  {flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext()
                                  )}
                                </div>
                              </td>
                            ))
                          )}
                        </tr>
                      ))}
                    </div>
                  );
                }
              }}
            </Await>
          </Suspense>
        </div>
        {props.props.isNavigationHidden ? (
          ""
        ) : (
          <div className="tableFooter">
            <span>Show Rows</span>
            <select
              value={table.getState().pagination.pageSize}
              onChange={(e) => table.setPageSize(Number(e.target.value))}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
              <option value={500}>500</option>
              <option value={1000}>1000</option>
            </select>
            <span>
              {table.getState().pagination.pageSize *
                (table.getState().pagination.pageIndex + 1) -
                table.getState().pagination.pageSize}{" "}
              -
              {table.getState().pagination.pageSize *
                (table.getState().pagination.pageIndex + 1)}{" "}
              of {Math.trunc(table.getPageCount() * pagination.pageSize)}
            </span>
            <button
              disabled={!table.getCanPreviousPage()}
              onClick={() => {
                table.setPageIndex(0);
              }}
            >
              <img src={firstIcon} alt={"First"} />
            </button>
            <button
              disabled={!table.getCanPreviousPage()}
              onClick={() => {
                table.previousPage();
              }}
            >
              <img src={prevIcon} alt={"Prev"} />
            </button>
            <button
              disabled={!table.getCanNextPage()}
              onClick={() => {
                table.nextPage();
              }}
            >
              <img src={nextIcon} alt={"Next"} />
            </button>
            <button
              disabled={!table.getCanNextPage()}
              onClick={() => {
                table.setPageIndex(table.getPageCount());
              }}
            >
              <img src={lastIcon} alt={"Last"} />
            </button>
          </div>
        )}
      </div>
      {/* {table.getSelectedRowModel().flatRows.map((selection) => {
        selectedList.push(selection.original);
      })}
      {handleSelect(selectedList)}
      {(selectedList = [])} */}

      {
        //set only after useeffect is done
        columns.length && isEffectComplete
          ? localStorage.setItem(gridId, JSON.stringify(colSizeList))
          : ""
      }
    </>
  );
}

export default BasicTableGridView;
