import React, { useEffect } from "react";
import { Page, Text, Image, Document, StyleSheet } from "@react-pdf/renderer";

import "./style.css";
import BssLogo from "@assets/common/bssLogo.png";
import moment from "moment";

function BSSForm(props) {
  const [passportPhoto, setPassportPhoto] = React.useState();
  const data = props.data;
  const permanentAddress =
    data.flatHouseNumber +
    ", " +
    data.buildingName +
    ", " +
    data.colonyName +
    ", " +
    data.areaName +
    ", " +
    data.landmark +
    ", " +
    data.city +
    ", " +
    data.state +
    ", " +
    data.country;
  const communicationAddress =
    data.flatHouseNumber +
    ", " +
    data.buildingName +
    ", " +
    data.colonyName +
    ", " +
    data.areaName +
    ", " +
    data.landmark +
    ", " +
    data.city +
    ", " +
    data.state +
    ", " +
    data.country;

  return (
    <>
      {data ? (
        data.bssCourseDetails ? (
          <div className="bssForm">
            {/* Page 1 */}
            <div className="bssHeader">
              <img src={BssLogo} className="icon" />
              <div className="d-flex justify-content-center align-items-center flex-column">
                <div
                  className="timesFont"
                  style={{ fontSize: "25px", fontWeight: "bold" }}
                >
                  BHARAT SEVAK SAMAJ
                </div>
                <div
                  className="w-100 text-center"
                  style={{
                    fontSize: "8px",
                    fontWeight: "bold",
                    wordSpacing: "2px",
                  }}
                >
                  NATIONAL DEVELOPMENT AGENCY, PROMOTED BY GOVT. OF INDIA
                </div>
                <div
                  className="w-100 text-center"
                  style={{
                    fontSize: "10px",
                    fontWeight: "bold",
                    marginTop: "5px",
                  }}
                >
                  NATIONAL VOCATIONAL EDUCATION MISSION <br></br>REGISTRATION
                  CUM EXAMINATION APPLICATION
                </div>

                <div
                  className="w-100 text-center"
                  style={{
                    fontSize: "10px",
                    fontWeight: "normal",
                    wordSpacing: "1px",
                  }}
                >
                  (FILL UP IN CAPITAL LETTERS)
                </div>
              </div>
              <div
                className="passportPhoto"
                style={{ backgroundImage: data.displayPhoto }}
              >
                <img crossorigin="anonymous" src={data.displayPhoto} />
              </div>
            </div>
            <span
              style={{
                fontSize: "10px",
                fontWeight: "normal",
                wordSpacing: "1px",
                marginTop: "-15px",
                position: "absolute",
                width: "300px",
              }}
            >
              Date: {moment(data.dateOfSignature).format("Do MMM, YYYY")}
            </span>
            <table className="mt-2">
              <tr>
                <td>Institution Approval Number</td>
                <td>{data.bssInstituteDetails?.approvalCode}</td>
              </tr>
              <tr height="60px">
                <td>
                  Institution Name & Full Address <br></br>with Pin Code
                </td>
                <td>
                  {data.bssInstituteDetails?.name} <br></br>{" "}
                  <span style={{ fontWeight: "400" }}>
                    {data.bssInstituteDetails?.address},{" "}
                    {data.bssInstituteDetails?.district},{" "}
                    {data.bssInstituteDetails?.state}{" "}
                    {data.bssInstituteDetails?.pincode}{" "}
                  </span>
                </td>
              </tr>
              <tr>
                <td>Student Name in English</td>
                <td>{data.fullName}</td>
              </tr>
              <tr>
                <td>Date of Birth</td>
                <td>{moment.utc(data.dob).format("Do MMM, YYYY")}</td>
              </tr>
              <tr>
                <td>Sex</td>
                <td
                  style={{
                    fontSize: "9px",
                    paddingTop: "2px",
                    paddingBottom: "2px",
                  }}
                >
                  <span
                    className={
                      data.gender === "male" || data.gender === "Male"
                        ? "tickOption tick"
                        : "tickOption"
                    }
                  >
                    Male
                  </span>
                  <span
                    className={
                      data.gender === "female" || data.gender === "Female"
                        ? "tickOption tick"
                        : "tickOption"
                    }
                  >
                    Female
                  </span>{" "}
                </td>
              </tr>
              <tr>
                <td>Name of guardian</td>
                <td>{data.guardianName}</td>
              </tr>
              <tr height="60px">
                <td>Permanent Address</td>
                <td style={{ overflowWrap: "break-word" }}>
                  {permanentAddress}
                </td>
              </tr>
              <tr height="60px">
                <td>Address of communication</td>
                <td>{communicationAddress}</td>
              </tr>
              <tr>
                <td>Name of the course</td>
                <td>{data.bssCourseDetails?.title}</td>
              </tr>
              <tr>
                <td>Course duration</td>
                <td
                  style={{
                    fontSize: "7px",
                    paddingTop: "2px",
                    paddingBottom: "2px",
                  }}
                >
                  <span
                    className={
                      data.bssCourseDetails?.duration.includes("month")
                        ? "tickOption tick"
                        : "tickOption"
                    }
                  >
                    {data.bssCourseDetails?.duration.includes("month")
                      ? data.bssCourseDetails.duration
                      : "Months"}
                  </span>
                  <span
                    className={
                      data.bssCourseDetails?.duration == "One Year"
                        ? "tickOption tick"
                        : "tickOption"
                    }
                  >
                    One Year
                  </span>
                  <span
                    className={
                      data.bssCourseDetails?.duration == "Two Year I year"
                        ? "tickOption tick"
                        : "tickOption"
                    }
                  >
                    Two Year<br></br>I Year
                  </span>
                  <span
                    className={
                      data.bssCourseDetails?.duration == "Two Year II year"
                        ? "tickOption tick"
                        : "tickOption"
                    }
                  >
                    Two Year<br></br>II Year
                  </span>
                  <span
                    className={
                      data.bssCourseDetails?.duration == "Direct II Year"
                        ? "tickOption tick"
                        : "tickOption"
                    }
                  >
                    Direct<br></br>II year
                  </span>
                </td>
              </tr>
              <tr>
                <td>Examination for which year</td>
                <td>
                  <div className="d-flex gap-2">
                    <div className="d-flex">
                      {Array.from(Array(4).keys()).map((index) => {
                        return (
                          <div className="charBox" key={index}>
                            {data.bssCourseDetails?.fromYear
                              ? String(data.bssCourseDetails?.fromYear)[index]
                              : ""}
                          </div>
                        );
                      })}
                    </div>
                    <div className="d-flex">
                      {Array.from(Array(4).keys()).map((index) => {
                        return (
                          <div className="charBox" key={index}>
                            {data.bssCourseDetails?.toYear
                              ? String(data.bssCourseDetails?.toYear)[index]
                              : ""}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </td>
              </tr>
            </table>
            <div
              className="bssHeader align-items-start justify-content-center gap-3 mt-2 pt-2"
              style={{ borderTop: "1px dashed black" }}
            >
              <img src={BssLogo} className="icon" />
              <div className="d-flex justify-content-center align-items-center flex-column">
                <div
                  className="timesFont"
                  style={{ fontSize: "25px", fontWeight: "bold" }}
                >
                  BHARAT SEVAK SAMAJ
                </div>
                <div
                  className="w-100 text-center"
                  style={{ fontSize: "8px", fontWeight: "bold" }}
                >
                  NATIONAL DEVELOPMENT AGENCY, PROMOTED BY GOVT. OF INDIA
                </div>
                <div
                  className="w-100 text-center timesFont mt-2"
                  style={{ fontSize: "14px", fontWeight: "bold" }}
                >
                  HALL TICKET
                </div>
              </div>
              <div style={{ width: "50px" }}></div>
            </div>

            <div
              className="d-flex flex-column w-100 gap-1"
              style={{ fontSize: "9px" }}
            >
              <div className="ms-auto fillInBlank" style={{ width: "170px" }}>
                Reg no. <span></span>
              </div>
              <div className="d-flex gap-2">
                <div
                  className="d-flex flex-column justify-content-between py-2"
                  style={{ flexGrow: "1" }}
                >
                  <div className="fillInBlank">
                    Name of Candidate:<span>{data.fullName}</span>
                  </div>
                  <div className="fillInBlank">
                    Institution Address:
                    <span>
                      {data.bssInstituteDetails?.name} <br></br>
                      <span style={{ fontWeight: "400" }}>
                        {data.bssInstituteDetails?.address},{" "}
                        {data.bssInstituteDetails?.district},{" "}
                        {data.bssInstituteDetails?.state}{" "}
                        {data.bssInstituteDetails?.pincode}
                      </span>
                    </span>
                  </div>
                  <div className="fillInBlank">
                    Course Name:<span>{data.bssCourseDetails?.title}</span>
                  </div>
                  <div className="fillInBlank">
                    Duration:<span>{data.bssCourseDetails?.duration}</span>
                  </div>
                </div>
                <div
                  className="passportPhoto"
                  style={{ backgroundImage: data.displayPhoto }}
                >
                  <img crossorigin="anonymous" src={data.displayPhoto} />
                </div>
              </div>
              <div>
                <img
                  crossorigin="anonymous"
                  src={data.signature}
                  className="signature"
                />
                <br></br>
                <b>Signature of the Candidate</b>
              </div>
            </div>

            {/* Page 2 */}
            <div
              className="timesFont w-100 text-center"
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                marginTop: "70px",
              }}
            >
              BSS STUDENT ID CARD FORM
            </div>
            <div
              className="timesFont w-100 text-center"
              style={{ fontSize: "11px", fontWeight: "bold" }}
            >
              (MUST BE FILLEDUP IN CAPITAL LETTERS)
            </div>
            <div className="d-flex flex-column gap-3 mt-3">
              <div className="studentIdFiled">
                <div>STUDENT NAME:</div>
                <div className="d-flex flex-wrap" style={{ rowGap: "5px" }}>
                  {Array.from(Array(36).keys()).map((index) => {
                    return (
                      <div className="charBox" key={index}>
                        {data.fullName ? data.fullName[index] : ""}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="studentIdFiled">
                <div>COURSE NAME:</div>
                <div className="underlineBlank">
                  {data.bssCourseDetails?.title}
                </div>
              </div>
              <div className="d-flex gap-2">
                <div className="d-flex gap-2 flex-column" style={{ flex: 1 }}>
                  <div className="studentIdFiled mt-3">
                    <div>COURSE CODE:</div>
                    <div className="d-flex flex-wrap" style={{ rowGap: "5px" }}>
                      {Array.from(Array(10).keys()).map((index) => {
                        return (
                          <div className="charBox" key={index}>
                            {data.bssCourseDetails?.bssCourseCode
                              ? data.bssCourseDetails.bssCourseCode[index]
                              : ""}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="studentIdFiled">
                    <div>DATE OF BIRTH:</div>
                    <div className="d-flex flex-wrap" style={{ rowGap: "5px" }}>
                      {Array.from(Array(10).keys()).map((index) => {
                        return (
                          <div className="charBox" key={index}>
                            {data.dob
                              ? moment.utc(data.dob).format("DD-MM-YYYY")[index]
                              : ""}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="studentIdFiled mt-auto">
                    <div>BLOOD GROUP:</div>
                    <div className="underlineBlank">{data.bloodGroup}</div>
                  </div>
                </div>
                <div
                  className="passportPhoto"
                  style={{ backgroundImage: data.displayPhoto }}
                >
                  <img crossorigin="anonymous" src={data.displayPhoto} />
                </div>
              </div>

              <div className="studentIdFiled">
                <div className="w-100">STUDENT RESIDENTIAL</div>
              </div>
              <div className="studentIdFiled">
                <div style={{ width: "70px" }}>ADDRESS:</div>
                <div className="underlineBlank">
                  {data.flatHouseNumber + ", " + data.buildingName}
                </div>
              </div>
              <div className="studentIdFiled">
                <div style={{ width: "70px" }}></div>
                <div className="underlineBlank">
                  {data.colonyName + ", " + data.areaName}
                </div>
              </div>
              <div className="studentIdFiled">
                <div style={{ width: "70px" }}></div>
                <div className="underlineBlank">
                  {data.landmark + ", " + data.city}
                </div>
              </div>
              <div className="studentIdFiled">
                <div style={{ width: "70px" }}></div>
                <div className="underlineBlank">
                  {data.state + ", " + data.country}
                </div>
                <div className="d-flex flex-wrap" style={{ rowGap: "5px" }}>
                  PINCODE : &nbsp;
                  {Array.from(Array(6).keys()).map((index) => {
                    return (
                      <div className="charBox" key={index}>
                        {data.pincode ? String(data.pincode)[index] : ""}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="studentIdFiled">
                <div style={{ width: "160px" }}>INSTITUTION NAME:</div>
                <div className="underlineBlank">
                  {data.bssInstituteDetails?.name}
                </div>
              </div>
              <div className="studentIdFiled">
                <div style={{ width: "160px" }}>INSTITUTION DISTRICT:</div>
                <div className="underlineBlank">
                  {data.bssInstituteDetails?.district}
                </div>
              </div>
              <div className="studentIdFiled">
                <div style={{ width: "160px" }}>INSTITUTION PINCODE:</div>
                <div className="underlineBlank">
                  {data.bssInstituteDetails?.pincode}
                </div>
              </div>
              <div className="studentIdFiled">
                <div style={{ width: "160px" }}>INSTITUTION STATE:</div>
                <div className="underlineBlank">
                  {data.bssInstituteDetails.state}
                </div>
              </div>
              <div className="studentIdFiled">
                <div style={{ width: "160px" }}>INSTITUTION APPROVAL CODE:</div>
                <div className="d-flex flex-wrap" style={{ rowGap: "5px" }}>
                  {Array.from(Array(15).keys()).map((index) => {
                    return (
                      <div className="charBox" key={index}>
                        {data.bssInstituteDetails?.approvalCode[index]}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="studentIdFiled">
                <div style={{ width: "134px", fontWeight: "bold" }}>
                  IMPORTANT NOTE:{" "}
                </div>
                <div className="studentIdFiled">
                  ALL THE ABOVE DETAILS MUST BE FILLEDUP IN CAPITAL LETTERS
                  PROPERLY . INCOMPLETE AND MISTAKE ID CARD FORM WILL NOT BE
                  PROCESSED AND THE SAME, NOT NOTIFIED TO YOU.
                </div>
              </div>
            </div>

            {/* Page 3 */}
            <table className="" style={{ marginTop: "150px" }}>
              <tr height="60px">
                <td>
                  Address of School/College <br></br>in which the candidate last
                  studied<br></br>with Pin Code
                </td>
                <td>
                  {data.underGraduateInstituteName}
                  <br></br>
                  {data.underGraduateInstituteAddress}
                </td>
              </tr>
              <tr height="60px">
                <td>
                  Name of qualifying examination<br></br> passed with the Reg.
                  Number of <br></br>Govt. Mark Sheet and year of passing
                </td>
                <td>
                  {data.underGraduateDegree}
                  <br></br>
                  {data.registrationNumber}
                </td>
              </tr>
              <tr height="60px">
                <td>
                  The Board/University from which<br></br>the candidate passed
                  the qualifying<br></br>examination.
                </td>
                <td>{data.underGraduateInstituteName}</td>
              </tr>
              <tr height="60px">
                <td>
                  Details of documents enclosed <br></br>[Only Xerox Copies]
                </td>
                <td>Marksheet, Aadhar, PAN</td>
              </tr>
            </table>

            <div style={{ fontSize: "9px" }}>
              <div className="mt-3 text-center">
                (This examination application should be submitted along with the
                exam fee)
              </div>
              <div className="mt-4 pb-1 text-center">
                <strong>DECLARATION BY THE CANDIDATE</strong>
              </div>
              <div>
                I here by declare that the entries made above are correct and
                that they, have been made in my <br></br>own handwriting!
              </div>
              <div className="mt-4 d-flex justify-content-between align-items-end">
                <div>
                  <div>Station: {data.placeOfSignature}</div>
                  <div className="mt-1">
                    Date: {moment(data.dateOfSignature).format("Do MMM, YYYY")}
                  </div>
                </div>
                <div>
                  <img
                    crossorigin="anonymous"
                    src={data.signature}
                    className="signature"
                  />
                  <br></br>
                  Signature of the candidate
                </div>
              </div>
              <div className="mt-4 d-flex gap-1" style={{ fontWeight: "bold" }}>
                <div>Note:</div>
                <div>
                  University affiliated College Principal (or) BSS Institute
                  Director (or) Institute Head are authorized to attest on the
                  both student Photographs.
                </div>
              </div>
              <div
                className="mt-4 pt-4 px-2"
                style={{ borderTop: "1px dashed black" }}
              >
                His/Her application for the examination has been accepted and
                granted as a candidate for<br></br> examination to the aforesaid
                course for 20 -20.
              </div>
              <div className="d-flex justify-content-between align-items-end mt-5 pt-5">
                <div>
                  <div>Chennai</div>
                  <div className="mt-2">Date</div>
                </div>
                <div>Controller of examination</div>
              </div>
            </div>

            {/* <div> */}
            {/* <img crossorigin="anonymous" src={props.marksheetOrDegreeCert} style={{width:'200px', height:'200px'}}/>
                    <img  crossorigin="anonymous" src={props.panCard}  style={{width:'200px', height:'200px'}}/>
                    <img  crossorigin="anonymous" src={props.otherDocs}  style={{width:'200px', height:'200px'}}/> */}
            {/* </div> */}
          </div>
        ) : (
          "Please add user data to generate PDF"
        )
      ) : (
        "User Data not found"
      )}
    </>
  );
}

export default BSSForm;
