import React from 'react';
import { Link } from 'react-router-dom';

import './sidebarNavigation.css';

import HeaderLogo from '@assets/sidebarNavigation/headerLogo.png';
import ProfilePlaceholder from '@assets/sidebarNavigation/headerLogo.png';
import {Skeleton, AspectRatio} from '@mui/joy'
import Search from '@components/Search';

function SidebarNavigationView(props) {
	return (
		<>
			<nav
				id='topNavBar'
				className='d-flex justify-content-between align-items-center'>
				<img src={HeaderLogo} alt='HeaderLogo' style={{ width: '44px' }} />
				<div style={{marginLeft:'20px', marginRight:'auto', fontWeight:'600'}}>eMediskill Admin Dashboard</div>
				<button
					type='button'
					className='btn btn-dark'
					onClick={() => {
						props.logout();
					}}>
					Logout
				</button>
			</nav>

			<div id='sidenavBar'>
				{props.currentUserProfile ? 
				<img
					style={{ width: '150px' }}
					src={props.currentUserProfile }
					alt='ProfilePlaceholder'
				/>
				:
				<Skeleton animation="wave" variant="circular" width={150} height={150} />
               }
				<ul className='navbar-nav'>
					{props.navigationData.map((value, key) => (
							<li key={key} className='d-flex'>
							{props.location.pathname === value.route?
							<div className='activeTabIndicator'></div> : ''}
								<Link to={value.route}>
									<img src={value.icon} alt={value.title} 
									style={props.location.pathname === value.route?{filter:'brightness(0) saturate(100%) invert(18%) sepia(91%) saturate(7439%) hue-rotate(350deg) brightness(84%) contrast(114%)' }:{}}/>
									<span style={props.location.pathname === value.route? {color:'#E3000F'}:{}}>{value.title}</span>
								</Link>
							</li>
					))}
                
				</ul>
				
				<div className='d-flex flex-column gap-2 mt-3 pe-3'>
				{ 
				!props.navigationData?.length && 
				[1,2,3,4,5,6,7,8,9,10,11,12,13,14].map((index)=>{

					return <div className='d-flex flex-row gap-2'>
					        <Skeleton variant="rectangular" width={30} height={30} />
							<div className='d-flex flex-column gap-1 flex-grow-1 justify-content-center'>
								<Skeleton variant="rectangular" height={8} width={'80%'}/>
							</div>
						  </div>
					})}
				</div>
				
			</div>
		</>
	);
}

export default SidebarNavigationView;
