import React from 'react';

import moment from 'moment'; import './style.css';

function TicketActivityView(props) {
	return (
		<>
			<div className='ticketActivityView'>
				<div className="accordion" id="ticketActivityAccordion">
					<div className="accordion-item">
						<h2 className="accordion-header">
							<button className="accordion-button" data-bs-toggle="collapse" data-bs-target="#ticketActivityCollapseOne">
								Ticket Activity
							</button>
						</h2>
						<div id="ticketActivityCollapseOne" className="accordion-collapse collapse show" data-bs-parent="#ticketActivityAccordion">
							<div className="accordion-body">

								{/* START : Day vise conversions accordion */}
								{props.activityData && 
									props.activityData.map((daysActivity, activityIndex) => (
										<div className="accordion ticketActivityDataAccordion" id={`taActivityAccordion_${activityIndex}`} key={activityIndex}>
											<div className="accordion-item">
												
												<h2 className="accordion-header">
													<button className="accordion-button accordionActivityBtn" data-bs-toggle="collapse" data-bs-target={`#taActivityCollapse_${activityIndex}`}>
														<span>{moment(daysActivity[0].createdAt).format('Do MMM, YYYY')}</span>
														<span>{moment(daysActivity[0].createdAt).format('hh:mm A')}</span>
													</button>
												</h2>
												<div id={`taActivityCollapse_${activityIndex}`} className="accordion-collapse collapse show" data-bs-parent={`#taActivityAccordion_${activityIndex}`}>

													<div className="accordion-body accordionActivityBody" style={{paddingRight:'0px'}}>
														{daysActivity.length > 1 &&
															<div className='line accordionConversionsLine'></div>
														}

														{/* START : Time vise conversions accordion */}
														{daysActivity.map((conversions, conversionsIndex) => (
																<>
																	<div 
																		className={`accordion conversionsAccordion ${conversionsIndex === (daysActivity.length - 1) ? 'withLineHelper' : 'withoutLineHelper'}`} 
																		id={`taConversionsAccordion_${conversions._id}`} key={conversionsIndex}>

																		<div className="accordion-item accordionConversionsItem">
																		
																			<h2 className="accordion-header">
																				{conversionsIndex!==0?<button className="accordion-button accordionConversionsBtn" data-bs-toggle="collapse" data-bs-target={`#taConversionsCollapse_${conversions._id}`}>
																					<span className='s1'>
																						{ conversions.commenterDetails?.displayPhoto? <img 
																						  className='profileIcon' 
																						  src={conversions.commenterDetails?.displayPhoto} alt='' /> :
																						  'No image'}
																						{conversions.commenterDetails?.fullName}
																					</span>
																					<span style={{fontSize: '13px', color:'#cccccc'}}>
																						{moment(conversions.createdAt).format('hh:mm A')}
																					</span>
																				</button>:
																				   <button className="accordion-button accordionConversionsBtn accordionConversionsBtnHiddenIcon">
																					<span className='s1'>
																						<img className='profileIcon rounded-circle' 
																						src={conversions.commenterDetails?.displayPhoto} alt='' />
																						{conversions.commenterDetails?.fullName}
																					</span>
																					</button>	
																				}
																			</h2>
																			<div id={`taConversionsCollapse_${conversions._id}`} 
																				className={`accordion-collapse collapse ${conversionsIndex === 0 ? 'show' : ''}`}
																				data-bs-parent={`#taConversionsAccordion_${conversions._id}`}>
																				<div className="accordion-body accordionConversionsBody" 
																				style={conversions.commentType==='normalComment'?{color:'black'} : {color:'red'}}>
																					{conversions.comment}

																					{conversions.lastSeenDetails.length>0 && 
																					<div className='lastSeenDiv'>
																						Last seen by 
																						{
																							conversions.lastSeenDetails.map((user)=>" "+user.fullName)
																						}
																						{
																							conversions.lastSeenDetails.map((user)=>{
																								return <img src={user.displayPhoto} className='rounded-circle'/>
																							}
																							
																							)
																						}
																					</div>
																				}
																				</div>
																				
																		</div>
																		</div>
																	</div>
																	{conversionsIndex === (daysActivity.length - 1) && 
																	<div className='accordionLineHelperContainer'>
																		
																	</div>
																	}
																</>
															))}
														{/* END : Time vise conversions accordion */}

													</div>
												</div>
											</div>
										</div>
									))
								}
								{/* END : Day vise conversions accordion */}

							</div>
						</div>
					</div>
				</div>


			</div>
		</>
	);
}

export default TicketActivityView;
