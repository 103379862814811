import React, { useEffect, useState } from 'react';

import NewEligibilityPopUpView from './view';

function NewEligibilityPopUp(props) {
    const[profession, setProfession] = useState('');
    const[qualifications, setQualifications] = useState([]);

	useEffect(()=>{
		setProfession(props.profession)
		setQualifications(props.qualifications)
	},[props.profession, props.qualifications])

	const handleChange=(value, key, index)=>{
	   	if(key === "profession")
		{
			setProfession(value)
		}else if(key==='qualification'){
			let updatedQualifications =[...qualifications]
			updatedQualifications[index] = value
			setQualifications(updatedQualifications)
		}
	}

	const handleCancel=()=>{
		props.handleCancel()
	}
	const handleSave=()=>{
		props.handleSave({"qualificationCategory" : profession, "qualifications" : qualifications})
	}
	
	
	const onDragEnd = (result) => {
		if (!result.destination) return;
	  const updatedQualifications = [...qualifications];
	  const [reorderedItem] = updatedQualifications.splice(result.source.index, 1);
	  updatedQualifications.splice(result.destination.index, 0, reorderedItem);
	  setQualifications(updatedQualifications);
	};

  const handleAddQualification=(event, index)=>{
	  var updatedQualifications = [...qualifications]
	  var newQualification= ''

	  if(index!=null)
	   {updatedQualifications.splice(index+1, 0, newQualification)}
	  else{
		  updatedQualifications=['']
	  }
   setQualifications(updatedQualifications)   
   if(event){event.stopPropagation()}
   }

   const handleDeleteQualification=(event, index)=>{
	  var updatedQualifications = [...qualifications]
	  updatedQualifications.splice(index, 1)
	  setQualifications(updatedQualifications)   
	  if(event){event.stopPropagation()}
  }

	return (
		<NewEligibilityPopUpView
		   props = {props}	
           profession = {profession}
		   qualifications={qualifications}

		   onDragEnd={onDragEnd}
		   handleAddQualification={handleAddQualification}
		   handleDeleteQualification={handleDeleteQualification}

		   handleChange={handleChange}
		   handleCancel ={handleCancel}
		   handleSave={handleSave}
			/>
	);
}

export default NewEligibilityPopUp;
