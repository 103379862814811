import React, { useEffect, useState } from 'react';

import AddRulePopUpView from './view';
import moment from 'moment';

import * as CoursesServices from '@services/Course';
import * as UsersServices from '@services/Users';
import Swal from 'sweetalert2';
// import * as CategoryServices from '@services/Category';
// import * as CoursesServices from '@services/Course';

import { decryptText } from '@library/enc-dec';
import { produce } from 'immer';

function AddRulePopUp(props) {
	const [allCourses, setAllCourses] = useState([]);
	const [selectedCourseId, setSelectedCourseId] = useState('');
	const [selectedCoursesList, setSelectedCoursesList] = useState([]);
	const [offerTime, setOfferTime] = useState('');
	const [error, setError] = useState('');

	const handleCourseChange = (event) => {
		setSelectedCourseId(event.target.value);
	};

	const handleAddNewCourse = (event) => {
		if(!selectedCourseId){
			return
		}
		let selectedCourse = {};
		allCourses.forEach((course) => {
			if (course._id === selectedCourseId) {
				selectedCourse = course;
				selectedCourse.offerPrice = "₹ 0";
				selectedCourse.offerPriceInNumbers= 0;
				selectedCourse.offerExpiry=  {"hours": 0};
		}});

		setSelectedCoursesList((prevCourseList) => [...prevCourseList, selectedCourse]);
	};

	const handleDeleteCourse = (event, courseId) => {
		const updatedCourse = selectedCoursesList.filter(
			(course) => course._id !== courseId
		);
		setSelectedCoursesList(updatedCourse);
		event.stopPropagation()
	};

	const handleCloseBtn = () => {
		props.handleClose()
	};

	useEffect(() => {
		getAllCourses();
	}, [props.visible]);

	const getAllCourses = async () => {
		try {
			let authToken = decryptText(localStorage.getItem('aEmediat'));
			const response = await CoursesServices.getAllCourses({pageNo:0,limit:10000}, authToken);

			if (response.success) {
				setAllCourses(response.data);
			}
		} catch (err) {
			console.log('Error coming while fetching all course', err);
		}
	};
	
	const handleSaveBtn = async () => {
		if (selectedCoursesList.length ==0) {
			setError('No Series Selected');
			setTimeout(function () {
				setError('');
			}, 2000);
		} else if(offerTime==='' || offerTime ===0){
			setError('Offer time is empty');
			setTimeout(function () {
				setError('');
			}, 2000);
		}
		else {

			if(!props.userIds)
			{
				props.handleSave(selectedCoursesList);
			}
			else{
				addRulesForUsers()
			}
		}
	};
	
	const addRulesForUsers = async () => {
		try {
				let authToken = decryptText(localStorage.getItem('aEmediat'));
				const payload={
					userIds : props.userIds,
					courses:  selectedCoursesList.map(obj => ({
						courseId: obj._id,
						offerPrice: obj.offerPrice,
						offerPriceInNumbers: obj.offerPriceInNumbers,
						offerExpiry: {"hours": Number(offerTime)}
					  }))

				}
	
				var response = await UsersServices.createUserSpecificOffer(payload, authToken);
				if(response.success){
					Swal.fire({icon: "success",title: response.message,showConfirmButton: false,timer: 2500});	
					props.handleSave()	
				}else{
					Swal.fire({icon: "error",title: response.message,showConfirmButton: false,timer: 2500});
					props.handleClose()
				}
				
			} catch (err) {
				console.log('Error coming while adding courses', err);
			}
		}

	const handleOfferPrice=(index, value)=>{
		let newList = produce(selectedCoursesList, draft=>{
			draft[index].offerPrice = `₹ ${value}`;
			draft[index].offerPriceInNumbers= value;
		})
		setSelectedCoursesList(newList)
	}

	return (
		<AddRulePopUpView
		        props={props}
				allCourses={allCourses}
				selectedCoursesList={selectedCoursesList}
				error={error}
				offerTime={offerTime}
				setOfferTime={setOfferTime}
				handleCourseChange={handleCourseChange}
				handleAddNewCourse={handleAddNewCourse}
				handleOfferPrice={handleOfferPrice}
				handleDeleteCourse={handleDeleteCourse}
				handleCloseBtn={handleCloseBtn}
				handleSaveBtn={handleSaveBtn}
			/>
	);
}

export default AddRulePopUp;
