import { Button, Tab, Tabs } from "react-bootstrap";
import './certificates.css';

import CheckIcon from '@assets/common/check.png';
import BlueDropDownIcon from '@assets/common/blueDropDown.png';
import ExpandIcon from '@assets/userDashboard/expand.png';
import SettingIcon from '@assets/userDashboard/setting.png';
import ColumnsIcon from '@assets/userDashboard/columns.png';
import FolderIcon from '@assets/userDashboard/folder.png';


import BasicTableGrid from '@components/BasicTableGrid';
import DateAndUser from '@components/DateAndUser';
import DynamicTabs from '@components/DynamicTabs';
import ButtonBars from '@components/ButtonBars';
import PhoneNumber from '@components/PhoneNumber';
import PopUp from '@components/PopUp';
import Search from '@components/Search';
import Tags from '@components/Tags';
import EditNote from '@components/EditNote';
import moment from 'moment';
import DownIcon from '@assets/common/dropDown.png';
import SelectionPopUp from '@components/SelectionPopUp';


import ConfirmDIalogBox from "@pages/Certificates/CertificatePages/confirmDialogBox";

function CertificatesView(props) {
    return (
        <>
            <div className='BSSPageMainContainer bg-white' ref={props.fullscreenElementRef}>
            <Search
					value={props.searchText}
					startDate={props.filters.startDate}
					endDate={props.filters.endDate}
					yearTag={props.filters.yearTag}
					monthTag={props.filters.monthTag}
					mainTag={props.filters.mainTag}
					tagStatus={props.filters.tagStatus}
					subTag={props.filters.subTag}
					manualTag={props.filters.manualTag}
					placeholder = {"Search certificates"}
					isFilterEnabled={true}

					filters = {[{placeholder:'Category',value:props.filters.categories.length>0?`(${props.filters.categories.length}) selected`:'', icon: DownIcon},
								{placeholder:'Select Course', value:props.filters.courseIds.length>0?`(${props.filters.courseIds.length}) selected`:'', icon: DownIcon},
								]}
					handleFilter={props.handleFilterPopUp}
					isTagFilterEnabled={true}
					
					onSearch ={(data)=>props.handleSearch(data)}
					/>
                <h4 style={{ marginBottom: '20px', marginLeft: '20px' }}> Certificates </h4>
                <DynamicTabs
					allTabs={props.folders}
					activeTab={props.activeTab}
					onTabClick={props.handleTabClick}
				/>
                <div className='d-flex align-items-center w-100 justify-content-between'>
					<ButtonBars
						buttons={[
							props.activeTab._id=="ready"?{
								text: 'Send for dispatch',
                                iconStart: CheckIcon,
								disabled: props.disableBulkActionBtn,
								onClick: (event) => {
									props.updateCertificateStatus('dispatch');
								},
							}: props.activeTab._id=="dispatch"?
							{
								text: 'Send to delivered',
                                iconStart: CheckIcon,
								disabled: props.disableBulkActionBtn,
								onClick: (event) => {
									props.handlePopUpOpen(event, "confirmDelivery");
								},
							}: props.activeTab._id=="delivered"?
							{
								text: 'Move to "For Dispatch"',
                                iconStart: CheckIcon,
								disabled: props.disableBulkActionBtn,
								onClick: (event) => {
									props.handlePopUpOpen(event, "changeExamYear");
								},
							}: null
						]}
					/>
					<div className='d-flex gap-2 controlsRight me-3'>
						<button onClick={props.toggleFullscreen}>
							<img src={ExpandIcon}></img>
							<br></br>
							Expand
						</button>
						<button onClick={(event)=>props.handlePopUpOpen(event, "settings")}>
							<img src={SettingIcon}></img>
							<br></br>
							Setting
						</button>
						<button onClick={props.handleColumnBtnClick}>
							<img src={ColumnsIcon}></img>
							<br></br>
							Columns
						</button>
					</div>
                </div>
          
                <BasicTableGrid 
				gridId='Certificates'
				data={props.deferredData} // Data to be displayed
				fixedCols={2} // How many columns are fixed and not scrolling
				initialSize={[35,321,141,202,118,122,156,176,89,252,388,450]}
				initialSorting={[{"id": "Date Added","desc": false}]}
				handleSelect={(selectedRows) => {props.handleCheckBoxes(selectedRows);}}
				length={20}
				onSortChange={(data) => {}
                    // props.handleSort(data)
				}
				columnsOptionPopUpRef={props.columnsOptionPopUpRef}
				columns={[
                    {header: 'Name as per degree certificate/marksheet ',
					cell: (data) => {
					const info = data.row.original;
					return (<div className="d-flex gap-2 w-100" style={info.fullName?.includes('Dr')? {color:'red'}:{}}>
                                {false ? <div className="thumbnailContainer" style={{backgroundImage:`url(${info.thumbnailUrl})`}}>
                                    </div>
                                : null}
                                {info.fullName}</div>);}
				},
				{header: 'Cleared exam', 
				 cell : (data)=>{
					return data.row.original.clearedExam
				}},
				{header: 'Passing Date/time', accessorFn: (row) => {},
                 cell: (data) => {
					const info = data.row.original;
						return <DateAndUser
						date = {info.passingDate}
						image = {''}
						imageText = {''}/>
					},
			    },
				{header: 'All courses', 
				 cell : (data)=>{
                    var info =data.row.original.allCourses
                    const total = data.row.original.courses?.length
                    const completed = (data.row.original.courses || []).filter(course => course.courseStatus === 'completed').length;
                   
					return <div className="d-flex align-items-center w-100">
                             <div className="flex-shrink-1 me-2" 
                             style={{ minWidth: 0, overflow:'hidden',textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{info}</div>
                             <div className="ms-auto d-flex align-items-center justify-content-center" 
                                  style={{padding: '5px', borderRadius: '20px',
                                         backgroundColor: total>0? completed/total === 1? '#19E039' : '#878787' :'#878787',
                                         color: '#ffffff', fontSize: '13px', width: '42px', height: '20px', paddingTop:'7px'
                                         }}>
                                    {completed}/{total}
                             </div>
                           </div>
				}},
				{header: 'Qualification', 
				 cell : (data)=>{
                    var qualification = data.row.original.qualification
                    var profession = data.row.original.profession==='doctor'? 'Doctor':'Nurse'
					return <div className="d-flex align-items-center w-100">
                                <div className="d-flex align-items-center justify-content-center" 
                                    style={{padding: '5px', borderRadius: '4px',
                                            backgroundColor: profession =='Doctor'? '#11DEE8' : '#878787',
                                            color: '#ffffff', fontSize: '13px', width: '52px', height: '18px'
                                            }}>
                                    {profession}
                                </div>
                                <div className="flex-shrink-1 ms-2" 
                                style={{ minWidth: 0, overflow:'hidden',textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                    {qualification}
                                </div>
                                
                            </div>
				}},
                {header: 'Phone Number',
                cell: (data) => {
                    var info = data.row.original;
                    return (<PhoneNumber
                                userId = {info.userId}
                                countryCode = {info.countryCode}
                                number = {info.contactNumber}
                                type = 'phone'
                                onChange = {(data)=>{props.handleChange('phoneNumber', data, info.userId)}}
                            />);
                        },
                },
                {header: 'Whatsapp Number',
                cell: (data) => {
                    var info = data.row.original;
                    return (<PhoneNumber
                                userId = {info.userId}
                                countryCode = {info.whatsAppCountryCode}
                                number = {info.whatsAppNumber}
                                type = 'whatsapp'
                                onChange = {(data)=>{props.handleChange('whatsAppNumber', data, info.userId)}}
                            />);
                        },
                },
				{header: 'Quantity', 
				 cell : (data)=>{
					return data.row.original.certificateCount
				}},
				{header: 'Dispatch Date/time', accessorFn: (row) => {},
                 cell: (data) => {
					const info = data.row.original;
						return <DateAndUser
						date = {info.certificateDispatchedAt}
						image = {''}
						imageText = {''}/>
					},
			    },
                {header: 'Tags',
                    cell: (data) => {
						const info = data.row.original
                        const tags = data.row.original.tags;
                        const userManualTags = data.row.original.userManualTags;
                        if(tags){
                        // tags.month= tags.month==='yes'? moment(info.createdAt).format('MMM'): 'no';
                        // tags.year= tags.year==='yes'? moment(info.createdAt).format('yyyy'): 'no';
                        // tags.status= tags.status==='yes'? 'Get status': 'no';

                        return (
                            <Tags 
                              userId={info.userId}
                              automaticTags={tags} // [{key:value}]
                              manualTags = {userManualTags}
                              onChange = {(manualTags)=>{props.handleChange('manualTags', manualTags, info.userId)}}
                            />
                        );
                        }

                    },
                },
				{header: 'Notes', 
				 cell : (data)=>{
					return <EditNote value={data.row.original.notes} 
                    onChange = {(notes)=>{props.handleChange('notes', notes, data.row.original.userId)}}
                    userId={data.row.original.userId}/>
				}},
				]}
				/>


                <PopUp
                    visible={props.isPopUpVisible && props.popUp=='settings'}
                    top={props.popUpPosition.top}
                    left={props.popUpPosition.left-50}
                    handleOutSideClick={props.handlePopUpClose}>
                    <div style={{height: '200px', overflow:'auto'}}>
                        {props.folders.map((folder, key)=>{
                            return <div className="settingOption" key={key}
                            onClick={
                                (event)=>{
                                    folder._id=='delivered'? 
                                    props.handlePopUpOpen(event,'confirmDelivery'):
                                    props.moveToFolder(folder._id)
                                }
                            }>{folder.title}</div>
                        })}
                        <div className="settingOption" onClick={props.handleExcelExport}>Download excel sheet</div>
                    </div>
                </PopUp>
                <PopUp
                    visible={props.isPopUpVisible && props.popUp==='confirmDelivery'}
                    top={props.popUpPosition.top}
                    left={props.popUpPosition.left}
                    handleOutSideClick={props.handlePopUpClose}>
                    <div className="full-page-overlay">    
                        <ConfirmDIalogBox
                            show={true}
                            download={props.handlePopUpSave}
                            onHide={props.handlePopUpClose}
                        />
                    </div>
                </PopUp>

                <div  style={{zIndex:'9999999999999999999999'}}>
                    {props.popUp === "filterCategory"  &&<PopUp visible={props.isPopUpVisible}
                        top={props.popUpPosition.top}
                        left={props.popUpPosition.left}
                        handleOutSideClick={props.handlePopUpClose}>
                        <SelectionPopUp 
                            data = {props.allCategories}
                        optionKey= {"title"}
                        title={"Select Category"}
                        searchPlaceholder={"Search category"}
                        isSearchEnabled={true}
                        height= {"300px"}
                        width= {"400px"}
                        selectedItems={props.allCategories.filter(category=> props.filters.categories.includes(category._id))}
                        dataKey={"_id"}
                        selectedItemsKey={"_id"}
                        selectionType={"multiple"}
                        handleCancel ={props.handlePopUpClose}
                        handleSave={props.handlePopUpSave}/>
                    </PopUp>}
                    {props.popUp === "filterCourse"  &&<PopUp visible={props.isPopUpVisible}
                        top={props.popUpPosition.top}
                        left={props.popUpPosition.left}
                        handleOutSideClick={props.handlePopUpClose}>
                        <SelectionPopUp 
                            data = {props.allCourses}
                            optionKey= {"title"}
                            title={"Select Course"}
                            searchPlaceholder={"Search course"}
                            isSearchEnabled={true}
                            height= {"300px"}
                            width= {"400px"}
                            selectedItems={props.allCourses.filter(course=> props.filters.courseIds.includes(course._id))}
                            dataKey={"_id"}
                            selectedItemsKey={"_id"}
                            selectionType={"multiple"}
                            handleCancel ={props.handlePopUpClose}
                            handleSave={props.handlePopUpSave}/>
                    </PopUp>}
                </div>
            </div>

        </>
    )
};

export default CertificatesView;
