import GoBackArrow from "@assets/sidebarNavigation/goBackArrow.png";
import EditPencilIcon from "@assets/common/editPencilIcon.png";
import AnalyticsIcon from "@assets/common/analyticsIcon.png";
import ModulesIcon from "@assets/common/coursePageTabsIcon.png";
import PricingIcon from "@assets/common/coursePageTagIcon.png";
import BlogIcon from "@assets/common/searchBox.png";
import SeoIcon from "@assets/common/infoBox.png";

export const responseData = {
  _id: "",
  title: "",
  internalTitle: "",
  mnemonic: "",
  visibility: "",
  examId: "",
  paymentGateways: [],
  imageCarousel: [],
  seriesWatchedPercentage: 0,
  exemptedDays: 0,
  tags: {
    year: null,
    main: null,
    secondary: null,
    month: null,
    status: null,
  },
  eligibleExams: [],
  eligibleCertificates: [],
  allowToCart: false,
  cardTitle: "",
  cardDescription: "",
  cardPointers: {
    icon: "",
    pointer: "",
  },
  cardIcons: [],
  cardBackgroundColor: "#f9f9f9",
  squareThumbnailUrl: "{}",
  rectangleThumbnailUrl: "{}",
  coverTitle: "",
  coverDescription: null,
  coverPointers: {
    icon: "",
    pointer: "",
  },
  infoParagraph: null,
  certificateThumbnailUrl: null,
  roadmapType: null,
  previewVideoLink: null,
  faqsId: null,
  categories: [],
  lastModifiedBy: "",
  isLatest: false,
  reviews: [],
  isLegalInfoRequired: false,
  isIdVerificationRequired: false,
  isAddressProofRequired: false,
};

export const paymentOptions = [
  { _id: "1", paymentGateway: "razorpay" },
  { _id: "2", paymentGateway: "eazypay" },
  { _id: "3", paymentGateway: "stripe" },
  { _id: "4", paymentGateway: "ccavenue" },
];

export const qualifications = {
  Doctor: ["MBBS", "BAMS", "BHMS"],
  Nurse: ["NMS", "NN"],
};

//Object to handle navigation
export const navigationData = [
  {
    title: "Back to Content",
    route: "/content?tab=courses",
    default: false,
    icon: GoBackArrow,
  },
  {
    title: "Details",
    component: "CourseDetails",
    default: false,
    icon: EditPencilIcon,
  },
  {
    title: "Content",
    component: "CourseContent",
    default: false,
    icon: ModulesIcon,
  },
  {
    title: "Pricing",
    component: "CoursePricing",
    default: false,
    icon: PricingIcon,
  },
  {
    title: "Blogs",
    component: "CourseBlogs",
    default: false,
    icon: BlogIcon,
  },
  {
    title: "SEO",
    component: "CourseSEO",
    default: false,
    icon: SeoIcon,
  },
  // {
  //   title: "Analytics",
  //   component: "CourseAnalytics",
  //   default: false,
  //   icon: AnalyticsIcon,
  // },
];
