import React, { useState, useEffect, useRef } from 'react';
import VideoView from './view';
import { useNavigate, defer, useLocation, useSearchParams} from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ClipboardJS from 'clipboard';

import * as VideosServices from '@services/Videos';
import * as VideosActions from '@redux/actions/Videos';

import { navigationData } from './data';
import { decryptText } from '@library/enc-dec';
import { toggleFullscreen} from '@helpers/common.js';
import { produce } from 'immer';
import { checkLoginAndRole } from '@helpers/Login/';

var listOfCheckedVideos = [];

function Videos(props) {
	const fullscreenElementRef = useRef(null);
	const navigate = useNavigate();
	const dataFetchedRef = useRef(false);

	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const [deferredData, setDeferredData] = useState({});

	const [allVideos, setAllVideos] = useState([]);

	let authToken = decryptText(localStorage.getItem('aEmediat'));
	const [isSorted, setIsSorted] = useState(props.filterInformation.isSorted);
	const [sortBy, setSortBy] = useState(props.filterInformation.sortBy);

	// UseState's to control the functionality of setting visibility of video
	const [internalIsPublic, setInternalIsPublic] = useState(false);
	const [internalIsUnlisted, setInternalIsUnlisted] = useState(false);
	const [disableBulkActionBtn, setDisableBulkActionBtn] = useState(true);
	const [disableSaveBtnOfVisibility, setDisableSaveBtnOfVisibility] =
		useState(false);
	const visibilityPopupContainerRef = useRef(null);
	const [
		is_VisibilityPopupContainerRef_Visible,
		set_Is_VisibilityPopupContainerRef_Visible,
	] = useState(false);
	const [
		visibilityPopupContainerRef_Position,
		set_VisibilityPopupContainerRef_Position,
	] = useState({ top: 0, left: 0 });
	const [currentSelectedCourse, setCurrentSelectedCourse] = useState(null);

	// To handle thumbnail & title
	const thubmnailPopupContainerRef = useRef(null);
	const [
		is_ThubmnailPopupContainerRef_Visible,
		set_Is_ThubmnailPopupContainerRef_Visible,
	] = useState(false);
	const [
		thubmnailPopupContainerRef_Position,
		set_ThubmnailPopupContainerRef_Position,
	] = useState({ top: 0, left: 0 });

	const [updatedCourseTitle, setUpdatedCourseTitle] = useState('');
	const [thumbnailFileObj, setThumbnailFileObj] = useState('');
	const [courseUpdateTilteError, setCourseUpdateTilteError] = useState(false);

	const [alertVisible, setAlertVisible] = useState(false);

	const [searchParams, setSearchParams] = useSearchParams();

	useEffect(() => {
		checkLoginAndRole('content');
		window.scrollTo(0, 0);
		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;
              
        setSearchParams({
			page: props.filterInformation.page,
			limit: props.filterInformation.limit,
		  })
		
	}, []);

	useEffect(()=>{
		if(!Number(queryParams.get('limit')) && Number(queryParams.get('page')))
		{
			return
		}
		props.updateFilterInformation({
			page:Number(queryParams.get('page')), 
			limit:Number(queryParams.get('limit')), 
			sortBy, 
			isSorted, 
			searchText: props.filterInformation.searchText
		})
     },[Number(queryParams.get('limit')),Number(queryParams.get('page')), sortBy, isSorted])

	useEffect(() => {
		// if(props.filterInformation.searchText != '')
		// 	// handleSearch('initial');
		if( props.filterInformation.limit>0){
			getAllVideos()
		} 

	}, [props.filterInformation]);

	const getAuthToken=()=>{
		return decryptText(localStorage.getItem('aEmediat'));
	}

	const getAllVideos = async () => {
		try {

			const payload ={
				            pageNo: props.filterInformation.page, 
							limit: props.filterInformation.limit, 
							sortBy: props.filterInformation.sortBy, 
							isSorted: props.filterInformation.isSorted, 
							searchText: props.filterInformation.searchText}

			var deferredResponse = defer({res: VideosServices.getAllCourseVideos(payload, authToken)});

			setDeferredData(deferredResponse);

			deferredResponse.data.res.then(response => {
				setAllVideos(response);
			})
			.catch(error => {
				alert(error.message);
			});


			
		} catch (err) {
			console.log('Error coming while fetching all videos', err);
		}
	};

	//Fn to check and uncheck all checkboxes
	const handleCheckBoxes = (selectedRows) => {
		listOfCheckedVideos = selectedRows;
		setDisableBulkActionBtn( selectedRows.length>0 ? false : true)
	};

	// To sort 
	const handleSort = (data) => {
		const mapping = {'Date Added': 'createdAt', 'Date Modified': 'updatedAt'}
		var id = data[0]?.id ? mapping[data[0].id] : ''
		setSortBy(id)
		setIsSorted(data[0]?.desc ? data[0].desc : false)
	};

	// Updating visibility of videos

	// Handle when visibility btn is click which will update the val of radio btns as per the selected course
	const handleVisibilityBtnClick = (event, courseId, course) => {
		set_Is_VisibilityPopupContainerRef_Visible(true);
		let currentEle = event.target;

		let left = currentEle.getBoundingClientRect().left;
		let top = window.scrollY + currentEle.getBoundingClientRect().bottom;
		set_VisibilityPopupContainerRef_Position({ left: left, top: top });

		setInternalIsPublic(false);
		setInternalIsUnlisted(false);
		setDisableSaveBtnOfVisibility(false);

		if (courseId === 'bulk') {
			setDisableSaveBtnOfVisibility(true);
			setCurrentSelectedCourse(courseId);
		} else {
			setCurrentSelectedCourse(courseId);

			if (course.isPublic) {
				setInternalIsPublic(true);
				setInternalIsUnlisted(false);
			} else {
				setInternalIsUnlisted(true);
				setInternalIsPublic(false);
			}
		}
	};

	// To close the visibility popup container
	const handleVisibilityCancleBtnClick = (event) => {
		set_Is_VisibilityPopupContainerRef_Visible(false);
	};

	// To handle radio btn of public and unlisted
	const handleVisibilityRadioBtns = (event, value) => {
		if (value === 'public') {
			setInternalIsPublic(true);
			setInternalIsUnlisted(false);
		} else {
			setInternalIsPublic(false);
			setInternalIsUnlisted(true);
		}
		setDisableSaveBtnOfVisibility(false);
	};

	// Handle visibitlity save button
	const handleSaveBtnVisibility = (event) => {
		currentSelectedCourse === 'bulk'?
			handleVideoVisibility(internalIsPublic, listOfCheckedVideos.map(item => item._id))
		:
			handleVideoVisibility(internalIsPublic, [currentSelectedCourse])
		listOfCheckedVideos = [];
		set_Is_VisibilityPopupContainerRef_Visible(false);
	};

	const handleVideoVisibility = async (isPublic, videoIds) => {
		try {

			const payload= {videoDocIds: videoIds , 
				            isPublic: isPublic}
			let response = await VideosServices.updateVideoVisibility(payload,getAuthToken());
			if (response.success) {
				let updatedAllVideos = produce(allVideos, (draft) => {
					draft.data=draft.data.map((video)=>{
						if(videoIds.includes(video._id)){
							video.isPublic = isPublic
						}
						return video
					}) 
				});
				setAllVideos(updatedAllVideos)
				var deferredResponse = defer({res: updatedAllVideos});
				setDeferredData(deferredResponse);
				return;
			}
			alert(response.message);
		} catch (err) {
			console.log('Error coming while handling video visibility', err);
		}
	};

	// Updating thubmnail and title of the video

	// To open thumbnail update popup
	const handleDoubleClick = (event, videoDocId, courseTitle) => {
		let currentEle = event.target;
		set_Is_ThubmnailPopupContainerRef_Visible(true);

		let left = currentEle.getBoundingClientRect().left;
		let top = window.scrollY + currentEle.getBoundingClientRect().bottom;

		set_ThubmnailPopupContainerRef_Position({ left: left, top: top });

		setUpdatedCourseTitle(courseTitle);

		setCurrentSelectedCourse(videoDocId);
	};

	// To close the visibility popup container
	const handleThumbnailCancleBtnClick = (event) => {
		set_Is_ThubmnailPopupContainerRef_Visible(false);
		setCourseUpdateTilteError(false);
	};

	// To update db thumbnail & title for course
	const handleSaveThumbnail_Title = async (event) => {
		try {
			
			set_Is_ThubmnailPopupContainerRef_Visible(false);	
			if (updatedCourseTitle !== '' || thumbnailFileObj !== '') {
				const paylaod = {
					videoDocId: currentSelectedCourse,
					title: updatedCourseTitle,
					thumbnailUrl: thumbnailFileObj,
				};

				// let url = URL.createObjectURL(thumbnailFileObj[0]);
				// handleThumbnailUpdate(currentSelectedCourse, updatedCourseTitle, url);
				let response = await VideosServices.updateVideoThumbnailAndTitle(
					paylaod,
					getAuthToken()
				);

				if (response.success) {
					const { title, thumbnailUrl } = response.data;
					handleThumbnailUpdate(currentSelectedCourse, title, thumbnailUrl);
				} else {
					alert(response.message);
				}
				setUpdatedCourseTitle('');
				setThumbnailFileObj('');
				document.getElementById('coverImage_name').innerHTML =
					'Upload cover picture';
				setCourseUpdateTilteError(false);
		

			} else {
				setCourseUpdateTilteError(true);
			}
		} catch (err) {
			console.log('Error coming while saving video title and thubnail', err);
		}
	};

	const handleThumbnailUpdate = (videoId, title, thumbnailUrl) => {
		let updatedAllVideos = produce(allVideos, (draft) => {
			draft.data=draft.data.map((video)=>{
				if(videoId === video._id){
					video.title = title
					video.thumbnailUrl = thumbnailUrl
				}
				return video
			}) 
		});
		setAllVideos(updatedAllVideos)
		var deferredResponse = defer({res: updatedAllVideos});
		setDeferredData(deferredResponse);
		setDisableBulkActionBtn(true);
	};

	//END : Logic for the functionality updating thumbnail & title for course

	const handleDeleteBulk = async () => {
		try {
			let response = await VideosServices.deleteVideos(
				{ videoDocIds: listOfCheckedVideos },
				getAuthToken()
			);
			if (response.success) {
				getAllVideos();
				listOfCheckedVideos = [];
				document.getElementById('bulkCheckbox').checked = false;
				setDisableBulkActionBtn(true);
				alert(response.message);
				return;
			}
			alert(response.message);
		} catch (err) {
			console.log('Error coming while deleting the videos', err);
		}
	};

	const handleFileUpload = (event) => {
		let fileInptId = event.target.id.split('_')[0];
		document.getElementById(fileInptId).click();
		document
			.getElementById(fileInptId)
			.addEventListener('change', function (event) {
				let fileName = this.value.replace(/C:\\fakepath\\/i, '');
				document.getElementById('coverImage_name').innerHTML = fileName;
			});
	};

	const handleUpdateCourseTitle = (event) => {
		setUpdatedCourseTitle(event.target.value);
	};

	const convertToBase64 = (event) => {
		setThumbnailFileObj(event.target.files[0]);
	};

	const setDefaultNavigation = (defaultComponent) => {
		return navigationData.map((item) => {
			if (item.component === defaultComponent) {
				return { ...item, default: true };
			}
			return item;
		});
	};

	const handleRedirectToHome = async (index, videoDocId, defaultComponent) => {
		var defaultComponent = ''
		if(index === 0){
			defaultComponent = 'VideoDetails'
		}else if(index === 1){
			defaultComponent = 'VideoComments'
		}else if(index === 2){
			defaultComponent = 'VideoAnalytics'
		}else if(index === 3){
			
				var x =`${process.env.REACT_APP_E_MEDISKILL_WEB_APP}` +
				'/video/v/' + videoDocId
				window.open(x, '_blank');
				return
		}else if(index === 4){
			var x=`${process.env.REACT_APP_E_MEDISKILL_WEB_APP}` +
				'/video/v/' + videoDocId
				await handleCopyClick(x)
				return
		}

		//Handle setting default page while navigating
		let finalNavigationData = [];
		finalNavigationData = navigationData;
		if (defaultComponent !== 'none') {
			finalNavigationData = setDefaultNavigation(defaultComponent);
		} else {
			// VAR x ONLY FOR TESTING
			// let x = 'VideoComments';
			// finalNavigationData = setDefaultNavigation(x);
			finalNavigationData = setDefaultNavigation('VideoDetails');
		}
    //    finalNavigationData[0].route = '/content?page=1&limit=10'


		if (videoDocId === 'none') {
			props.resetVideosReducer();
			navigate('/videos', { state: { finalNavigationData } });
		} else {
			navigate('/videos/' + videoDocId, { state: { finalNavigationData } });
		}
	};

	const handleCopyClick = async(textToCopy) => {
		console.log("Text to copy", textToCopy)
		await window.navigator.clipboard.writeText(textToCopy);
		const clipboard = new ClipboardJS('.clipboard', {
			text: () => textToCopy,
		});

		clipboard.on('success', (e) => {
			// setAlertVisible(true);
			console.log("COpyied")
			setTimeout(() => {
				// setAlertVisible(false);
			}, 1000);
			e.clearSelection();
			clipboard.destroy();
		});

		clipboard.on('error', (e) => {
			console.error('Copy failed:', e.action);
			clipboard.destroy();
		});


		setTimeout(() => {
			console.log("TIMING OUT")
		}, 10000);
	};


	const handleSearch = (data) => {
		let updatedFilters = produce(props.filterInformation, (draft) => {
			draft.page=0;
			draft.searchText= data.text;
		});
		props.updateFilterInformation(updatedFilters);
	};


	return (
		<>
			<VideoView
			    deferredData={deferredData}
				allVideos={allVideos}
				handleRedirectToHome={handleRedirectToHome}
				//START : UseStates & fn to control the functionality of upating thumbnail of video
				handleDoubleClick={handleDoubleClick}
				handleThumbnailCancleBtnClick={handleThumbnailCancleBtnClick}
				thubmnailPopupContainerRef={thubmnailPopupContainerRef}
				is_ThubmnailPopupContainerRef_Visible={
					is_ThubmnailPopupContainerRef_Visible
				}
				thubmnailPopupContainerRef_Position={thubmnailPopupContainerRef_Position}
				updatedCourseTitle={updatedCourseTitle}
				courseUpdateTilteError={courseUpdateTilteError}
				handleUpdateCourseTitle={handleUpdateCourseTitle}
				handleSaveThumbnail_Title={handleSaveThumbnail_Title}
				handleFileUpload={handleFileUpload}
				convertToBase64={convertToBase64}
				

				//START : UseStates & fn to control the functionality of upating visibility of video
				visibilityPopupContainerRef={visibilityPopupContainerRef}
				is_VisibilityPopupContainerRef_Visible={
					is_VisibilityPopupContainerRef_Visible
				}
				visibilityPopupContainerRef_Position={visibilityPopupContainerRef_Position}
				internalIsPublic={internalIsPublic}
				internalIsUnlisted={internalIsUnlisted}
				handleVisibilityBtnClick={handleVisibilityBtnClick}
				handleSaveBtnVisibility={handleSaveBtnVisibility}
				handleVisibilityCancleBtnClick={handleVisibilityCancleBtnClick}
				handleVisibilityRadioBtns={handleVisibilityRadioBtns}
				//END : UseStates & fn to control the functionality of upating visibility of video

				//START : UseStates & fn for the functionality of bulk actions
				disableSaveBtnOfVisibility={disableSaveBtnOfVisibility}
				disableBulkActionBtn={disableBulkActionBtn}
				handleDeleteBulk={handleDeleteBulk}
				//END : UseStates & fn for the functionality of bulk actions

				//START : UseStates & fn to control the functionality of checkboxes
				handleCheckBoxes={handleCheckBoxes}
				//END : UseStates & fn to control the functionality of checkboxes
				alertVisible={alertVisible}
				
				//START: Search
				searchText={props.filterInformation.searchText}
				handleSearch={(event) => {
					handleSearch(event);
				}}
				//END: Search

				handleSort={handleSort}
				// Fullscreen
				fullscreenElementRef={fullscreenElementRef}
				toggleFullscreen={()=>toggleFullscreen(fullscreenElementRef)}
				isSorted={isSorted}
				sortBy={sortBy}
			/>
		</>
	);
}

const mapStateToProps = (state) => {
	return {
		allVideos: state.videos.allVideos,
		totalNumberOfVideos: state.videos.totalNumberOfVideos,
		videoSet: state.videos.videoSet,
		filterInformation: state.videos.filterInformation

	};
};

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			updateAllVideos: VideosActions.updateAllVideos,
			updateTotalNumOfVideos: VideosActions.updateTotalNumOfVideos,
			updateVideoSet: VideosActions.updateVideoSet,
			resetVideosReducer: VideosActions.resetVideosReducer,
			updateFilterInformation: VideosActions.updateFilterInformation
		},
		dispatch
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(Videos);
