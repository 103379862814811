import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, defer, useLocation} from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {colors} from '@assets/colors.js'
import moment from 'moment';

import * as AnalysisServices from '@services/Analysis';


import { decryptText } from '@library/enc-dec';
import { produce } from 'immer';
import Swal from 'sweetalert2';
import {getEventElementPosition, toggleFullscreen} from '@helpers/common.js';
import OverviewView from './view';
import { render } from '@testing-library/react';
const mainDateOption=['Today','Yesterday','This Month','This Week', 'This Year', 'Last 7 days','Last 30 days','Last 180 days','Last 360 days', 'This financial year','All time','Select']
const years = ['2022','2023','2024']
const months= ['January','February','March','April','May','June','July','August','September','October','November','December']


var listOfCheckedRows = [];
const currentDate = new Date();

const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

const startDateStr = startDate.toISOString().split('T')[0];
const endDateStr = endDate.toISOString().split('T')[0];


function Overview(props){   
	const fullscreenElementRef = useRef(null);
	const columnsOptionPopUpRef = useRef(null);
	const [selectedYear, setSelectedYear] = useState(null);


    useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				columnsOptionPopUpRef.current &&
				!columnsOptionPopUpRef.current.contains(event.target)
			) {
				columnsOptionPopUpRef.current.style.display = 'none';
			}
		};

		document.addEventListener('click', handleClickOutside);

		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, []);
	const [deferredData, setDeferredData] = useState({});
	const [searchText, setSearchText] = useState('');
	const [isSorted, setIsSorted] = useState(false);
	const [sortBy, setSortBy] = useState('updatedAt');
    const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const limit = Number(queryParams.get('limit'));
	const page = Number(queryParams.get('page'));
	const [popUp, setPopUp] =useState('');
	const [isPopUpVisible, setIsPopUpVisible] = useState(false);
	const [popUpPosition, setPopUpPosition] = useState({});

    const [startDate, setStartDate] = useState(startDateStr)
    const [endDate, setEndDate] = useState(endDateStr)
    const [chartOptions, setChartOptions] =useState({
        // Data: Data to be displayed in the chart
        data: [
            { month: 'Jan', views: 209, uniqueViews: 162 },
            { month: 'Feb', views: 209, uniqueViews: 162 },
            { month: 'Mar', views: 4603, uniqueViews: 3020 },
            { month: 'Apr', views: 1209, uniqueViews: 1162 },
            { month: 'May', views: 8162, uniqueViews: 8000 },  
            { month: 'Jun', views: 3209, uniqueViews: 2162 },
            { month: 'Jul', views: 1228, uniqueViews: 1250 },
            { month: 'Sep', views: 10145, uniqueViews: 9500 },
            { month: 'Nov', views: 2809, uniqueViews: 2000 },
        ],
        // Series: Defines which chart type and data to use
        series: [{ type: 'line', xKey: 'date', yKey: 'total', yName: "Total" }],
		connectMissingData:true,
        axis:[
            {type: 'number', position:'bottom'},
            { type: 'log', position: 'left', nice: false, base:10, tick: {
				minSpacing: 200,
			  },},
    ],
	animation: {
		enabled: true,
		duration:300
	},
	legend:{
		enabled:true,
		position:'top'
	}
      })

	const [viewGraphBy, setViewGraphBy]=useState('Views')
	const [viewsBy, setViewsBy]=useState('Views')
	const [pageData, setPageData] = useState({});
	const [stats, setStats] = useState({});
	const [isLoading, setIsLoading] = useState(true);

    useEffect(()=>{
        getOverviewData()
    },[startDate, endDate, viewGraphBy])

	useEffect(()=>{
		if(!pageData)
			{return}
		let chartData = pageData.graph
		if(chartData && viewGraphBy==='Views')
		{
			const totalViewsByDate =restructureData(chartData)
			const updatedOptions =produce(chartOptions,(draft)=>{
				draft.data = totalViewsByDate
			})
			setChartOptions(updatedOptions)
		}
	
		  
	},[deferredData, pageData, startDate, endDate, viewsBy])




    const getOverviewData=async()=>{
		setIsLoading(true)
        let authToken = decryptText(localStorage.getItem('aEmediat'));
		let payload = {
                            startDate:startDate,
                            endDate : endDate,
							viewType: viewGraphBy
                        };

		

        var deferredResponse = defer({ res: AnalysisServices.getPageViews(payload, authToken)
			.then(preprocessData).catch(error=>{
				console.log("Error in fetching analysis data")
				return null
			}) });
        
		setDeferredData(deferredResponse)

        deferredResponse.data.res.then(response => {
            setPageData(response)
			setIsLoading(false)
        })
        .catch(error => {
            Swal.fire({icon: "error",title: error.message,showConfirmButton: false,timer: 2500});
			setIsLoading(false)
        });

		if(viewGraphBy==='Revenue'){
			payload.revenueType = "courseRevenue"  //gatewayRevenue
			const response = await AnalysisServices.getRevenue(payload, authToken)
			if(response.success)
			{
				updateGraphByRevenueData(response.data)
			}
		}
		if(viewGraphBy==='Payment Gateways'){
			payload.revenueType = "gatewayRevenue"  //gatewayRevenue
			const response = await AnalysisServices.getRevenue(payload, authToken)
			if(response.success)
			{
				updateGraphByPaymentGatewayData(response.data)
			}
		}
                                       
    }

	const preprocessData = (response) => {
		if(!response.status===200)
			{ return null}
		console.log(response)
		response.pageLimit = 1000
		response.currentDocCount=response.data.overAllData.length
		response.totalDocCount=response.data.overAllData.length
		response.graph = response.data.graph
		response.data = response.data.overAllData.map((item,index)=>{
			item.color = colors[index%colors.length]
			return item
		})
		let paymentMethods = {}
		response.data.forEach(item => {
			item.paymentMethods?.forEach(method => {
				if (!paymentMethods[method]) {
					paymentMethods[method] = 1
				}
				paymentMethods[method]++
			})

			item.paymentMethods = Object.keys(paymentMethods).map(method => {
				return {name: method, value: paymentMethods[method]}
			})

			paymentMethods={}
		})

		calculateStats(response.data)
		console.log("reponse preprocessed",response)
		return response
	}

	const calculateStats = (data) => {
		if(viewGraphBy==='Views'){
			var stats={
				totalViews: 0,
				uniqueViews: 0,
				signUps: 0,
				purchases: 0,
				totalCollection: 0,
				tax:0,
				gatewayCharges:0,
				numberOfCourses:0,
			}
			console.log(data)
			data.forEach(item => {
				stats.totalViews =stats.totalViews+item.pageViews
				stats.uniqueViews = stats.uniqueViews + item.uniquePageViews
				stats.signUps = stats.signUps + item.signUpsFromPage
				stats.purchases = stats.purchases+Math.ceil(stats.signUps * item.signupToConversionRate/100)
				stats.totalCollection = stats.totalCollection +item.netPrice
				stats.tax= stats.tax + item.tax
				stats.gatewayCharges = stats.gatewayCharges + item.paymentGatewayFees
			})
			
		setStats(stats)
		}
	}

	function restructureData(courseViews){
		// Initialize an empty object to store the aggregated views
		console.log(courseViews)
		const aggregatedViews = {};
		let uniqueUsers = []
		// Iterate through the courseViews array
		courseViews.forEach(view => {
			let { createdAt, courseId } = view;
			 let date = createdAt.split("T")[0]
			 if(startDate <= date && date <=endDate)
			  { 
				  // Check if the date exists in the aggregatedViews object, if not, initialize it
				  if (!aggregatedViews[date]) {
					  aggregatedViews[date] = {};
				  }
				  // Check if the courseId exists in the aggregatedViews object for the specific date, if not, initialize it
				  if (!aggregatedViews[date][courseId]) {
					  aggregatedViews[date][courseId] = {
						  totalViews: 0,
						  courseName: view.courseId
					  };
				  }


				  if(viewsBy==='Conversions' && view.isSignedUp===true){
					aggregatedViews[date][courseId].totalViews++;
				  }else if(viewsBy==="Views"){
					// Increment the totalViews for the specific course and date
					aggregatedViews[date][courseId].totalViews++;
				  }else if(viewsBy==="Unique Views"){
					if(uniqueUsers.indexOf(view.userId)===-1 || view.userId===null){
						uniqueUsers.push(view.userId)
						aggregatedViews[date][courseId].totalViews++;
					}
				  }
			}
		});
  
		// Convert the aggregatedViews object into an array of objects
		// const aggregatedViewsList = Object.keys(aggregatedViews).map(date => {
		//     const courseViews = Object.values(aggregatedViews[date]);
		//     return {
		//         date: date,
		//         ...courseViews.map(course => ({[course.courseName ]: course.totalViews}))
		//     };
		// }); 
		// Convert the aggregatedViews object into an array of objects
		const aggregatedViewsList = Object.keys(aggregatedViews).map(date => {
			const courseViews = Object.values(aggregatedViews[date]);
  
			let combinedTotal = 0;
			let subjectViews = {};
  
			  courseViews.forEach(course => {
				
				// Add total views for the current course to the combined total
				combinedTotal += viewsBy==='Views' ? course.totalViews :
								 viewsBy==='Unique Views' ? course.totalViews: course.totalViews
				
				// Extract the subject name from the courseId
				const subject = course.courseName;
				
				// Add the subject views to the subjectViews object
				subjectViews[subject] = course.totalViews;
			});
  
			return {
				date: date,
				total:combinedTotal,
				...subjectViews
			};
		}); 
  
		return aggregatedViewsList
	}

	const updateGraphByRevenueData=(data)=>{
		let aggregatedData = {}
		data.forEach(obj=>{
			let date = obj.createdAt.split("T")[0]
			if(startDate <= date && date <=endDate)
				{ 
					// Check if the date exists in the aggregatedViews object, if not, initialize it
					if (!aggregatedData[date]) {
						aggregatedData[date] = {};
					}
					// Check if the courseId exists in the aggregatedViews object for the specific date, if not, initialize it
					if (!aggregatedData[date][obj.courseId]) {
						aggregatedData[date][obj.courseId] = {
							totalAmount: 0,
							courseId: obj.courseId
						};
					}
	
					// Increment the totalViews for the specific course and date
					aggregatedData[date][obj.courseId].totalAmount = 
					aggregatedData[date][obj.courseId].totalAmount+ obj.totalAmount;

				}
		})

		aggregatedData = Object.keys(aggregatedData).map(date => {
			const dateData = Object.values(aggregatedData[date]);
  
			let combinedTotal = 0;
			let subjectTotal = {};
  
			dateData.forEach(course => {
				
				// Add total views for the current course to the combined total
				combinedTotal += course.totalAmount;
				
				// Extract the subject name from the courseId
				const subject = course.courseId;
				
				// Add the subject views to the subjectViews object
				subjectTotal[subject] = course.totalAmount;
			});
  
			return {
				date: date,
				total:combinedTotal,
				...subjectTotal
			};
		}); 

		console.log(aggregatedData)
		aggregatedData = aggregatedData.reverse()
        console.log(aggregatedData)
		aggregatedData = aggregateData(aggregatedData, 30)

		const updatedOptions =produce(chartOptions,(draft)=>{
			draft.data = aggregatedData
		  })
		setChartOptions(updatedOptions)
  
	}
	const updateGraphByPaymentGatewayData=(data)=>{
		console.log("Gatway data", data)
		let aggregatedData = {}
		data.forEach(obj=>{
			let date = obj.createdAt.split("T")[0]
			if(startDate <= date && date <=endDate)
				{ 
					// Check if the date exists in the aggregatedViews object, if not, initialize it
					if (!aggregatedData[date]) {
						aggregatedData[date] = {};
					}
					// Check if the courseId exists in the aggregatedViews object for the specific date, if not, initialize it
					if (!aggregatedData[date][obj.paymentMethod]) {
						aggregatedData[date][obj.paymentMethod] = {
							totalAmount: 0,
							paymentMethod: obj.paymentMethod
						};
					}
	
					// Increment the totalViews for the specific course and date
					aggregatedData[date][obj.paymentMethod].totalAmount = 
					aggregatedData[date][obj.paymentMethod].totalAmount+ obj.totalAmount;

				}
		})

		aggregatedData = Object.keys(aggregatedData).map(date => {
			const dateData = Object.values(aggregatedData[date]);
  
			let combinedTotal = 0;
			let subjectTotal = {};
  
			dateData.forEach(course => {
				
				// Add total views for the current course to the combined total
				combinedTotal += course.totalAmount;
				
				// Extract the subject name from the courseId
				const subject = course.paymentMethod;
				
				// Add the subject views to the subjectViews object
				subjectTotal[subject] = course.totalAmount;
			});
  
			return {
				date: date,
				total:combinedTotal,
				...subjectTotal
			};
		}); 


		aggregatedData = aggregatedData.reverse()
		aggregatedData = aggregateData(aggregatedData, 30)

		const updatedOptions =produce(chartOptions,(draft)=>{
			draft.data = aggregatedData
			draft.series = [{ type: 'line', xKey: 'date', yKey: 'total', yName: "Total" },
			{ type: 'line', xKey: 'date', yKey: 'eazypay', yName: "Eazypay" },
			{ type: 'line', xKey: 'date', yKey: 'stripe', yName: "Stripe" },
			{ type: 'line', xKey: 'date', yKey: 'razorpay', yName: "Razorpay" },
			{ type: 'line', xKey: 'date', yKey: 'ccavenue', yName: "CCavenue" },

			]
		  })
		setChartOptions(updatedOptions)
	}

	function aggregateData(data, threshold) {
		if (data.length <= threshold) {
			return data;
		}

		const chunkSize = Math.ceil(data.length / threshold);
		const aggregatedData = [];

		for (let i = 0; i < data.length; i += chunkSize) {
			const chunk = data.slice(i, i + chunkSize);

			const avgDate = new Date(
				chunk.reduce((sum, point) => sum + new Date(point.date).getTime(), 0) / chunk.length
			);

			const aggregatedPoint = {
				date: avgDate.toISOString().split('T')[0], // Format date as YYYY-MM-DD
				total: chunk.reduce((sum, point) => sum + point.total, 0) / chunk.length
			};

			const keys = new Set(chunk.flatMap(Object.keys).filter(key => key !== 'date' && key !== 'total'));

			keys.forEach(key => {
				aggregatedPoint[key] = chunk.reduce((sum, point) => sum + (point[key] || 0), 0) / chunk.length;
			});

			aggregatedData.push(aggregatedPoint);
		}

		return aggregatedData;
	}
  
       //Fn to check and uncheck all checkboxes
	const handleCheckBoxes = (selectedRows) => {
		listOfCheckedRows = selectedRows;

		const updatedOptions =produce(chartOptions,(draft)=>{
			draft.series = [draft.series[0]]
			selectedRows.forEach(item => {
				draft.series.push({ type: 'line',  
					    xKey: 'date', 
						yKey: item.courseId, 
						yName: item.courseTitle, 
						stroke:item.color,
					    marker:{
						 enabled: true,
						 fill: item.color
						},
						legend:{
							item:{
								label:{
									color:item.color
								}
							}
						},
						tooltip:{
							renderer:{

							}
						}
					})
			})
		  })
		setChartOptions(updatedOptions)

		// setDisableBulkActionBtn( selectedRows.length>0 ? false : true)
	};
	// To sort 
	const handleSort = (data) => {
		const mapping = {'Date Added': 'createdAt', 'Date Modified': 'updatedAt'}
		var id = data[0]?.id ? mapping[data[0].id] : ''
		setSortBy(id)
		setIsSorted(data[0]?.desc ? data[0].desc : false)
	};
    const handleColumnBtnClick = (event) => {
		handlePopUpClose();
		columnsOptionPopUpRef.current.style.display = 'block';
		var position = getEventElementPosition(event);
		columnsOptionPopUpRef.current.style.top = position.top + 'px';
		columnsOptionPopUpRef.current.style.left = position.left - 160 + 'px';
		event.stopPropagation();
	};
    	// To close the popup container
	const handlePopUpOpen = (event, popUpName, data) => {
		if(popUpName==='dateMonthPopUp')
			{
				setSelectedYear(data)
			}
		handlePopUpClose()
		setPopUp(popUpName)
		setIsPopUpVisible(true)
		let position = getEventElementPosition(event);
		setPopUpPosition(position);
		event.stopPropagation();
	};
	const handlePopUpClose = () => {
		setIsPopUpVisible(false)
		columnsOptionPopUpRef.current.style.display = 'none';
	};
	// To handleSave buttons on the popup
	const handlePopUpSave = async (e, data) => {

		if(popUp === "dateMonthPopUp"){
			let selectedMonth = data
			const startDate = moment([selectedYear, selectedMonth]); // Month is zero-indexed
			const endDate = startDate.clone().endOf('month');
			const formattedStartDate = startDate.format('YYYY-MM-DD');
			const formattedEndDate = endDate.format('YYYY-MM-DD');
			setStartDate(formattedStartDate)
			setEndDate(formattedEndDate)
		}    else if(mainDateOption.includes(data)) {
        const {formattedStartDate, formattedEndDate} = calculateDates(data)
        setStartDate(formattedStartDate)
         setEndDate(formattedEndDate)
    }else if(popUp==='viewByPopUp')
		{
			setViewGraphBy(e[0].name)
		}
		handlePopUpClose();
		// getAllBssForms();
		// setDisableBulkActionBtn(true);
	};

    const handleChange = (field, value) => {
		if(field ==='startDate'){
			setStartDate(value)
		}else if(field==='endDate'){
			setEndDate(value)
		}else if(field==="viewsBy"){
			setViewsBy(value)
		}
	};
	function calculateDates(selectedOption) {
		const today = moment().startOf('day');
		let startDate, endDate;
  
		switch (selectedOption) {
			case 'Today':
				startDate = today.clone().startOf('day');
				endDate = today.clone().endOf('day');
				break;
			case 'Yesterday':
				startDate = today.clone().subtract(1, 'day').startOf('day');
				endDate = today.clone().subtract(1, 'day').endOf('day');
				break;
			case 'This Month':
				startDate = today.clone().startOf('month');
				endDate = today.clone().endOf('month');
				break;
			case 'This Week':
				startDate = today.clone().startOf('isoWeek');
				endDate = today.clone().endOf('isoWeek');
				break;
			case 'This Year':
				startDate = today.clone().startOf('year');
				endDate = today.clone().endOf('year');
				break;
			case 'Last 7 days':
				startDate = today.clone().subtract(6, 'days').startOf('day');
				endDate = today.clone().endOf('day');
				break;
			case 'Last 30 days':
				startDate = today.clone().subtract(29, 'days').startOf('day');
				endDate = today.clone().endOf('day');
				break;
			case 'Last 180 days':
				startDate = today.clone().subtract(179, 'days').startOf('day');
				endDate = today.clone().endOf('day');
				break;
			case 'Last 360 days':
				startDate = today.clone().subtract(359, 'days').startOf('day');
				endDate = today.clone().endOf('day');
				break;
			case 'This financial year':
				let date = getCurrentFinancialYear();
				startDate = date.startDate;
				endDate= date.endDate;
				break;
			case 'All time':
				startDate = moment(0); // Unix epoch
				endDate = today.clone().endOf('day');
				break;
			default:
				// Handle 'Select' or unknown option
				startDate = null;
				endDate = null;
				break;
		}
		const formattedStartDate = startDate.format('YYYY-MM-DD');
		const formattedEndDate = endDate.format('YYYY-MM-DD');
		return { formattedStartDate, formattedEndDate };
	}

	function getCurrentFinancialYear() {
		const today = moment();
		const currentMonth = today.month();
		const currentYear = today.year();
  
		let startYear, endYear;
  
		// If current month is April or later, financial year starts in the current year
		if (currentMonth >= 3) { // April is the fourth month (zero-based index)
			startYear = currentYear;
			endYear = currentYear + 1;
		} else { // Otherwise, financial year started in the previous year
			startYear = currentYear - 1;
			endYear = currentYear;
		}
  
		const startDate = moment(`${startYear}-04-01`).startOf('day');
		const endDate = moment(`${endYear}-03-31`).endOf('day');
  
		return { startDate, endDate };
	}

    return <OverviewView 
                deferredData={deferredData}
                handleCheckBoxes={handleCheckBoxes}
                handleSort={handleSort}
                pageData={pageData}
				startDate={startDate}
				endDate={endDate}
                setStartDate={setStartDate}
				setEndDate={setEndDate}
				// Start: Control Pop Up 
				popUp={popUp}
				popUpPosition = {popUpPosition}
				isPopUpVisible = {isPopUpVisible}
				handlePopUpClose = {handlePopUpClose} 
				handlePopUpOpen = {handlePopUpOpen} 
				handlePopUpSave= {handlePopUpSave}

				// Fullscreen
				fullscreenElementRef={fullscreenElementRef}
				toggleFullscreen={()=>toggleFullscreen(fullscreenElementRef)}
				//ColumnButton and popup
				columnsOptionPopUpRef={columnsOptionPopUpRef}
				handleColumnBtnClick={handleColumnBtnClick}

				handleChange={handleChange}

                chartOptions={chartOptions}
                viewGraphBy={viewGraphBy}
				viewsBy={viewsBy}

				mainDateOption={mainDateOption}
				years={years}
				months={months}
				stats={stats}

				isLoading={isLoading}
    
    />
}


export default Overview