import React from 'react';

import moment from 'moment'; import './style.css';
import SystemIcon from '@assets/common/system.png';

// created => => claimed => pull => reopen => transfer => waiting => moveToFolder => statusChange => call => comment => purchase => accessRemove ( done by system )=> unClaimedClosed
const activityColorMapping={
	'system':{
		'created' : '#bd1414',
		'statusChange': '#bd1414',
		'accessRemove': '#bd1414',
		'moveToFolder': '#bd1414',
		'pull': '#bd1414',
		'claimed': '#bd1414',
		'reopen': '#bd1414',
		'transfer': '#bd1414',
		'waiting': '#bd1414',
		'call': '#bd1414',
		'comment': '#bd1414',
		'purchase': '#128424',
		'accessRemove': '#bd1414',
		'unClaimedClosed': '#bd1414',},
	'admin':{
		'created' : '#cb8614',
		'statusChange': '#cb8614',
		'accessRemove': '#cb8614',
		'moveToFolder': '#cb8614',
		'pull': '#cb8614',
		'claimed': '#cb8614',
		'reopen': '#cb8614',
		'transfer': '#cb8614',
		'waiting': '#cb8614',
		'call': '#cb8614',
		'comment': '#cb8614',
		'purchase': '#cb8614',
		'accessRemove': '#cb8614',
		'unClaimedClosed': '#cb8614',}
}

function LeadActivityView(props) {
	return (
		<>
			<div className='ticketActivityView'>
				<div className="accordion" id="ticketActivityAccordion">
					<div className="accordion-item">
						<h2 className="accordion-header">
							<button className="accordion-button" data-bs-toggle="collapse" data-bs-target="#ticketActivityCollapseOne">
								Lead Activity
							</button>
						</h2>
						<div id="ticketActivityCollapseOne" className="accordion-collapse collapse show" data-bs-parent="#ticketActivityAccordion">
							<div className="accordion-body">

								{/* START : Day vise conversions accordion */}
								{props.activityData && 
									props.activityData.map((daysActivity, activityIndex) => (
										<div className="accordion ticketActivityDataAccordion" id={`taActivityAccordion_${activityIndex}`} key={activityIndex}>
											<div className="accordion-item">
												
												<h2 className="accordion-header">
													<button className="accordion-button accordionActivityBtn" data-bs-toggle="collapse" data-bs-target={`#taActivityCollapse_${activityIndex}`}>
														<span>{moment(daysActivity[0].createdAt).format('Do MMM, YYYY')}</span>
														<span>{moment(daysActivity[0].createdAt).format('hh:mm A')}</span>
													</button>
												</h2>
												<div id={`taActivityCollapse_${activityIndex}`} className="accordion-collapse collapse show" data-bs-parent={`#taActivityAccordion_${activityIndex}`}>

													<div className="accordion-body accordionActivityBody p-0" style={{paddingRight:'0px'}}>
														{daysActivity.length > 1 &&
															<div className='line accordionConversionsLine'></div>
														}

														{/* START : Time vise conversions accordion */}
														{daysActivity.map((conversions, conversionsIndex) => (
																<>
																	<div 
																		className={`accordion conversionsAccordion ${conversionsIndex === (daysActivity.length - 1) ? 'withLineHelper' : 'withoutLineHelper'} mb-3`} 
																		id={`taConversionsAccordion_${conversions._id}`} key={conversionsIndex}>

																		<div className="accordion-item accordionConversionsItem">
																		
																			<h2 className="accordion-header pb-1">
																				{conversionsIndex!==0?<button className="accordion-button accordionConversionsBtn" data-bs-toggle="collapse" data-bs-target={`#taConversionsCollapse_${conversions._id}`}>
																					<span className='s1'>
																						{ conversions.agentId==='system'? 
																						<img 
																						className='profileIcon rounded-circle' 
																						src={SystemIcon} alt='' />
																						: conversions.displayPhoto? <img 
																						  className='profileIcon rounded-circle' 
																						  src={conversions.displayPhoto} alt='' /> :
																						  'No image'}
																						{conversions?.fullName}
																					</span>
																					<span style={{fontSize: '13px', color:'#cccccc'}}>
																						{moment(conversions.createdAt).format('hh:mm A')}
																					</span>
																				</button>:
																				   <button className="accordion-button accordionConversionsBtn accordionConversionsBtnHiddenIcon">
																					<span className='s1'>
																					{ conversions.agentId==='system'? 
																						<img 
																						className='profileIcon rounded-circle' 
																						src={SystemIcon} alt='' />
																						: conversions.displayPhoto? <img 
																						  className='profileIcon rounded-circle' 
																						  src={conversions.displayPhoto} alt='' /> :
																						  'No image'}
																						{conversions?.fullName}
																					</span>
																					</button>	
																				}
																			</h2>
																			<div id={`taConversionsCollapse_${conversions._id}`} 
																				className={`accordion-collapse collapse ${conversionsIndex === 0 ? 'show' : ''}`}
																				data-bs-parent={`#taConversionsAccordion_${conversions._id}`}>
																				<div className="accordion-body accordionConversionsBody  py-0" 
														
																				>
																					<pre className='m-0 p-0'
																					 style={{color: activityColorMapping[conversions.agentId==='system'?'system':'admin'][conversions.activityType],
																					    fontFamily: 'Inter',
																						fontSize: '14px',
																						fontWeight: '400',
						
																					   }}
																					>
																						{conversions.title}
																					</pre>
																					{conversions?.description?<span>
																						{conversions.description}
																					</span> : null}
																				</div>
																				
																		</div>
																		</div>
																	</div>
																	{/* {conversionsIndex === (daysActivity.length - 1) && 
																	<div className='accordionLineHelperContainer'>
																	</div>
																	} */}
																</>
															))}
														{/* END : Time vise conversions accordion */}

													</div>
												</div>
											</div>
										</div>
									))
								}
								{/* END : Day vise conversions accordion */}

							</div>
						</div>
					</div>
				</div>


			</div>
		</>
	);
}

export default LeadActivityView;
