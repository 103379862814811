import React from "react";
import { Link } from "react-router-dom";

import "./style.css";

import TextInputV2 from "@components/TextInputV2";

import MoveBarIcon from "@assets/common/moveBarIcon.png";
import PlusCircleIcon from "@assets/common/plusCircleIcon.png";
import UploadIcon from "@assets/common/uploadIcon.png";
import DeleteIcon from "@assets/common/deleteIcon.png";

import { DragDropContext, Droppable } from "react-beautiful-dnd";
//DnD Import
import DnD from "@components/DnD";

import PopUp from "@components/PopUp";
import ImageInput from "@components/ImageInput";
import SaveBtn from "@components/SaveBtn";

function BlogsView(props) {
  return (
    <div
      className="rightHandRightPageMainContainer flex-grow-1 gap-3 d-flex flex-column"
      style={{ maxWidth: "none" }}
    >
      <div className="topContainer">
        <h4 className="heading">Blogs</h4>
        <div>
          <Link
            style={{ textDecoration: "none" }}
            className="cancelBtn"
            to="/content?tab=courses"
          >
            Cancel
          </Link>
          <SaveBtn
            onClick={props.handleSave}
            text={"Save"}
            altText={"Saving..."}
            disabled={props.isFormSaving}
          />
        </div>
      </div>
      <div
        className="d-flex gap-3 flex-grow-1"
        style={{
          maxWidth: "none",
          borderTop: "1px solid #e5e5e5",
          overflow: "scroll",
        }}
      >
        <div
          className="d-flex ms-3"
          style={{ width: "100%", maxWidth: "729px" }}
        >
          <div className="marginLeftContainer w-100 d-flex gap-3 flex-column mt-5 pt-2">
            {/* START : Error container */}
            {props.formValuesEmpty && (
              <div className="incompleteFieldsError">
                <span>All input fields are required</span>
              </div>
            )}
            {/* END : Error container */}
            {/* START : Deal ending section */}
            <div style={{ paddingBottom: "10%" }}>
              <DragDropContext onDragEnd={props.onDragEnd}>
                <Droppable droppableId="dataSet">
                  {(provided) => (
                    <div
                      className="d-flex flex-column gap-4"
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {props.blogs?.sections?.map((section, index) => (
                        <DnD
                          kay={index}
                          element={section}
                          index={index}
                          newComponent={
                            <div
                              className="d-flex gap-2 align-items-center"
                              style={{ fontSize: "14px" }}
                            >
                              <div
                                className="review d-block"
                                style={{ height: "auto" }}
                              >
                                <div className="ps-4">
                                  <span style={{ color: "#bbb" }}>
                                    Section {index + 1}
                                  </span>

                                  <h5
                                    className="subHeading"
                                    style={{ fontSize: "14px" }}
                                  >
                                    <input
                                      className="blogTitle"
                                      type="text"
                                      value={section.title}
                                      onChange={(event) => {
                                        props.handleChange(
                                          event.target.value,
                                          "title",
                                          index
                                        );
                                      }}
                                    ></input>
                                  </h5>
                                </div>
                                <div className="d-flex align-items-center gap-2">
                                  <img
                                    className="moveIcon"
                                    src={MoveBarIcon}
                                    style={{ height: "5px" }}
                                  ></img>
                                  <textarea
                                    className="w-100 blogDescription p-2"
                                    placeholder="Type paragraph.."
                                    value={section.description}
                                    onChange={(event) => {
                                      props.handleChange(
                                        event.target.value,
                                        "description",
                                        index
                                      );
                                    }}
                                  ></textarea>
                                </div>
                                <div className="d-flex align-items-center gap-2 mt-2">
                                  <img
                                    className="moveIcon"
                                    src={MoveBarIcon}
                                    style={{ height: "5px" }}
                                  ></img>
                                  <input
                                    type="text"
                                    className="w-100  blogThumbnail p-2"
                                    placeholder="Click here to add image"
                                    value={section.thumbnailUrl}
                                    onClick={(event) =>
                                      props.handleOpenPopUp(event, index)
                                    }
                                  />
                                </div>
                              </div>
                              <img
                                className="addReviewIcon"
                                src={PlusCircleIcon}
                                onClick={(event) => {
                                  props.handleAddBlog(event, index);
                                }}
                              />
                              <img
                                className="addReviewIcon"
                                src={DeleteIcon}
                                onClick={(event) => {
                                  props.handleDeleteBlog(event, index);
                                }}
                              />
                            </div>
                          }
                        />
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>

              <center>
                {props.blogs?.sections?.length > 0 ? (
                  ""
                ) : (
                  <div className="bg-light w-100 p-3 mt-2">
                    <button
                      className="btn btn-dark d-flex gap-2 align-items-center w-50 justify-content-center"
                      style={{ fontSize: "14px" }}
                      onClick={(event) => {
                        props.handleAddBlog(event, null);
                      }}
                    >
                      <img className="addReviewIcon " src={PlusCircleIcon} />
                      Add Blog
                    </button>
                  </div>
                )}
              </center>
            </div>
          </div>
        </div>
      </div>

      <PopUp
        visible={props.isPopUpVisible}
        top={0}
        left={0}
        handleOutSideClick={props.handlePopUpClose}
      >
        <div className="full-page-overlay">
          <ImageInput
            handleClose={props.handlePopUpClose}
            handleAdd={(event, files) =>
              props.handleChange(files, "thumbnailUrl")
            }
          />
        </div>
      </PopUp>
    </div>
  );
}

export default BlogsView;
