import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import BlogsView from './view';
import * as CourseServices from '@services/Course';
import { decryptText } from '@library/enc-dec';

import ErrorView from '@components/ErrorView';
import { getEventElementPosition} from '@helpers/common.js';
import {localData} from './data.js'
import Swal from 'sweetalert2';

function Blogs(props) {
	const { id } = useParams();
	const [blogs, setBlogs] = useState([])

	const [formValuesEmpty, setFormValuesEmpty] = useState(false);
	const [isPopUpVisible, setIsPopUpVisible] = useState(false);
	const [selectedBlogIndex, setSelectedBlogIndex] = useState(false);
	const [isFormSaving, setIsFormSaving] = useState(false)	

	useEffect(()=>{
		getBlogs()
	},[])
	
	const getBlogs = async()=>{
		try {
			let authToken = decryptText(localStorage.getItem('aEmediat'));
			const response = await CourseServices.getBlogs(id, authToken);
			if (response.success) {
				setBlogs(response.data)
				return;
			}
			console.log(response.message);
		} catch (err) {
			console.log('Error coming while fetching blogs', err);
		}
	}

	const handleSave = async()=>{
		try {
			setIsFormSaving(true)
			let authToken = decryptText(localStorage.getItem('aEmediat'));
			const sectionsWithoutId = blogs.sections.map(section => {
				if (section.thumbnailUrl === '') {
					section.thumbnailUrl = null;
				}
				const { _id, ...rest } = section;
				return rest;
			});
			const payload = {
				courseId: id,
				sections : sectionsWithoutId
			}
			const response = await CourseServices.updateBlog(payload, authToken);
			if(response.success)
			{
				Swal.fire({icon: "success", title: response.message, showConfirmButton: false,timer: 2500});
			}			
			else{
				Swal.fire({icon: "error", title: response.message, showConfirmButton: false,timer: 2500});
			}
			setIsFormSaving(false)
		} catch (err) {
			console.log('Error coming while updating blogs', err);
			setIsFormSaving(false)
		}
	}

	const handleChange = async(value, field, index)=>{
        var updatedBlogs = {...blogs}
		if(field=='thumbnailUrl')
		{
			if(typeof(value[0]) ==='string')
			{
				value = value[0]
			}else{
				var imageUrl = await updateBlogThumbnail(value[0])
				value = imageUrl
			}
			index=selectedBlogIndex
			handlePopUpClose()
		}
        updatedBlogs.sections[index][field] = value
		setBlogs(updatedBlogs) 
	}

	 const onDragEnd = (result) => {
		  if (!result.destination) return;
		const updatedBlogs = {...blogs};
		const [reorderedItem] = updatedBlogs.sections.splice(result.source.index, 1);
		updatedBlogs.sections.splice(result.destination.index, 0, reorderedItem);
		setBlogs(updatedBlogs);
	  };
  
	const handleAddBlog=(event, index)=>{
		var updatedBlogs = {...blogs}
		var newBlog= {"title": "Type heading",
						 "description": "",
						 "thumbnailUrl": "",
						 "_id": "section"+index}
 
		if(index!=null)
		 {updatedBlogs.sections.splice(index, 0, newBlog)}
		else{
			updatedBlogs.id = id
			updatedBlogs.sections=[newBlog]
		}
	 setBlogs(updatedBlogs)   
	 }

	 const handleDeleteBlog=(event, index)=>{
		var updatedBlogs = {...blogs}
		updatedBlogs.sections.splice(index, 1)
	    setBlogs(updatedBlogs)   
	 
	}

	const handlePopUpClose = () => {
		setIsPopUpVisible(false);
	};
	// Open popUps
	const handleOpenPopUp = (event, index) => {
		setIsPopUpVisible(true)
		setSelectedBlogIndex(index)
		event.stopPropagation();
    }

	const updateBlogThumbnail =async(image)=>{
		try {
			  let authToken = decryptText(localStorage.getItem('aEmediat'));
		   const payload={
			  'thumbnailUrl':image
		   }
			  let response = await CourseServices.updateBlogThumbnail(payload, authToken);
			  if (response.success) {
				  return response.data.thumbnailUrl;
			  }
			  alert(response.message);
		  } catch (err) {
			  console.log('Error coming while uploading blog thumbnail', err);
		  }
	 }

	return (
		<>
			{id ? (
				<BlogsView
				    blogs={blogs}
					formValuesEmpty={formValuesEmpty}
					isFormSaving={isFormSaving}
					onDragEnd={onDragEnd}
					handleAddBlog={handleAddBlog}
					handleDeleteBlog={handleDeleteBlog}
					handleChange={handleChange}
					handleSave={handleSave}

					isPopUpVisible={isPopUpVisible}
					handlePopUpClose={handlePopUpClose}
					handleOpenPopUp={handleOpenPopUp}
				/>
			) : (
				<ErrorView text={'Please Select or Add a course before adding videos.'} />
			)}
		</>
	);
}

export default Blogs;
