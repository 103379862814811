import React from 'react';
import { Text, View, Image, StyleSheet, Font } from '@react-pdf/renderer';

import logo from '@assets/navbar/headerLogo.png';
import line from '@assets/invoice/line.png';

import InterRegular from '@assets/invoice/Inter-Regular.ttf';
import InterMedium from '@assets/invoice/Inter-Medium.ttf';
import InterSemiBold from '@assets/invoice/Inter-SemiBold.ttf';
import InterBold from '@assets/invoice/Inter-Bold.ttf';

Font.register({ family: 'InterRegular', src: InterRegular });
Font.register({ family: 'InterMedium', src: InterMedium });
Font.register({ family: 'InterSemiBold', src: InterSemiBold });
Font.register({ family: 'InterBold', src: InterBold });

const styles = StyleSheet.create({
	logo: {
		width: '113px',
		height: '27px',
		marginTop: -30,
		marginLeft: 410,
	},
	headingSection: {
		marginTop: -807,
		marginLeft: -25,
		padding: 0,
	},
	gstText: {
		color: '#7f7f80',
		fontSize: 9,
	},
	textInvoice: {
		marginTop: 30,
		color: '#252525',
	},
	billNo: {
		fontSize: '10px',
	},
	underline: {
		width: 150,
		height: '30px',
		marginTop: -12,
		marginLeft: 75,
	},
});

const InvoiceHeader = ({ invoice }) => (
	<>
		<View style={styles.headingSection}>
			<Text style={{ fontFamily: 'InterBold' }}>eMediSkill Services LLP</Text>
			<Text style={{ fontFamily: 'InterBold' }}>
				Aristomisr, F-06, Plot No. 42, Sr. No 588/2A, Burhani Colony, Market Yard,
				Pune - 411037
			</Text>
			<Text>
				<Text style={styles.gstText}>GSTIN</Text>- 27AAJFE5493L1ZT
			</Text>
			<Text>
				<Text style={styles.gstText}>SAC CODE</Text>- 999295
			</Text>
			<Image style={styles.logo} src={logo} />

			<Text style={styles.textInvoice}>
				Tax Invoice <Text style={styles.billNo}> {invoice.data.invoiceId}</Text>
			</Text>
			<Image style={styles.underline} src={line} />
		</View>
	</>
);

export default InvoiceHeader;
