
import ImpressionsContainer from '@components/Analytics/ImpressionsContainer'
import BasicTableGrid from '@components/BasicTableGrid';
import DistributionBar from '@components/Analytics/DistributionBar'
import FilterDate  from '@components/FilterDate';
import FilterIcon from '@assets/common/filter.png';
import DownIcon from '@assets/common/dropDown.png';
import { AgChartsReact } from 'ag-charts-react';
import PopUp from '@components/PopUp';
import SelectionPopUp from '@components/SelectionPopUp';
import PaymentGatewayDetails from '@components/Analytics/PaymentGatewayDetails';

import moment from 'moment';
import './style.css'


const viewOption = [{_id:'Views', name:'Views'},
    {_id:'Revenue', name:'Revenue'},
    {_id:'Payment Gateways', name:'Payment Gateways'},
    {_id:'Sign-Ups', name:'Sign-Ups'},
    ]


function OverviewView(props){

    return <div className='AdminPageMainContainer bg-white d-flex gap-3 p-4 overflow-scroll'>
                <div className=" d-flex gap-2">
                        <div className='border rounded p-2 d-flex justify-content-center align-items-center' style={{height:'32px'}}
                         onClick={(e)=>props.handlePopUpOpen(e, 'dateSelectPopUp')}
                        >
                            <img style={{height: '15px'}} src={FilterIcon}/>
                        </div> 
                        <div className='filterSelect'><FilterDate title="Start Date" value={props.startDate} 
                        onChange={(event)=>{props.handleChange('startDate', event.target.value)}}/></div>
                        <div className='filterSelect'><FilterDate title="End Date" value={props.endDate} 
                        onChange={(event)=>{props.handleChange('endDate', event.target.value)}} /></div>
                        <div className='filterSelect' style={{cursor:'pointer'}}
                              onClick={(e)=>props.handlePopUpOpen(e,"viewByPopUp")}>
                            {props.viewGraphBy} <img src={DownIcon} style={{height:'7px'}}/></div>
                </div>
                <div className="d-flex flex-column gap-2">
                    <div className="row mx-1" style={{height: '500px'}}>
                             
                            <div className={props.viewGraphBy=='Sign-Ups'?
                                "col-12 border rounded p-0":"col-7 border rounded p-0"}>
                            {(props.isLoading)?
                            <div className='w-100 h-100 d-flex'>

                                <div className="spinner-border text-danger m-auto" role="status">
                                <span className="visually-hidden">Loading...</span>
                                </div> 
                            </div>
                            :
                            <div className=' pb-4' style={props.viewGraphBy=='Views'?{height:'87%', justifyContent:'flex-end'}:{height:'100%'}}>
                            {props.viewGraphBy ==='Views' &&
                                <div className='d-flex w-100 justify-content-between' style={{height:'20%'}}>
                                    <div className={props.viewsBy==='Views'?'chartTab chartTabSelected border-end border-bottom' : 
                                    'chartTab border-end border-bottom'}
                                        onClick={()=>{props.handleChange('viewsBy', 'Views')}}
                                       >Views<br></br><br></br><b>
                                        
                                        {props.pageData?.data?.reduce((total, item) => total + item.pageViews, 0) || 0 }</b></div>
                                    <div className={props.viewsBy==='Unique Views'?'chartTab chartTabSelected border-end border-bottom' : 
                                    'chartTab border-end border-bottom'} 
                                        onClick={()=>{props.handleChange('viewsBy', 'Unique Views')}}>
                                        Unique Views<br></br><br></br><b>
                                        {props.pageData.data?.reduce((total, item) => total + item.uniquePageViews, 0) || 0 }</b></div>
                                    <div className={props.viewsBy==='Conversions'?'chartTab chartTabSelected border-end border-bottom' : 
                                        'chartTab border-end border-bottom'} 
                                        onClick={()=>{props.handleChange('viewsBy', 'Conversions')}}
                                        >Conversions<br></br><br></br><b>
                                        {props.pageData.data?.reduce((total, item) => total + item.signUpsFromPage, 0) || 0 }</b></div>
                                </div>}
                                    <AgChartsReact options={props.chartOptions} />
                            </div>
                            }
                                </div>


                            {props.viewGraphBy!=='Sign-Ups' &&<div className="col-5 d-flex rounded-1">
                            
                                {props.viewGraphBy==='Views'&& <ImpressionsContainer
                                title={'Impressions and how they led to conversions'}
                                subTitle={`Data available from ${moment(props.startDate).format('DD MMM, YYYY')} to ${moment(props.endDate).format('DD MMM, YYYY')}`}
                                isLoading={props.isLoading}
                                funnel={true}
                                child1={<>
                                        <div className="funnelChild" style={{fontSize:'13px'}}>Product page view
                                            <div style={{fontSize:'18px', color: '#252525'}}>{props.stats?.totalViews}</div>
                                            <div style={{height:'3px', width:'100%', backgroundColor: '#ccc', display:'flex', borderRadius:'10px', overflow:'hidden'}}>
                                                <div style={{flex: props.stats?.uniqueViews/props.stats?.totalViews, height:'3px', backgroundColor: '#4f3bca'}}>
                                                </div>
                                            </div>
                                            <div><b>{(props.stats?.uniqueViews/props.stats?.totalViews *100).toFixed(2)}</b>
                                                % of the impressions are unique</div>
                                        </div>
                                        </>}
                                child2={<div className="funnelChild flex-row"><b>{props.stats?.uniqueViews}</b> unique views, 
                                <b>{props?.stats?.signUps!==0? 
                                (props.stats?.signUps/props.stats?.uniqueViews*100).toFixed(2) : 0 }
                                </b> % sign-up rate</div>}
                                child3={<>
                                        <div className="funnelChild">New sign ups from product page
                                        <div style={{fontSize:'18px', color: '#252525'}}>
                                            {props.stats?.signUps}
                                            </div>
                                            </div>
                                </>}
                                child4={<div className="funnelChild flex-row">
                                    <b>{props.stats.purchases ? ((props.stats?.purchases/props.stats?.signUps *100).toFixed(2)) : 0}</b>
                                    % sign-up to conversion rate</div>}
                                child5={<div className="funnelChild">
                                            <div>Conversions</div>
                                            <div><b>{props.stats?.purchases}</b> users purchased,   courses</div>
                                    </div>
                                    }/>}
                                
                            {props.viewGraphBy==='Revenue'&& <ImpressionsContainer                        
                                funnel={true}
                                title={'Impressions and how they led to conversions'}
                                subTitle={`Data available from ${moment(props.startDate).format('DD MMM, YYYY')} to ${moment(props.endDate).format('DD MMM, YYYY')}`}
                                isLoading={props.isLoading}
                                child1={<>
                                        <div className="funnelChild" style={{fontSize:'13px'}}>Total collection
                                            <div style={{fontSize:'18px', color: '#252525'}}>INR {props.stats?.totalCollection}</div>
                                            <div style={{height:'3px', width:'100%', backgroundColor: '#ccc', display:'flex', borderRadius:'10px', overflow:'hidden'}}>
                                                <div style={{flex: (props.stats.tax /props.stats.totalCollection*100/100).toFixed(2), height:'3px', backgroundColor: '#4f3bca'}}>
                                                </div>
                                            </div>
                                            <div><b>{(props.stats.tax /props.stats.totalCollection*100).toFixed(2)}</b>
                                                % of the collection is tax</div>
                                        </div>
                                        </>}
                                child2={<div className="funnelChild flex-row"><b>{props.stats?.uniqueViews}</b> courses and, 
                                <b>{(props.stats?.signUps/props.stats?.uniqueViews*100).toFixed(2)}</b> new users</div>}
                                child3={<>
                                        <div className="funnelChild d-flex flex-row g-2">
                                            <div>
                                                Tax
                                                <div style={{fontSize:'18px', color: '#252525'}}>
                                                INR {props.stats?.tax}
                                                </div></div>
                                            <div>
                                                Payment Gayeway
                                                <div style={{fontSize:'18px', color: '#252525'}}>
                                                INR {props.stats?.gatewayCharges}
                                                </div></div>
                                            </div>
                                </>}
                                child4={<div className="funnelChild flex-row">
                                    <b>{(props.stats?.gatewayCharges/props.stats?.totalCollection *100).toFixed(2)}</b>
                                    % payment gateway charges</div>}
                                child5={<div className="funnelChild">
                                            <div>Actual collection</div>
                                            <div style={{fontSize:'18px', color: '#252525'}}>INR {props.stats?.totalCollection - 
                                                props.stats?.tax - props.stats?.gatewayCharges
                                                }</div>
                                    </div>
                                    }/>}

                            {props.viewGraphBy==='Payment Gateways'&& <ImpressionsContainer
                                        title={'Collection by payment bank'}
                                        subTitle={`Data available from ${moment(props.startDate).format('DD MMM, YYYY')} to ${moment(props.endDate).format('DD MMM, YYYY')}`}
                                        isLoading={props.isLoading}
                                        funnel={false}
                                        child1={
                                                <div className="d-flex flex-wrap gap-2 flex-1 w-100 h-100 " style={{fontSize:'13px',justifyContent:"space-between"}}>
                                                    
                                                   {[1,2,3,4,5].map((value, index)=>{
                                                    return <PaymentGatewayDetails 
                                                      name={"CIMET"}
                                                      value={"INR 200"}
                                                      tax ={"INR 100"}
                                                      fee={"INR 50"}
                                                    />
                                                    })} 
                                                </div>
                                                }
                                        />}
                            </div>}

                            
                    </div>
                    <div className="d-flex" style={{height: '400px'}}>
                                        
                        <BasicTableGrid 
                            gridId='AnalyticsOverview'
                            data={props.deferredData} // Data to be displayed
                            fixedCols={2} // How many columns are fixed and not scrolling
                            initialSize = {[35,499,183,258,150,133,262]}
                            initialSorting={[{"id": "Date Added","desc": false}]}
                            handleSelect={(selectedRows) => {
                                props.handleCheckBoxes(selectedRows);
                            }}
                            length={20}
                            onSortChange={(data) => {}
                                // props.handleSort(data)
                            }
                            columnsOptionPopUpRef={props.columnsOptionPopUpRef}
                            isColorEnabled={true}
                            isNavigationHidden={true}
                            columns={[
                            {header: "Columns",
                                cell: (data) => {
                                const info = data.row.original;
                                return (<div className="d-flex gap-2">
                                                    <div className="thumbnailContainer rounded bg-light" 
                                                    style={{backgroundImage:`url(${info.courseThumbnailUrl})`}}>
                                                    </div>
                                            <div className="d-flex flex-column justify-content-center gap-2 ms-2">
                                                <div>{info?.courseTitle} </div>
                                            </div>
                                        </div>);
                                },
                            },
                            {header: 'Page Views', 
                            cell: (data) => {
                                const info = data.row.original;
                                return <div>{info.pageViews}</div>}
                            },
                            {header: 'Unique Page Views', 
                            cell: (data) => {
                                const info = data.row.original;
                                return <div>{info.uniquePageViews}</div>}
                            },
                            {header: 'Sign-ups form page', 
                            cell: (data) => {
                                const info = data.row.original;
                                return <div>{info.signUpsFromPage}</div>}
                            },
                            {header: 'Views to conversion rate', 
                            cell: (data) => {
                                const info = data.row.original;
                                return <div>{info.viewsToConversionRate}</div>}
                            },
                            {header: 'Sign-up to conversion rate', 
                            cell: (data) => {
                                const info = data.row.original;
                                return <div>{info.signupToConversionRate}</div>}
                            },
                            {header: 'Payment Gateway', 
                            cell: (data) => {
                                const info = data.row.original;
                                return <DistributionBar 
                                        data = {info.paymentMethods}
                                      />}
                            },
                            {header: 'Net Amount', 
                            cell: (data) => {
                                const info = data.row.original;
                                return <div>{info.netPrice}</div>}
                            },
                            {header: 'Gross Amount', 
                            cell: (data) => {
                                const info = data.row.original;
                                return <div>{info.grossPrice}</div>}
                            },
                            {header: 'Payment Gateway Charges', 
                            cell: (data) => {
                                const info = data.row.original;
                                return <div>{info.paymentGatewayFees}</div>}
                            },
                            {header: 'Bank Settled Amount', 
                            cell: (data) => {
                                const info = data.row.original;
                                return <div>{info.settlementFees}</div>}
                            }
                            
                            ]}

                        />
                </div>
                </div>
                {props.popUp === "dateSelectPopUp"  &&<PopUp visible={props.isPopUpVisible}
				top={180}
				left={280}
				handleOutSideClick={props.handlePopUpClose}>
				<div style={{height: 'auto', overflow:'hidden'}}>
					{props.mainDateOption.map((option, index) => (
						<div
							key={index}
							onClick={(e) => option !=='Select' ?props.handlePopUpSave(e, option):props.handlePopUpOpen(e, 'dateYearPopUp')}
							className='settingOption d-flex'>
							{option}
							<img className='ms-auto' src={option.img} style={{height:'12px'}}/>
						</div>
					))}
				</div>
                </PopUp>}
                {props.popUp === "dateYearPopUp"  &&<PopUp visible={props.isPopUpVisible}
                    top={180}
                    left={280}
                    handleOutSideClick={props.handlePopUpClose}>
                    <div style={{height: 'auto', overflow:'hidden'}}>
                        {props.years.map((option, index) => (
                            <div
                                key={index}
                                onClick={(e) => props.handlePopUpOpen(e, 'dateMonthPopUp', option)}
                                className='settingOption d-flex'>
                                {option}
                                <img className='ms-auto' src={option.img} style={{height:'12px'}}/>
                            </div>
                        ))}
                    </div>
                </PopUp>}
                {props.popUp === "dateMonthPopUp"  &&<PopUp visible={props.isPopUpVisible}
                    top={180}
                    left={280}
                    handleOutSideClick={props.handlePopUpClose}>
                    <div style={{height: 'auto', overflow:'hidden'}}>
                        {props.months.map((option, index) => (
                            <div
                                key={index}
                                onClick={(e) => props.handlePopUpSave(e, index)}
                                className='settingOption d-flex'>
                                {option}
                                <img className='ms-auto' src={option.img} style={{height:'12px'}}/>
                            </div>
                        ))}
                    </div>
                </PopUp>}

                {props.popUp === "viewByPopUp"  &&<PopUp visible={props.isPopUpVisible}
				top={210}
				left={610}
				handleOutSideClick={props.handlePopUpClose}>
				<SelectionPopUp 
				  data = {viewOption}
				  optionKey= {"name"}
				  title={"Select year tag"}
				  searchPlaceholder={""}
				  isSearchEnabled={false}
				  height= {"auto"}
				  width= {"auto"}
				//   selectedItems={viewOption.filter(item => props.selectedViewOption?.id ===item._id)}
				  dataKey={"_id"}
				  selectedItemsKey={"_id"}
				  selectionType={"single"}
				  handleCancel ={props.handlePopUpClose}
				  handleSave={props.handlePopUpSave}/>
			</PopUp>}
                
            </div>
}


export default OverviewView