import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import ContentView from './view';
import * as SeriesServices from '@services/Series';
import * as CourseContentServices from '@services/CourseContent';
import { decryptText } from '@library/enc-dec';

import ErrorView from '@components/ErrorView';
import { getEventElementPosition} from '@helpers/common.js';
import Swal from 'sweetalert2';

function Content(props) {
	const dataFetchedRef = useRef(null);
	const { id } = useParams();
    const [courseContent, setCourseContent] = useState([])
    const [allSeries, setAllSeries] = useState([])

	const [formValuesEmpty, setFormValuesEmpty] = useState(false);
	
	const [popUpPosition, setPopUpPosition] = useState({top:0, left:0});
	const [isSelectSeriesPopUpVisible, setIsSelectSeriesPopUpVisible] = useState(false);
	const [isImageUploadPopUpVisible, setIsImageUploadPopUpVisible] = useState(false);
	const [selectedContentIndex, setSelectedContentIndex] = useState('')
	const [isFormSaving, setIsFormSaving] = useState(false)

	useEffect(()=>{
		getCourseContent()
		getAllSeries()
	},[])
	
	const getCourseContent = async()=>{
		try {
			let authToken = decryptText(localStorage.getItem('aEmediat'));
			const response = await CourseContentServices.getCourseContent(id, authToken);
			if (response.success) {
				setCourseContent(response.data.content)
				return;
			}
			console.log(response.message);
		} catch (err) {
			console.log('Error coming while fetching courses prices', err);
		}
	}

	const getAllSeries = async()=>{
		try {
			let authToken = decryptText(localStorage.getItem('aEmediat'));
			const payload={
				pageNo: 0,
				limit: 1000,
				isSorted: true
			}
			const response = await SeriesServices.getAllSeries(payload, authToken);
			if (response.success) {
				setAllSeries(response.data);
				return;
			}
			alert(response.message);
		} catch (err) {
			console.log('Error coming while fetching all series', err);
		}
	}

	const handleSave = async()=>{
		try {
			setIsFormSaving(true)
			let authToken = decryptText(localStorage.getItem('aEmediat'));
			const courseContentWithoutId = courseContent.map(({ _id, ...rest }) => rest);
			const payload = {
				courseId: id,
				content : courseContentWithoutId
			}
			const response = await CourseContentServices.updateCourseContent(payload, authToken);
			if(response.success)
			{
				Swal.fire({icon: "success", title: response.message, showConfirmButton: false,timer: 2500});
			}
			else{
				Swal.fire({icon: "error", title: response.message, showConfirmButton: false,timer: 2500});
			}
			
			setIsFormSaving(false)
		} catch (err) {
			console.log('Error coming while fetching courses prices', err);
			setIsFormSaving(false)
		}
	}

	const handleChange = async(value, field, index)=>{
        if(field==='series')
		{
			setCourseContent(value)
			handlePopUpClose()
		}
		else if(field==='imageUpload')
		{
			var imageUrl = await updateSeriesThumbnail(value[0])
			
			var updatedCourseContent = [...courseContent]
            updatedCourseContent[selectedContentIndex].thumbnailUrl = imageUrl
			setCourseContent(updatedCourseContent)
			handlePopUpClose()
		}
		else if(field==='title')
		{
			var updatedCourseContent = [...courseContent]
            updatedCourseContent[index].title = value
			setCourseContent(updatedCourseContent)
		}
	}

	 const onDragEnd = (result) => {
		  if (!result.destination) return;
		const updatedCourseContent = [...courseContent];
		const [reorderedItem] = updatedCourseContent.splice(result.source.index, 1);
		updatedCourseContent.splice(result.destination.index, 0, reorderedItem);
		setCourseContent(updatedCourseContent);
	  };
  
	const handlePopUpClose = () => {
		setIsImageUploadPopUpVisible(false);
        setIsSelectSeriesPopUpVisible(false);
	};
	// Open popUps
	const handleOpenPopUp = (event, popUp, data) => {
		handlePopUpClose();
        // setPopUpType(popUp)
		switch(popUp){
		case 'imageUpload':
			setSelectedContentIndex(data)
			setIsImageUploadPopUpVisible(true)
			break
		case 'seriesSelect': 
			setIsSelectSeriesPopUpVisible(true)
			break
      }
      let position = getEventElementPosition(event);
		setPopUpPosition(position);
		event.stopPropagation();
    }

	const updateSeriesThumbnail =async(image)=>{
		try {
			  let authToken = decryptText(localStorage.getItem('aEmediat'));
		   const payload={
			  'thumbnailUrl':image
		   }
			  let response = await CourseContentServices.updateSeriesThumbnail(payload, authToken);
			  if (response.success) {
				  return response.data.thumbnailUrl;
			  }
			  alert(response.message);
		  } catch (err) {
			  console.log('Error coming while fetching course details', err);
		  }
	 }

	return (
		<>
			{id ? (
				<ContentView
				    courseContent={courseContent}
					allSeries={allSeries}
					formValuesEmpty={formValuesEmpty}
					isFormSaving={isFormSaving}
					onDragEnd={onDragEnd}

					popUpPosition={popUpPosition}
					isSelectSeriesPopUpVisible={isSelectSeriesPopUpVisible}
					isImageUploadPopUpVisible={isImageUploadPopUpVisible}
					handlePopUpClose={handlePopUpClose}
					handleOpenPopUp={handleOpenPopUp}
					selectedContentIndex = {selectedContentIndex}
					handleChange={handleChange}
					handleSave={handleSave}
				/>
			) : (
				<ErrorView text={'Please Select or Add a course before adding videos.'} />
			)}
		</>
	);
}

export default Content;
