import React, { useState, useEffect } from 'react';
import PhotoProofView from './view';

import * as VerificationServices from '@services/Verification';
import * as VerificationActions from '@redux/actions/Verification';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { decryptText } from '@library/enc-dec';


function PhotoProof(props) {
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [iscardVisible, setIscardVisible] = useState(false);
    const [selectedPhotoProof, setSelectedPhotoProof] = useState(false);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [isMenuVisible, setIsMenuVisible] = useState(false)
	
	const [fromInputDate, setFromInputDate] = useState('');
	const [toInputDate, setToInputDate] = useState('');

	let authToken = decryptText(localStorage.getItem('aEmediat'));

	useEffect(() => {
		getPendingPhotoProof();
	}, [fromInputDate, toInputDate]);

	// Get All the verification data from API service 
	const getPendingPhotoProof = async () => {
		let authToken = decryptText(localStorage.getItem('aEmediat'));
		let payload ={
			toInputDate,
			fromInputDate
		}
		let response = await VerificationServices.getPendingPhotoProof(payload, authToken);
		if (response.success) {
			props.updatePendingPhotoProof([...response.data]);
			return;
		}
		alert(response.message);
	};

	const verifySinglePhotoProof = async(userId, verificationType, status)=>{
		closeVerificationPopUp()
		let response = await VerificationServices.updateDocumentStatus(authToken, userId, verificationType, status)
	    if (response.success){
			getPendingPhotoProof()
		}
		alert(response.message)
	}

	const verifyMultiplePhotoProof = async(userIdList, verificationType, status)=>{
		if(selectedUsers.length===0)
		{
			alert("No User selected")
			return
		}
		let response = await VerificationServices.updateMultipleDocumentStatus(authToken, userIdList, verificationType, status)
	    if (response.success){
			getPendingPhotoProof()
		}
		alert(response.message)
	}


	////////////////////////////////////////////////////////////////////////////////////////////
	const updateSelectedUsers = (id)=>{
		let updatedUsers = [...selectedUsers]
		 const index = updatedUsers.indexOf(id);
		 if (index !== -1) {
			 updatedUsers.splice(index, 1);
		 } else {
			 updatedUsers.push(id);
		 }
		setSelectedUsers(updatedUsers) 
	 }
 
	 const selectAllDocuments = () =>{ 
 
		 if( ! isAllSelected)
		 {
			let allUserIds = []
			 props.pendingPhotoProof.forEach(proof => {
				 allUserIds.push(proof._id)
			 });
 
			 setSelectedUsers(allUserIds)
		 }
		 else{
			 setSelectedUsers([])
		 }
 
		setIsAllSelected(!isAllSelected)
	 }
 
	 const openVerificationPopUp =(idProof)=>{
		 setSelectedPhotoProof(idProof)
		 setIscardVisible(true)
	 }
 
	 const closeVerificationPopUp =()=>{
	 setIscardVisible(false)
	 }

	 const handleMenuVisibility=(type)=>{
		setIsMenuVisible(!isMenuVisible)
		if(type=='main' || type== undefined){
			return
		}
		let date = new Date();
		let firstDay = ''
		let lastDay = ''
		if(type=="today"){
		   firstDay = date;
		   lastDay = firstDay;
			setFromInputDate(firstDay.toISOString().substring(0, 10));
			setToInputDate(lastDay.toISOString().substring(0, 10));

		}else if(type=="month"){
			firstDay = new Date(date.getFullYear(), date.getMonth(), 2);
			lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 1);
			setFromInputDate(firstDay.toISOString().substring(0, 10));
			setToInputDate(lastDay.toISOString().substring(0, 10));
		}
		else if(type=="week"){
			var curr = new Date; // get current date
			var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
			var last = first + 6; // last day is the first day + 6

			firstDay = new Date(curr.setDate(first));
			lastDay = new Date(curr.setDate(last));
			setFromInputDate(firstDay.toISOString().substring(0, 10));
			setToInputDate(lastDay.toISOString().substring(0, 10));

		}
		else if(type=="year"){
			firstDay = new Date(date.getFullYear(), 0, 2);
			lastDay = new Date(date.getFullYear(), 12, 1);
			setFromInputDate(firstDay.toISOString().substring(0, 10));
			setToInputDate(lastDay.toISOString().substring(0, 10));
		}
		else if(type=="all"){
		   setFromInputDate('');
		   setToInputDate('');
		} 
	}
	
	return (
		<>
			<PhotoProofView
			  popUpImg = {selectedPhotoProof.passportPhoto}
				popUpName = {selectedPhotoProof.fullName}
				popUpContact = {selectedPhotoProof.countryCode +" "+selectedPhotoProof.contactNumber}
				popUpWhatsapp = {selectedPhotoProof.whatsAppCountryCode +" "+ selectedPhotoProof.whatsAppNumber}
				popUpEmail = {selectedPhotoProof.email}
				popUpQualification={selectedPhotoProof.qualification}
				popUpCourses = {selectedPhotoProof.courses}
				handlePopUpVerifyButton = {()=>{verifySinglePhotoProof(selectedPhotoProof._id,'passportPhotoStatus', 'Verified')}}
				handlePopUpRejectButton = {()=>{verifySinglePhotoProof(selectedPhotoProof._id,'passportPhotoStatus', 'Rejected')}}
				handlePopUpPopUpCheckBox = {()=>{updateSelectedUsers(selectedPhotoProof._id)}}
				handlePopUpBackButton = {()=>closeVerificationPopUp()}
				isPopUpVisible = {iscardVisible}
				isPopUpChecked = { selectedUsers.includes(selectedPhotoProof._id) }
  
				cardGridData = {props.pendingPhotoProof}
				selectedCards= {selectedUsers}
				handleGridCardClick = {(data)=>{openVerificationPopUp(data)}}
				handleGridCheckBox = {(id)=>updateSelectedUsers(id)}
  
				isFilterChecked = {isAllSelected}
				handleFilterCheckbox = {()=>{selectAllDocuments()}} 
				handleVerifyAllButton = {()=>{verifyMultiplePhotoProof(selectedUsers, 'passportPhotoStatus', 'Verified')}}
				handleRejectAllButton = {()=>{verifyMultiplePhotoProof(selectedUsers, 'passportPhotoStatus', 'Rejected')}}
				
				isFilterMenuVisible = {isMenuVisible}
				handleFilterMenuVisibility = {(option)=>{handleMenuVisibility(option)}}
				toggleFullscreen={props.toggleFullscreen}

			  />
		</>
	);
}



const mapStateToProps = (state) => {
	return {
		pendingPhotoProof: state.verification.pendingPhotoProof
	};
};

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			updatePendingPhotoProof: VerificationActions.updatePendingPhotoProof
		},
		dispatch
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(PhotoProof);

