import React, { forwardRef } from "react";
import "./style.css";

const SeriesStatusPopUpView = forwardRef((props, ref) => {
  const {
    newSeriesDuration,
    disableSeriesDurationSaveBtn,
    allSeries,
    selectedSeries,
  } = props;
  const { handleCancelBtn, handleSaveBtn } = props;
  const { handleSeriesDurationRadioBtns, handleSelectedSeries } = props;

  return (
    <div className="seriesStatusPopUp">
      <span>Change Series Status to</span>
      {props.seriesIds === undefined || props.seriesIds?.length === 0 ? (
        <select
          className="seriesSelect"
          onChange={(event) => {
            handleSelectedSeries(event);
          }}
          value={selectedSeries}
        >
          <option value={""}>Select series</option>
          {allSeries.map((series, index) => {
            return (
              <option key={index} value={series._id}>
                {" "}
                {series.title}
              </option>
            );
          })}
        </select>
      ) : null}

      {props.error !== "" && (
        <>
          <div className="myAlert">{props.error}</div>
        </>
      )}
      <hr />

      <span>Set Series Status to</span>
      <div className="form-check ">
        <input
          onChange={(event) => {
            handleSeriesDurationRadioBtns(event, "In Progress");
          }}
          checked={newSeriesDuration === "In Progress" ? true : false}
          className="form-check-input"
          type="radio"
          name="courseDurationRadioBtn"
          id="onGoingRadioBtn"
        />

        <div style={{ marginRight: "9px" }} className="d-inline">
          <input
            style={{ width: "70px" }}
            // value={props.seriesDurationInDays}
            type="number"
            onChange={(event) => {
              props.handleSeriesDurationInDays(event);
            }}
            className="inputPercentage"
          />
        </div>

        <label className="form-check-label" htmlFor="onGoingRadioBtn">
          days
        </label>
      </div>

      <div className="form-check">
        <input
          onChange={(event) => {
            handleSeriesDurationRadioBtns(event, "lifetime");
          }}
          checked={props.newSeriesDuration === "lifetime" ? true : false}
          className="form-check-input"
          type="radio"
          name="courseDurationRadioBtn"
          id="lifetimeRadioBtn"
        />
        <label className="form-check-label" htmlFor="lifetimeRadioBtn">
          Lifetime
        </label>
      </div>

      <div className="form-check">
        <input
          onChange={(event) => {
            handleSeriesDurationRadioBtns(event, "disabled");
          }}
          checked={props.newSeriesDuration === "disabled" ? true : false}
          className="form-check-input"
          type="radio"
          name="courseDurationRadioBtn"
          id="disabledRadioBtn"
        />
        <label className="form-check-label" htmlFor="disabledRadioBtn">
          Disabled
        </label>
      </div>

      <div style={{ float: "right", marginTop: "10px", marginLeft: "auto" }}>
        <button
          onClick={(event) => {
            handleCancelBtn(event);
          }}
          className="cancleBtn"
        >
          Cancle
        </button>

        <button
          disabled={disableSeriesDurationSaveBtn}
          style={{
            cursor: props.disableSeriesDurationSaveBtn
              ? "not-allowed"
              : "pointer",
          }}
          id="courseDurationSaveBtn"
          onClick={handleSaveBtn}
          className="saveBtn"
        >
          Save
        </button>
      </div>
    </div>
  );
});

export default SeriesStatusPopUpView;
