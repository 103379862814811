import React from "react";

import "./style.css";
import VideoPlayHolder from "@assets/common/videoPlayHolder.png";
import BlackCrossIcon from "@assets/common/blackCrossIcon.png";

import PopUp from "@components/PopUp";

import SaveBtn from "@components/SaveBtn";

function AddCoursePopUpView(props) {
  var paymentOptions = [
    { name: "EazyPay", type: "eazyPay" },
    { name: "RazorPay", type: "razorPay" },
    { name: "Stripe", type: "stripe" },
    { name: "CCAvenue", type: "ccavenue" },
    { name: "External Payments", type: "external" },
    { name: "Free", type: "free" },
  ];
  paymentOptions =
    props.userIds.length > 1 ? paymentOptions.slice(-2) : paymentOptions;

  return (
    <div className="addCourseMain">
      <span>Add Course</span>
      <div className="d-flex gap-1 courseSelector">
        <select
          onChange={(event) => {
            props.handleCategoryChange(event);
          }}
          className="form-select greyBg"
        >
          <option value="">Category</option>
          {props.listOfCategory &&
            props.listOfCategory.map((category, index) => (
              <>
                <option data-categoryid={category._id} value={category._id}>
                  {category.title}
                </option>
              </>
            ))}
        </select>
        <select
          onChange={(event) => {
            props.handleCourseChange(event);
          }}
          value={props.course}
          style={{ marginLeft: "15px" }}
          className="form-select greyBg"
        >
          <option value="">Course</option>
          {props.listOfCourses &&
            props.listOfCourses.map((course) => {
              const matchingCategories = props.listOfCategory.filter(
                (category) =>
                  category._id === props.category &&
                  category.courseIds &&
                  category.courseIds.includes(course._id)
              );

              if (matchingCategories.length > 0) {
                return (
                  <option
                    key={course._id}
                    data-courseid={course._id}
                    value={course._id}
                  >
                    {course.title}
                  </option>
                );
              }

              return null;
            })}
        </select>
        <button
          className="roundBtn blackBtn"
          style={{ marginLeft: "auto" }}
          onClick={(event) => {
            props.addNewCourse(event);
          }}
        >
          +
        </button>
      </div>

      {props.error && props.error !== "" && (
        <div className="alert alert-danger w-50">{props.error}</div>
      )}

      <div className="hr" />

      <div className="paymentInfo g-2 gap-2">
        <input
          onChange={(event) => {
            props.handleCdate(event);
          }}
          value={props.cDate}
          placeholder="Date Purchased"
          type="date"
          className="form-control"
        />
        <input
          onClick={(event) => {
            props.handlePaymentClick(event);
          }}
          value={props.paymentMode}
          className="form-select"
          type="text"
          placeholder="Payment Option"
        />
        {["eazyPay", "razorPay", "stripe", "ccavenue"].includes(
          props.paymentMode
        ) && (
          <>
            <input
              onChange={(event) => {
                props.handleTransactionId(event);
              }}
              value={props.transactionId}
              type="text"
              className="form-control greyFont"
              placeholder="Transaction ID"
            />
            <input
              onChange={(event) => {
                props.handleOrderId(event);
              }}
              value={props.orderId}
              type="text"
              className="form-control greyFont"
              placeholder="Order ID"
            />

            <input
              onChange={(event) => {
                props.handlePaidAmount(event);
              }}
              value={props.paidAmount}
              type="number"
              className="form-control greyFont"
              placeholder="Paid amount"
              style={{ flex: "1" }}
            />
          </>
        )}

        {["external"].includes(props.paymentMode) && (
          <input
            onChange={(event) => {
              props.setSpreadSheetLink(event.target.value);
            }}
            value={props.spreadSheetLink}
            type="text"
            className="form-control greyFont"
            placeholder="Add spreadsheet link"
            style={{ flex: "1" }}
          />
        )}
      </div>

      {props.newCourseList &&
        props.newCourseList.length > 0 &&
        props.newCourseList.map((course, index) => (
          <>
            <div className="newCourseContainer g-2" key={index}>
              <img
                className="courseThumbnail"
                src={
                  course.rectangleThumbnailUrl
                    ? course.rectangleThumbnailUrl
                    : VideoPlayHolder
                }
                alt="course"
              />
              <span className="courseName">{course.title}</span>
              <img
                onClick={(event) => {
                  props.handleDeleteCourse(event, course._id);
                }}
                className="blackCrossIcon"
                src={BlackCrossIcon}
                alt="BlackCrossIcon"
              />
            </div>
          </>
        ))}

      <div className="border-top pt-2">
        <div className="d-flex gap-2 align-items-center">
          <input
            type="checkbox"
            checked={props.isAddCourseOnChecked}
            onChange={(e) => {
              props.setIsAddCourseOnChecked(!props.isAddCourseOnChecked);
            }}
          />
          Add course on
        </div>
        <div className="d-flex gap-2 paymentInfo">
          <input
            onChange={(event) => {
              props.setCourseAddOnDate(event.target.value);
            }}
            value={props.courseAddOnDate}
            placeholder="Date Purchased"
            type="date"
            className="form-control"
          />
          <input
            onChange={(event) => {
              props.setCourseAddOnTime(event.target.value);
            }}
            value={props.courseAddOnTime}
            placeholder="Date Purchased"
            type="time"
            className="form-control"
          />
        </div>
      </div>
      <div className="d-flex justify-content-end gap-1">
        <button onClick={props.addNewCouseClosePupupBtn} className="closeBtn">
          Close
        </button>
        {/* <button onClick={props.saveNewCouse} className="saveBtn">
          Save
        </button> */}
        <SaveBtn
            onClick={props.saveNewCouse}
            text={"Save"}
            altText={"Saving..."}
            disabled={props.isFormSaving}
            isPopUpBtn={true}
          />
      </div>
      <PopUp
        visible={props.isPopUpVisible}
        top={"140px"}
        left={"280px"}
        handleOutSideClick={props.handlePopUpClose}
      >
        <div className="p-2">
          <div className="d-flex flex-column gap-1">
            {paymentOptions.map((option, index) => (
              <div key={index} className="d-flex gap-2">
                <input
                  type="radio"
                  name="paymentMethod"
                  value={option.type}
                  onChange={props.handlePaymentMode}
                />
                {option.name}
              </div>
            ))}
          </div>
          <div className="popUpFooter">
            <input
              className="cancelBtn"
              type="button"
              value={"Cancel"}
              onClick={(event) => props.handlePopUpClose(event)}
            />
            <input
              className="saveBtn"
              type="button"
              value={"Save"}
              onClick={(event) => props.handlePopUpSave(event)}
            />
          </div>
        </div>
      </PopUp>
    </div>
  );
}

export default AddCoursePopUpView;
