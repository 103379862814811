import Search from "@components/Search";
import DynamicTabs from "@components/DynamicTabs";
import ButtonBars from "@components/ButtonBars";
import PopUp from "@components/PopUp";
import BasicTableGrid from "@components/BasicTableGrid";
import PhoneNumber from "@components/PhoneNumber";
import DateAndUser from "@components/DateAndUser";
import TextAndLinks from "@components/TextAndLinks";

import ChangeTicketStatus from "@components/CustomerCarePopups/ChangeTicketStatus";

import "./style.css";
import BlueDropDownIcon from "@assets/common/blueDropDown.png";
import ExpandIcon from "@assets/userDashboard/expand.png";
import SettingIcon from "@assets/userDashboard/setting.png";
import ColumnsIcon from "@assets/userDashboard/columns.png";
import FolderIcon from "@assets/userDashboard/folder.png";
import EditPencilIcon from "@assets/common/editPencilIcon.png";
import Tags from "@components/Tags";
import EditNote from "@components/EditNote";

function CustomerCareView(props) {
  return (
    <>
      <div
        className="contentPageMainContainer customerCareView bg-white"
        ref={props.fullscreenElementRef}
      >
        <Search
          value={props.searchText}
          placeholder={"Search Customer Care"}
          onChange={(event) => {
            props.handleSearchInput(event);
          }}
          onKeyDown={(event) => {
            props.handleSearch(event);
          }}
          onSearchClick={(event) => {
            event.key = "Enter";
            props.handleSearch(event);
          }}
          isDefaultChild={true}
        ></Search>

        <h4 className="mb-3 topHeading">Customer Care</h4>

        <DynamicTabs
          allTabs={props.folders}
          activeTab={props.activeTab}
          onTabClick={props.handleTabClick}
        />

        <div className="d-flex align-items-center w-100 justify-content-between">
          <ButtonBars
            buttons={[
              {
                type: "round",
                text: "+",
                onClick: (event) => {
                  props.handlePopUpOpen(event, "createNew");
                },
              },
              {
                text: "Change Status",
                iconEnd: BlueDropDownIcon,
                disabled: props.disableBulkActionBtn,
                onClick: (event) => {
                  props.handlePopUpOpen(event, "changeTicketStatus");
                },
              },
            ]}
          />

          <div className="d-flex gap-2 controlsRight me-3">
            <button onClick={props.toggleFullscreen}>
              <img src={ExpandIcon}></img>
              <br></br>
              Expand
            </button>
            {/* <button
              onClick={(event) => props.handlePopUpOpen(event, "setting")}
            >
              <img src={SettingIcon}></img>
              <br></br>
              Setting
            </button> */}
            <button onClick={props.moveToArchive}>
              <img src={FolderIcon}></img>
              <br></br>
              Archive
            </button>
            <button onClick={props.handleColumnBtnClick}>
              <img src={ColumnsIcon}></img>
              <br></br>
              Columns
            </button>
          </div>
        </div>

        <PopUp
          visible={props.isPopUpVisible && props.popUp === "changeTicketStatus"}
          top={props.popUpPosition.top}
          left={props.popUpPosition.left}
          handleOutSideClick={props.handlePopUpClose}
        >
          <ChangeTicketStatus
            handleCancel={props.handlePopUpClose}
            handleSave={props.handlePopUpSave}
          />
        </PopUp>

        <PopUp
          visible={props.isPopUpVisible && props.popUp === "setting"}
          top={props.popUpPosition.top}
          left={props.popUpPosition.left}
          handleOutSideClick={props.handlePopUpClose}
        >
          setting content
        </PopUp>

        <BasicTableGrid
          gridId="customerCareMain"
          data={props.deferredData} // Data to be displayed
          fixedCols={2} // How many columns are fixed and not scrolling
          initialSize={[35, 187, 150, 150, 150, 150, 150, 300, 150]}
          initialSorting={[{ id: "Date/Time", desc: false }]}
          handleSelect={(selectedRows) => {
            props.handleCheckBoxes(selectedRows);
          }}
          length={20}
          onSortChange={(data) => props.handleSort(data)}
          columnsOptionPopUpRef={props.columnsOptionPopUpRef}
          columns={[
            {
              header: "Name",
              cell: (data) => {
                const info = data.row.original;
                return (
                  <div className="d-flex gap-2 w-100">
                    <TextAndLinks
                      title={
                        (info.firstName ?? info.firstName) +
                        (info.lastName ?? info.lastName)
                      }
                      subTitle={"view"}
                      images={[EditPencilIcon]}
                      onClick={(event, index) => {
                        props.handleRedirectToHome(
                          event,
                          info._id,
                          "CustomerCareDetails"
                        );
                      }}
                    />
                  </div>
                );
              },
            },
            {
              header: "Phone Number",
              cell: (data) => {
                const info = data.row.original;
                return (
                  <PhoneNumber
                    countryCode={info.countryCode}
                    number={info.contactNumber}
                    type="phone"
                  />
                );
              },
            },
            {
              header: "WhatsApp Number",
              cell: (data) => {
                const info = data.row.original;
                return (
                  <PhoneNumber
                    countryCode={info.countryCode}
                    number={info.whatsAppNumber}
                    type="phone"
                  />
                );
              },
            },
            {
              header: "Date/Time",
              accessorFn: (row) => {
                return row.createdAt;
              },
              cell: (data) => {
                const info = data.row.original;
                return (
                  <DateAndUser
                    date={info.createdAt}
                    image={info.createdUserDetails?.displayPhoto}
                    imageText={info.createdUserDetails?.displayName}
                  />
                );
              },
            },
            {
              header: "Email ID",
              cell: (data) => {
                const info = data.row.original;
                return info.email;
              },
            },
            // {
            //   header: "Activity",
            //   cell: (data) => {
            //     const info = data.row.original;
            //     return (
            //       <div className="unreadUpdatesCustomerCareContainer">
            //         <span>{info.activity}</span>
            //         unread updates
            //       </div>
            //     );
            //   },
            // },
            {
              header: "Note",
              cell: (data) => {
                const info = data.row.original;
                return (
                  <EditNote
                    value={info.notes}
                    onChange={(notes) => {
                      // props.handleChange('notes', notes, data.row.original.userId)
                    }}
                    queryId={info._id}
                  />
                );
              },
            },
            {
              header: "Label",
              cell: (data) => {
                const info = data.row.original;
                return (
                  <Tags
                    queryId={info._id}
                    automaticTags={[]}
                    manualTags={info.customerCareTags}
                    onChange={(newTags) => {
                      // props.handleChange('manualTags', manualTags, info.userId)
                    }}
                  />
                );
              },
            },
          ]}
        />
      </div>
    </>
  );
}

export default CustomerCareView;
