import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import PurchasesView from "./view";
import { useParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import * as UserPagesServices from "@services/UserPages";

import { decryptText } from "@library/enc-dec";
import ErrorView from "@components/ErrorView";
import * as UserActions from "@redux/actions/Users";

import { useNavigate } from "react-router-dom";
import { getEventElementPosition } from "@helpers/common.js";

function Purchases(props) {
  var { id } = useParams();

  const navigate = useNavigate();
  const columnsOptionPopUpRef = useRef(null);

  // START : UseState's to control the functionality of setting exam status
  const [disableExamStatusSaveBtn, setDisableExamStatusSaveBtn] =
    useState(false);
  const [newExamStatus, setNewExamStatus] = useState("");
  const [showResultCheckBox, setShowResultCheckBox] = useState(false);
  const [inputPercentage, setInputPercentage] = useState("");
  const examStatusPopupContainerRef = useRef(null);
  const [
    is_ExamStatusPopupContainerRef_Visible,
    set_Is_ExamStatusPopupContainerRef_Visible,
  ] = useState(false);
  const [
    examStatusPopupContainerRef_Position,
    set_ExamStatusPopupContainerRef_Position,
  ] = useState({ top: 0, left: 0 });
  const [examStatusError, setExamStatusError] = useState("");
  // END : UseState's to control the functionality of setting exam status

  // START : UseState's to control the functionality of setting course duration
  const [disableCourseDurationSaveBtn, setDisableCourseDurationSaveBtn] =
    useState(false);
  const [newCourseDuration, setNewCourseDuration] = useState("");
  const [courseDurationInDays, setCourseDurationInDays] = useState("");
  const courseDurationPopupContainerRef = useRef(null);
  const [
    is_CourseDurationPopupContainerRef_Visible,
    set_Is_CourseDurationPopupContainerRef_Visible,
  ] = useState(false);
  const [
    courseDurationPopupContainerRef_Position,
    set_CourseDurationPopupContainerRef_Position,
  ] = useState({ top: 0, left: 0 });
  const [courseDurationError, setCourseDurationError] = useState("");
  // END : UseState's to control the functionality of setting course duration

  const [userPurchasesData, setUserPurchasesData] = useState({});
  const [selectedPurchasedId, setSelectedPurchasedId] = useState(0);
  const [selectedCourseId, setSelectedCourseId] = useState(0);
  const [popUp, setPopUp] = useState("");
  const [isPopUpVisible, setIsPopUpVisible] = useState(false);
  const resultModalRef = useRef(null);
  const [purchaseId, setPurchaseId] = useState("");
  const [resultId, setResultId] = useState("");
  const [popUpPosition, setPopUpPosition] = useState({});

  useEffect(() => {
    getUserPurchases();
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const getUserPurchases = async () => {
    try {
      // user id
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      const response = await UserPagesServices.getPurchasesByUserId(
        id,
        authToken
      );
      if (response.success) {
        setUserPurchasesData(response);
      }
    } catch (err) {
      console.log("Error:", err);
    }
  };

  // Handle hiding of popup's when clicked outside the respective container
  const handleOutsideClick = (event) => {
    if (
      examStatusPopupContainerRef.current &&
      !examStatusPopupContainerRef.current.contains(event.target)
    ) {
      handleExamStatusPopupCancleBtnClick();
    }

    if (
      courseDurationPopupContainerRef.current &&
      !courseDurationPopupContainerRef.current.contains(event.target)
    ) {
      handleCourseDurationPopupCancleBtnClick();
    }
  };

  //-----------------------------------
  // START : CODE for updating course duration
  //-----------------------------------

  // Set course status display text
  // const [courseStatusDisplayText, setCourseStatusDisplayText] = useState(null)
  const setCourseStatusOnUI = (status, duration) => {
    if (status !== null || status !== "") {
      if (status === "In Progress") {
        let r_text =
          "Will expire on " +
          moment().add(duration, "days").format("Do MMMM, YYYY");
        return r_text;
      } else if (status === "Disabled") {
        let r_text = "Expired on " + moment().format("Do MMMM, YYYY");
        return r_text;
      } else {
        return status;
      }
    } else {
      return "";
    }
  };

  // Handle when course duration btn is click which will update the val of radio btns as per the selected user
  const openCourseDurationStatusPopup = (event, purchaseId, detailedId) => {
    const purchase = userPurchasesData.data.find(
      (purchase) => purchase._id === purchaseId
    );
    if (purchase) {
      const currentCourseData = purchase.courseDetails.find(
        (course) => course._id === detailedId
      );

      if (currentCourseData && currentCourseData.status) {
        setNewCourseDuration(currentCourseData.status);
        if (currentCourseData.status === "In Progress") {
          if (currentCourseData.courseDurationDays !== null) {
            setCourseDurationInDays(currentCourseData.courseDurationDays);
          }
        }
      }
    }

    setSelectedPurchasedId(purchaseId);
    setSelectedCourseId(detailedId);

    set_Is_CourseDurationPopupContainerRef_Visible(true);
    let currentEle = event.target;
    let left = currentEle.getBoundingClientRect().left - 350;
    let top = window.scrollY + currentEle.getBoundingClientRect().bottom;
    set_CourseDurationPopupContainerRef_Position({ left: left, top: top });

    // setDisableCourseDurationSaveBtn(true);
  };

  // To close the exam status popup container
  const handleCourseDurationPopupCancleBtnClick = (event) => {
    set_Is_CourseDurationPopupContainerRef_Visible(false);
    setNewCourseDuration("");
    setCourseDurationError("");
    setCourseDurationInDays("");
    setSelectedPurchasedId(0);
    setSelectedCourseId(0);
  };

  // To handle radio btn of exam status
  const handleCourseDurationRadioBtns = (event, value) => {
    setNewCourseDuration(value);
    setDisableCourseDurationSaveBtn(false);
  };

  const handleCourseDurationInDays = (event) => {
    if (event.target.value !== "") {
      setCourseDurationInDays(Number(event.target.value));
    }
  };

  // Handle exam status save button
  const handlePurchaseStatus = async () => {
    try {
      if (validatePCID()) {
        setExamStatusError("Reselect the exam status");
        return;
      }

      if (newCourseDuration === "") {
        return;
      }

      if (newCourseDuration === "In Progress") {
        if (courseDurationInDays === "") {
          setCourseDurationError(
            "If in days selected then days cant be left empty"
          );
          return;
        }
      }

      let payload = {
        purchaseId: selectedCourseId,
        status: newCourseDuration,
        duration: courseDurationInDays ? courseDurationInDays : "NA",
      };

      let authToken = decryptText(localStorage.getItem("aEmediat"));
      const response = await UserPagesServices.updatePurchasedCourseStatus(
        payload,
        authToken
      );
      if (response.success) {
        getUserPurchases();
      }
      alert(response.message);
    } catch (err) {
      console.log("Error coming while updating purchase status", err);
    }
    // reset values
    handleCourseDurationPopupCancleBtnClick();
  };
  //-----------------------------------
  // END : CODE for updating course duration
  //-----------------------------------

  //-----------------------------------
  // START : CODE for updating exam status
  //-----------------------------------
  // Handle when exam status btn is click which will update the val of radio btns as per the selected user
  const openExamStatusPopup = (event, purchaseId, detailedId) => {
    const purchase = userPurchasesData.data.find(
      (purchase) => purchase._id === purchaseId
    );
    if (purchase) {
      const currentExamStatus = purchase.courseDetails.find(
        (course) => course._id === detailedId
      );
      if (currentExamStatus && currentExamStatus.examStatus) {
        setNewExamStatus(currentExamStatus.examStatus);
        if (currentExamStatus.examStatus === "Passed") {
          if (currentExamStatus.passPercentage !== null) {
            setInputPercentage(currentExamStatus.passPercentage);
          }
          if (currentExamStatus.showResult !== null) {
            setShowResultCheckBox(currentExamStatus.showResult);
          }
        }
      }
    }

    setSelectedPurchasedId(purchaseId);
    setSelectedCourseId(detailedId);

    set_Is_ExamStatusPopupContainerRef_Visible(true);
    let currentEle = event.target;
    let left = currentEle.getBoundingClientRect().left - 350;
    let top = window.scrollY + currentEle.getBoundingClientRect().bottom;
    set_ExamStatusPopupContainerRef_Position({ left: left, top: top });

    // setDisableExamStatusSaveBtn(true);
  };

  // To close the exam status popup container
  const handleExamStatusPopupCancleBtnClick = (event) => {
    set_Is_ExamStatusPopupContainerRef_Visible(false);
    setNewExamStatus("");
    setExamStatusError("");
    setShowResultCheckBox(false);
    setInputPercentage("");
    setSelectedPurchasedId(0);
    setSelectedCourseId(0);
  };

  // To handle radio btn of exam status
  const handleExamStatusRadioBtns = (event, value) => {
    setNewExamStatus(value);
    setDisableExamStatusSaveBtn(false);
  };

  //To handle checkbox of show result
  const handleShowResultCheckBox = (event) => {
    setShowResultCheckBox(event.target.checked);
  };

  const handleInputPercentage = (event) => {
    setInputPercentage(event.target.value);
  };

  // Handle exam status save button
  const handleExamStatus = async () => {
    try {
      if (validatePCID()) {
        setExamStatusError("Reselect the exam status");
        return;
      }
      if (newExamStatus === "Passed") {
        if (inputPercentage === "" || typeof showResultCheckBox !== "boolean") {
          setExamStatusError(
            "Input percentage or show results cant be left empty"
          );
          return;
        }
      }

      let payload = {
        purchaseId: selectedCourseId,
        status: newExamStatus,
        percentage: inputPercentage,
        showResult: showResultCheckBox,
      };

      let authToken = decryptText(localStorage.getItem("aEmediat"));
      const response = await UserPagesServices.updateExamStatus(
        payload,
        authToken
      );
      if (response.success) {
        getUserPurchases();
      }
      alert(response.message);
    } catch (err) {
      console.log("Error coming while handleExamStatus()", err);
    }
    //reset values
    handleExamStatusPopupCancleBtnClick();
  };
  //-----------------------------------
  // END : CODE for updating exam status
  //-----------------------------------

  // Fn to validate purchase and couse id
  const validatePCID = () => {
    if (
      selectedPurchasedId === parseInt(0) ||
      selectedCourseId === parseInt(0)
    ) {
      return true;
    } else {
      return false;
    }
  };

  //-----------------------------------
  // START : CODE add course
  //-----------------------------------
  const openAddCoursePopup = () => {
    if (document.getElementById("addNewCousePopupBtn")) {
      let addNewCousePopup = document.getElementById("addNewCousePopupBtn");
      addNewCousePopup.click();
    }
  };
  //-----------------------------------
  // END : CODE add course
  //-----------------------------------

  const handleDownloadInvoice = async (data) => {
    let authToken = decryptText(localStorage.getItem("aEmediat"));
    const details = await UserPagesServices.getOrderById(
      data.orderId,
      id,
      authToken
    );

    if (details.success) {
      navigate("/invoice", { state: { invoice: details } });
    } else {
      alert(details.message);
    }
  };

  const handleSave = async (data) => {
    var orderData = {};
    if (data.paymentMode === "free") {
      let randomString = `${id}_${data.newCourseList[0]._id.toString()}`;
      orderData = {
        userId: id,
        orderId: `order_free_${randomString}`,
        paymentId: `pay_free_${randomString}`,
        paymentMethod: "free",
        courses: data.newCourseList,
        orderAmount: "₹ 0/-",
        orderAmountInNumbers: 0,
        continent: "DEVELOPING_ASIA",
        countryName: "India",
        currencySymbol: "₹",
        GST: 0,
        grandTotal: 0,
        netTotal: 0,
        subTotal: 0,
        createdAt: data.cDate,
        coursesMenmonic: data.newCourseList
          .map((course) => course.menmonic)
          .join("+"),
        discount: 0,
        discountInPercentage: 0,
        couponDetails: null,
        currencyCode: "INR",
      };
    } else {
      orderData = {
        userId: id,
        orderId: data.orderId,
        paymentId: data.transactionId,
        paymentMethod: data.paymentMode,
        courses: data.newCourseList,
        orderAmount: "₹" + data.paidAmount + "/-",
        orderAmountInNumbers: data.paidAmount,
        continent: "DEVELOPING_ASIA",
        countryName: "India",
        currencySymbol: "₹",
        GST: parseInt(data.paidAmount * (18 / 100)),
        grandTotal: data.paidAmount,
        netTotal: data.paidAmount,
        subTotal: parseInt(
          data.paidAmount - parseInt(data.paidAmount * (18 / 100))
        ),
        createdAt: data.cDate,
        coursesMenmonic: data.newCourseList
          .map((course) => course.menmonic)
          .join("+"),
        discount: 0,
        discountInPercentage: 0,
        couponDetails: null,
        currencyCode: "INR",
      };
    }

    let purchaseData = [];
    purchaseData.courseIds = data.newCourseList.map(
      (course) => course.courseId
    );
    purchaseData.orderId = orderData.orderId;

    try {
      const orderResponse = await UserPagesServices.addOrder(orderData);

      if (orderResponse.success) {
        let payload = {
          courseIds: purchaseData.courseIds,
          orderId: purchaseData.orderId,
          userId: id,
        };
        const purchaseResponse = await UserPagesServices.addToPurchases(
          payload
        );
        alert(purchaseResponse.message);
      } else {
        alert(orderResponse.message);
        return false;
      }
      // Update the purchase data on the page
      getUserPurchases();
      // Closing the Modal
      if (document.getElementById("addNewCouseClosePupupBtn")) {
        document.getElementById("addNewCouseClosePupupBtn").click();
      }
      return true;
    } catch (err) {
      console.log("Error:", err);
    }
  };

  const handlePopUpOpen = (event, popUpName, data) => {
    handlePopUpClose();
    setPopUp(popUpName);
    setIsPopUpVisible(true);

    if (popUpName === "examResult") {
      setPurchaseId(data._id);
      setResultId(data.resultId);
    }

    let position = getEventElementPosition(event);
    setPopUpPosition(position);
    event.stopPropagation();
  };

  const handlePopUpClose = () => {
    setIsPopUpVisible(false);
    if (columnsOptionPopUpRef.current) {
      columnsOptionPopUpRef.current.style.display = "none";
    }
  };

  const handleOrderClick = async (data, index) => {
    try {
      const { courseDetails } = data;
      const purchaseIds = courseDetails.map((course) => course._id);

      const isChecked = data.courseDetails.every((course) =>
        props.selectedPurchaseIds.includes(course._id)
      );

      if (isChecked) {
        props.updateSelectedPurchaseIds([]);
      } else {
        props.updateSelectedPurchaseIds(purchaseIds);
      }
    } catch (err) {
      console.log("Error coming from handleOrderClick()", err);
    }
  };

  const handleCourseClick = async (data, index) => {
    try {
      const purchaseId = data._id;

      const isChecked = props.selectedPurchaseIds.includes(purchaseId);
      if (isChecked) {
        props.updateSelectedPurchaseIds(
          props.selectedPurchaseIds.filter((id) => id !== purchaseId)
        );
      } else {
        props.updateSelectedPurchaseIds([
          ...props.selectedPurchaseIds,
          purchaseId,
        ]);
      }
    } catch (err) {
      console.log("Error coming from handleCourseClick()", err);
    }
  };
  return (
    <>
      {id ? (
        <PurchasesView
          openExamStatusPopup={openExamStatusPopup}
          openCourseDurationStatusPopup={openCourseDurationStatusPopup}
          userPurchasesData={userPurchasesData}
          //START : UseStates & fn to control the functionality of upating status
          examStatusPopupContainerRef={examStatusPopupContainerRef}
          is_ExamStatusPopupContainerRef_Visible={
            is_ExamStatusPopupContainerRef_Visible
          }
          examStatusPopupContainerRef_Position={
            examStatusPopupContainerRef_Position
          }
          handleExamStatus={handleExamStatus}
          handleExamStatusPopupCancleBtnClick={
            handleExamStatusPopupCancleBtnClick
          }
          handleExamStatusRadioBtns={handleExamStatusRadioBtns}
          handleShowResultCheckBox={handleShowResultCheckBox}
          showResultCheckBox={showResultCheckBox}
          handleInputPercentage={handleInputPercentage}
          inputPercentage={inputPercentage}
          newExamStatus={newExamStatus}
          disableExamStatusSaveBtn={disableExamStatusSaveBtn}
          examStatusError={examStatusError}
          //END : UseStates & fn to control the functionality of upating status

          // START : UseState's to control the functionality of setting course duration
          is_CourseDurationPopupContainerRef_Visible={
            is_CourseDurationPopupContainerRef_Visible
          }
          newCourseDuration={newCourseDuration}
          courseDurationPopupContainerRef={courseDurationPopupContainerRef}
          courseDurationPopupContainerRef_Position={
            courseDurationPopupContainerRef_Position
          }
          courseDurationError={courseDurationError}
          handleCourseDurationRadioBtns={handleCourseDurationRadioBtns}
          courseDurationInDays={courseDurationInDays}
          handleCourseDurationInDays={handleCourseDurationInDays}
          handleCourseDurationPopupCancleBtnClick={
            handleCourseDurationPopupCancleBtnClick
          }
          handlePurchaseStatus={handlePurchaseStatus}
          disableCourseDurationSaveBtn={disableCourseDurationSaveBtn}
          setCourseStatusOnUI={setCourseStatusOnUI}
          // END : UseState's to control the functionality of setting course duration

          // START : UseState's for add new course
          openAddCoursePopup={openAddCoursePopup}
          // END : UseState's for add new course

          handleSave={handleSave}
          handleDownloadInvoice={handleDownloadInvoice}
          handlePopUpOpen={handlePopUpOpen}
          handlePopUpClose={handlePopUpClose}
          popUp={popUp}
          isPopUpVisible={isPopUpVisible}
          resultModalRef={resultModalRef}
          purchaseId={purchaseId}
          resultId={resultId}
          popUpPosition={popUpPosition}
          handleOrderClick={handleOrderClick}
          handleCourseClick={handleCourseClick}
          selectedPurchaseIds={props.selectedPurchaseIds}
        />
      ) : (
        <ErrorView
          text={"Please Select/Create user before adding new purchase."}
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    tab: state.tabs.UserTab,
    userDetails: state.user.userDetails,
    access: state.login.access,
    selectedPurchaseIds: state.user.selectedPurchaseIds,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateSelectedPurchaseIds: UserActions.updateSelectedPurchaseIds,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Purchases);
