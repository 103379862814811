import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, defer, useLocation} from 'react-router-dom';

import moment from 'moment';

import * as VideosServices from '@services/Videos';
import * as VideosActions from '@redux/actions/Videos';

import { decryptText } from '@library/enc-dec';
import { produce } from 'immer';
import Swal from 'sweetalert2';
import {getEventElementPosition, toggleFullscreen} from '@helpers/common.js';
import LeadsView from './view';

const mainDateOption=['Today','Yesterday','This Month','This Week', 'This Year', 'Last 7 days','Last 30 days','Last 180 days','Last 360 days', 'This financial year','All time','Select']
const years = ['2022','2023','2024']
const months= ['January','February','March','April','May','June','July','August','September','October','November','December']


var listOfCheckedRows = [];
const currentDate = new Date();

const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

const startDateStr = startDate.toISOString().split('T')[0];
const endDateStr = endDate.toISOString().split('T')[0];


function Leads(props){   
	const fullscreenElementRef = useRef(null);
	const columnsOptionPopUpRef = useRef(null);
	const [selectedYear, setSelectedYear] = useState(null);
	
    const [startDate, setStartDate] = useState(startDateStr)
    const [endDate, setEndDate] = useState(endDateStr)

    useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				columnsOptionPopUpRef.current &&
				!columnsOptionPopUpRef.current.contains(event.target)
			) {
				columnsOptionPopUpRef.current.style.display = 'none';
			}
		};

		document.addEventListener('click', handleClickOutside);

		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, []);
	const [deferredData, setDeferredData] = useState({});
	const [searchText, setSearchText] = useState('');
	const [isSorted, setIsSorted] = useState(false);
	const [sortBy, setSortBy] = useState('updatedAt');
    const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const limit = Number(queryParams.get('limit'));
	const page = Number(queryParams.get('page'));
	const [popUp, setPopUp] =useState('');
	const [isPopUpVisible, setIsPopUpVisible] = useState(false);
	const [popUpPosition, setPopUpPosition] = useState({});


       //Fn to check and uncheck all checkboxes
	const handleCheckBoxes = (selectedRows) => {
		listOfCheckedRows = selectedRows;
		// setDisableBulkActionBtn( selectedRows.length>0 ? false : true)
	};
	// To sort 
	const handleSort = (data) => {
		const mapping = {'Date Added': 'createdAt', 'Date Modified': 'updatedAt'}
		var id = data[0]?.id ? mapping[data[0].id] : ''
		setSortBy(id)
		setIsSorted(data[0]?.desc ? data[0].desc : false)
	};
    const handleColumnBtnClick = (event) => {
		handlePopUpClose();
		columnsOptionPopUpRef.current.style.display = 'block';
		var position = getEventElementPosition(event);
		columnsOptionPopUpRef.current.style.top = position.top + 'px';
		columnsOptionPopUpRef.current.style.left = position.left - 160 + 'px';
		event.stopPropagation();
	};
    	// To close the popup container
	const handlePopUpOpen = (event, popUpName, data) => {
		if(popUpName==='dateMonthPopUp')
			{
				setSelectedYear(data)
			}
		handlePopUpClose()
		setPopUp(popUpName)
		setIsPopUpVisible(true)
		let position = getEventElementPosition(event);
		setPopUpPosition(position);
		event.stopPropagation();
	};
	const handlePopUpClose = () => {
		setIsPopUpVisible(false)
		columnsOptionPopUpRef.current.style.display = 'none';
	};
	// To handleSave buttons on the popup
	const handlePopUpSave = async (e, data) => {

		if(popUp === "dateMonthPopUp"){
			let selectedMonth = data
			const startDate = moment([selectedYear, selectedMonth]); // Month is zero-indexed
			const endDate = startDate.clone().endOf('month');
			const formattedStartDate = startDate.format('YYYY-MM-DD');
			const formattedEndDate = endDate.format('YYYY-MM-DD');
			setStartDate(formattedStartDate)
			setEndDate(formattedEndDate)
		}    else if(mainDateOption.includes(data)) {
        const {formattedStartDate, formattedEndDate} = calculateDates(data)
        setStartDate(formattedStartDate)
         setEndDate(formattedEndDate)
    }
		handlePopUpClose();
		// getAllBssForms();
		// setDisableBulkActionBtn(true);
	};
	
    const handleChange = (field, value) => {
		console.log(field, value)
		if(field ==='startDate'){
			setStartDate(value)
		}else if(field==='endDate'){
			setEndDate(value)
		}
	};
	function calculateDates(selectedOption) {
		const today = moment().startOf('day');
		let startDate, endDate;
  
		switch (selectedOption) {
			case 'Today':
				startDate = today.clone().startOf('day');
				endDate = today.clone().endOf('day');
				break;
			case 'Yesterday':
				startDate = today.clone().subtract(1, 'day').startOf('day');
				endDate = today.clone().subtract(1, 'day').endOf('day');
				break;
			case 'This Month':
				startDate = today.clone().startOf('month');
				endDate = today.clone().endOf('month');
				break;
			case 'This Week':
				startDate = today.clone().startOf('isoWeek');
				endDate = today.clone().endOf('isoWeek');
				break;
			case 'This Year':
				startDate = today.clone().startOf('year');
				endDate = today.clone().endOf('year');
				break;
			case 'Last 7 days':
				startDate = today.clone().subtract(6, 'days').startOf('day');
				endDate = today.clone().endOf('day');
				break;
			case 'Last 30 days':
				startDate = today.clone().subtract(29, 'days').startOf('day');
				endDate = today.clone().endOf('day');
				break;
			case 'Last 180 days':
				startDate = today.clone().subtract(179, 'days').startOf('day');
				endDate = today.clone().endOf('day');
				break;
			case 'Last 360 days':
				startDate = today.clone().subtract(359, 'days').startOf('day');
				endDate = today.clone().endOf('day');
				break;
			case 'This financial year':
				let date = getCurrentFinancialYear();
				startDate = date.startDate;
				endDate= date.endDate;
				break;
			case 'All time':
				startDate = moment(0); // Unix epoch
				endDate = today.clone().endOf('day');
				break;
			default:
				// Handle 'Select' or unknown option
				startDate = null;
				endDate = null;
				break;
		}
		const formattedStartDate = startDate.format('YYYY-MM-DD');
		const formattedEndDate = endDate.format('YYYY-MM-DD');
		return { formattedStartDate, formattedEndDate };
	}

	function getCurrentFinancialYear() {
		const today = moment();
		const currentMonth = today.month();
		const currentYear = today.year();
  
		let startYear, endYear;
  
		// If current month is April or later, financial year starts in the current year
		if (currentMonth >= 3) { // April is the fourth month (zero-based index)
			startYear = currentYear;
			endYear = currentYear + 1;
		} else { // Otherwise, financial year started in the previous year
			startYear = currentYear - 1;
			endYear = currentYear;
		}
  
		const startDate = moment(`${startYear}-04-01`).startOf('day');
		const endDate = moment(`${endYear}-03-31`).endOf('day');
  
		return { startDate, endDate };
	}

    return <LeadsView 
                deferredData={deferredData}
                handleCheckBoxes={handleCheckBoxes}
                handleSort={handleSort}
                
				mainDateOption={mainDateOption}
				years={years}
				months={months}
				startDate={startDate}
				endDate={endDate}
                setStartDate={setStartDate}
				setEndDate={setEndDate}
				

				// Start: Control Pop Up 
				popUp={popUp}
				popUpPosition = {popUpPosition}
				isPopUpVisible = {isPopUpVisible}
				handlePopUpClose = {handlePopUpClose} 
				handlePopUpOpen = {handlePopUpOpen} 
				handlePopUpSave= {handlePopUpSave}

				// Fullscreen
				fullscreenElementRef={fullscreenElementRef}
				toggleFullscreen={()=>toggleFullscreen(fullscreenElementRef)}
				//ColumnButton and popup
				columnsOptionPopUpRef={columnsOptionPopUpRef}
				handleColumnBtnClick={handleColumnBtnClick}

				handleChange={handleChange}
    
    />
}


export default Leads