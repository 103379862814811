import React from 'react';

import './style.css';
import BasicTableGrid from '@components/BasicTableGrid';
import ButtonBars from '@components/ButtonBars';
import PopUp from '@components/PopUp';
import Search from '@components/Search';

import NewEligibilityPopUp from '@components/SuperAdmin/Eligibility/NewEligibilityPopUp';
import SelectionPopUp from '@components/SelectionPopUp';

import SettingIcon from '@assets/userDashboard/setting.png';
import ExpandIcon from '@assets/userDashboard/expand.png';
import ColumnsIcon from '@assets/userDashboard/columns.png';

function EligibilityView(props){
    return(
        <div className='AdminPageMainContainer bg-white' ref={props.fullscreenElementRef}>
            <Search
				value={props.searchText}
				placeholder = {"Search Eligibility"}
				onSearch={(event)=>{props.handleSearch(event)}}
				/>
            <div className='d-flex align-items-center w-100 justify-content-between'>
					<ButtonBars
						buttons={[{type: 'round',
                                    text: '+',
                                    onClick: (event) => {
                                        props.handlePopUpOpen(event, "createNew", null);
                                    },
                                },
						]}
					/>
					<div className='d-flex gap-2 controlsRight me-3'>
						<button onClick={props.toggleFullscreen}>
							<img src={ExpandIcon}></img>
							<br></br>
							Expand
						</button>
						{/* <button onClick={(event)=>props.handlePopUpOpen(event, "setting", null)}>
							<img src={SettingIcon}></img>
							<br></br>
							Settings
						</button> */}
						<button onClick={props.handleColumnBtnClick}>
							<img src={ColumnsIcon}></img>
							<br></br>
							Columns
						</button>
					</div>
            </div>

            <BasicTableGrid 
				gridId='Eligibility'
				data={props.deferredData} // Data to be displayed
				fixedCols={2} // How many columns are fixed and not scrolling
				initialSize={[35,228,202,936]}
				initialSorting={[{"id": "Date/Time","desc": true}]}
				handleSelect={(selectedRows) => {props.handleCheckBoxes(selectedRows);}}
				length={20}
				onSortChange={(data) => {}
                    // props.handleSort(data)
				}
				columnsOptionPopUpRef={props.columnsOptionPopUpRef}
				columns={[
					{header: 'Profession', 
						cell : (data)=>{
							return (<div style={{cursor:'pointer'}}
										onDoubleClick={(event)=>{props.handlePopUpOpen(event, 'createNew', data.row.original)}}>
								{data.row.original.profession}
								</div>)
						}
					},
					{header: 'Qualification', 
					  cell : (data)=>{
						return (<div style={{cursor:'pointer'}}
						onDoubleClick={(event)=>{props.handlePopUpOpen(event, 'createNew', data.row.original)}}>
							{data.row.original.qualifications?.map(qualification=> `${qualification}, `)}
							</div>)
					}},
					{header: 'Visibility', 
					  cell : (data)=>{
						return (<div style={{cursor:'pointer'}}
						onDoubleClick={(event)=>{props.handlePopUpOpen(event, 'visibility', data.row.original)}}>
							{data.row.original.visibility}
							</div>)
					}},
				]}
				/>

            <PopUp
				visible={props.isPopUpVisible && props.popUp=='createNew'}
				top={props.popUpPosition.top}
				left={props.popUpPosition.left}
				handleOutSideClick={props.handlePopUpClose}>
				<NewEligibilityPopUp
				  profession = {props.selectedRow? props.selectedRow.profession : ''}
				  qualifications = {props.selectedRow? props.selectedRow.qualifications : []}
				  handleCancel={props.handlePopUpClose}
				  handleSave={props.handlePopUpSave}
				/>
			</PopUp>
			<PopUp
				visible={props.isPopUpVisible && props.popUp=='visibility'}
				top={props.popUpPosition.top}
				left={props.popUpPosition.left}
				handleOutSideClick={props.handlePopUpClose}>
				<SelectionPopUp 
				  data = {[{"_id":1, "visibility" : 'active'}, {"_id":2, "visibility" : 'disabled'}]}
				  optionKey= {"visibility"}
				  title={"Visibility"}
				  isSearchEnabled={false}
				  height= {"140px"}
				  width= {"200px"}
				  handleCancel ={props.handlePopUpClose}
				  handleSave={(visibility)=>{props.handlePopUpSave(visibility)}}/>
			</PopUp>
			<PopUp
				visible={props.isPopUpVisible && props.popUp=='setting'}
				top={props.popUpPosition.top}
				left={props.popUpPosition.left-100}
				handleOutSideClick={props.handlePopUpClose}>
				<div>
                    <div className="settingOption" onClick={()=>props.handlePopUpSave()}>Delete</div>
				</div>
			</PopUp>
		
        </div>
    )
}

export default EligibilityView