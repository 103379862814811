

import { Skeleton } from '@mui/joy'
import  './style.css'

function ImpressionsContainer(props){

    return <div className="d-flex flex-column flex-grow-1 border rounded bg-white impressionContainer align-items-center p-2">
                <div className="d-flex flex-column w-100">
                    <span className="title w-100">{props.title}</span>
                    <span className="subTitle w-100">{props.subTitle}</span>
                </div>
                <div className={
                  props.funnel?
                  "d-flex flex-grow-1 border rounded trapaziod align-items-center w-100"
                  : "d-flex flex-grow-1 border rounded align-items-center w-100 bg-transparent"
                  }  style={{backgroundColor:'#efefef', padding:1}} >
                  {!props.isLoading? 
                    (props.funnel? 
                      <div className="trapaziod mt-auto d-flex flex-column h-100">
                      <div className='bg-light flex-grow-1'>
                          <div className='content'>
                            {props.child1} 
                          </div> 
                      </div>
                      <div className='bg-white flex-grow-1'>
                      <div className='content'>
                          {props.child2} 
                      </div> 
                      </div>
                      <div className='bg-light flex-grow-1'>
                          <div className='content'>
                            {props.child3} 
                          </div> 
                      </div>
                      <div className='bg-white flex-grow-1'>
                          <div className='content'>
                            {props.child4} 
                          </div> 
                      </div>
                      <div className='flex-grow-1' style={{backgroundColor:'#F3FBFF'}}>
                        <div className='content'>
                          {props.child5}
                        </div> 
                      </div>
                      </div>
                    : <div style={{width:'100%', height:'100%', padding:'8px', justifyContent:"space-between"}}>
                        {props.child1}
                    </div>)
      
                  :
                  <div className="trapaziod mt-auto d-flex flex-column h-100 trapaziod">
                      {[1,2,3,4,5].map(()=>{

                        return <div className='bg-light flex-grow-1'>
                            <div className='content'>
                              <Skeleton variant="rectangular" width="100%" height="95%">
                              </Skeleton> 
                            </div> 
                        </div>
                      })}
                      
                  </div>}
        </div>
      </div>
}


export default ImpressionsContainer
