import { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import MailContentView from './view';
import { useLocation } from 'react-router-dom';

import InnerSidebarNavigation from '@components/InnerSidebarNavigation';
import GoBackArrow from '@assets/sidebarNavigation/goBackArrow.png';

import * as ArticleServices from '@services/Articles';
import * as ArticleActions from '@redux/actions/Articles';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { decryptText } from '@library/enc-dec';
import { current, produce } from 'immer';
import { useNavigate } from 'react-router-dom';

import * as EmailTemplateServices from '@services/EmailTemplate';


// signUp, coursePurchased, reminderVideoCompletion, reminderExam, 
//reminderPendingFees, bssFormFilling, newCourseCreation 
const emailTypes = {
	"signUp" : [
	  {name: 'Country Code', text: '#countryCode', placeholder: '+91'},
	  {name: 'Contact Number', text: '#contactNumber', placeholder: '9898989898'},
	  {name: 'Country Name', text: '#countryName', placeholder:'India'},
	],
	"coursePurchased" : [
	  {name: 'Name', text: '#name', placeholder: 'John Travor'},
	  {name: 'Contact Number', text: '#contactNumber', placeholder: '9898989898'},
	  {name: 'Purchase Title', text: '#purchaseTitle', placeholder: 'C1, C2, C3 ...'},
	  {name : 'Tracsaction Id', text: '#paymentId', placeholder: 'P12345'},
	  {name : 'Payment Method', text: '#paymentMethod', placeholder: 'online'},
	],
	"reminderVideoCompletion" : [
		{name: 'Name', text: '#name', placeholder: 'John Travor'},
		{name: 'Email', text: '#email', placeholder: 'john.travor@gmail.com'},
		{name: 'Series Title', text: '#seriesTitle', placeholder: 'SeriesA'},
	],
	"reminderExam" : [
		{name: 'Name', text: '#name', placeholder: 'John Travor'},
		{name: 'Email', text: '#email', placeholder: 'john.travor@gmail.com'},
		{name: 'Exam Title', text: '#examTitle', placeholder: 'Exam set-A'},
		{name: 'Course Title', text: '#courseTitle', placeholder: 'Child Care And Parenting'},
	],
	"bssFormFilling" : [
		{name: 'Name', text: '#name', placeholder: 'John Travor'},
		{name: 'Email', text: '#email', placeholder: 'john.travor@gmail.com'},
	],
	"reminderPendingFees" : [
		{name: 'Name', text: '#name', placeholder: 'John Travor'},
		{name: 'Email', text: '#email', placeholder: 'john.travor@gmail.com'},
		{name: 'Course Title', text: '#courseTitle', placeholder: 'Child Care And Parenting'},
		{name: 'Paid Amount', text: '#paidAmount', placeholder: '$1000'},
		{name: 'Pending Amount', text: '#pendingAmount', placeholder: '$200'},
	],
	"newCourseCreation" : [
		{name: 'Name', text: '#name', placeholder: 'John Travor'},
		{name: 'Email', text: '#email', placeholder: 'john.travor@gmail.com'},
		{name: 'Course Title', text: '#courseTitle', placeholder: 'Child Care And Parenting'},
	],
	"resultReminder" : [
		{name: 'Name', text: '#name', placeholder: 'John Travor'},
		{name: 'Email', text: '#email', placeholder: 'john.travor@gmail.com'},
		{name: 'Course Title', text: '#courseTitle', placeholder: 'Child Care And Parenting'},
	],
  
  }
  

function DynamicDetails(props) {
	const navigate = useNavigate();
	const location = useLocation();
	let { id } = useParams();
	const emailQuillRef = useRef();

	const [emailName, setEmailName] =useState('');
	const [subject, setSubject] =useState('');
	const [description, setDescription] =useState('');
	const [emailContent, setEmailContent] =useState('');

	const [selectedType, setSelectedType] = useState(Object.keys(emailTypes)[0]);
	const [template, setTemplate] = useState('');
	const [isFormSaving, setIsFormSaving] = useState(false)

	//Failsafe if no data received from navigation
	let navigationData = [
		{
			title: 'Back to Articles',
			route: '/dynamic',
			icon: GoBackArrow,
		},
	];
	if (location.state && location.state.finalNavigationData) {
		navigationData = location.state.finalNavigationData;
	}


	useEffect(() => {
		if (emailQuillRef.current) {
		  const toolbar = emailQuillRef.current;
		//   toolbar.innerHTML = '<button class="ql-addVariableButton">Add variable</button>';
		//   toolbar.querySelector('.ql-addVariableButton').addEventListener('click', toggleDropdown);
		}
		if(id)
		{
			getEmailTemplateById();
		}
	  }, []);

	  useEffect(()=>{
		setTemplate(emailContent.replace(/#([a-zA-Z0-9]+)/g, (match, p1) => {
		  const found = emailTypes[Object.keys(emailTypes).find(et => emailTypes[et].find(e => e.text === match))];
		  if(found) {
			const val = found.find(e => e.text === match).placeholder;
			return `<span style="font-weight:'bold'">${val}</span>`;
		  }
		  return match;
		}));
	  },[emailContent])
	
	  const addValue = (newValue) => {
		const ed = emailQuillRef.current.getEditor();
		if(ed?.getSelection()?.index > -1) {
		ed.editor.insertText(ed.getSelection().index, ` ${newValue} `);
		setEmailContent(emailQuillRef.current.value)
		}
	  }

	const getEmailTemplateById = async () => {
		try {
			if (id !== undefined) {
				const authToken = decryptText(localStorage.getItem('aEmediat'));
				const response = await EmailTemplateServices.getEmailTemplate(id, authToken);
				if (response.success) {
					console.log(response)

					setEmailName(response.data.internalTitle)
					setEmailContent(response.data.templateBody)
					setDescription(response.data.internalDescription)
					setSelectedType(response.data.scenario)
					setSubject(response.data.subject)

					return;
				}
				alert(response.message);
			}
		} catch (err) {
			console.log('Error coming while fetching article details');
		}
	};


	const handleSubmit = async () => {
		try {
			    setIsFormSaving(true)
				const authToken = decryptText(localStorage.getItem('aEmediat'));
				const payload = {
					internalTitle: emailName,
					internalDescription: description,
					subject: subject,
					templateBody: emailContent,
					scenario: selectedType, // signUp, coursePurchased, reminderVideoCompletion, reminderExam, reminderPendingFees, bssFormFilling, newCourseCreation (optional)
					templateId: id?id:'' 
				}
				const response = await EmailTemplateServices.updateEmailTemplate(payload, authToken);
				if (response.success) {
					alert('Email Template Updated Successfully');
					navigate(`/DynamicMail/${response.data._id}`);
					
					setIsFormSaving(false)
				}
				
			    setIsFormSaving(false)

		} catch (err) {
			alert(err.message);
			
			setIsFormSaving(false)
		}
	};

	const handleCancle = () => {
		navigate('/dynamic?tab=articles');
	};

	
	const handleChange=(value, field)=>{
		if(field ==='emailName'){
			setEmailName(value)
		}else if(field ==='subject'){
			setSubject(value)
		}else if(field ==='description'){
			setDescription(value)
		}else if(field ==='emailContent'){
			setEmailContent(value)
		}
	} 

	

	return (
		<>
			<InnerSidebarNavigation navigationData={navigationData} />
			<MailContentView
			    emailName = {emailName}
				subject={subject}
				description={description}
				emailContent={emailContent}
				emailQuillRef={emailQuillRef}
				selectedType={selectedType}
				template={template}
				emailTypes={emailTypes}
				setSelectedType={setSelectedType}
				addValue={addValue}
				handleCancle={handleCancle}
				handleSubmit={handleSubmit}
				handleChange={handleChange}
				isFormSaving={isFormSaving}
			/>
		</>
	);
}

const mapStateToProps = (state) => {
	return {
		selectedArticle: state.articles.selectedArticle,
	};
};

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			updateSelectedArticle: ArticleActions.updateSelectedArticle,
			resetArticle: ArticleActions.resetArticle,
		},
		dispatch
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(DynamicDetails);
