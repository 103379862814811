import React, { useEffect, useState, useRef } from "react";

import SeriesStatusPopUpView from "./view";
import * as UserPagesServices from "@services/UserPages";
import * as SeriesServices from "@services/Series";
import * as UsersServices from "@services/Users";
import Swal from "sweetalert2";

import { decryptText } from "@library/enc-dec";

function SeriesStatusPopUp(props) {
  const [disableSeriesDurationSaveBtn, setDisableSeriesDurationSaveBtn] =
    useState(true);
  const [allSeries, setAllSeries] = useState([]);
  const [selectedSeries, setSelectedSeries] = useState(null);
  const [newSeriesDuration, setNewSeriesDuration] = useState("");
  const [seriesDurationInDays, setSeriesDurationInDays] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    getAllSeries();
  }, []);

  const getAllSeries = async () => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      const payload = {
        pageNo: 0,
        limit: 1000,
        isSorted: true,
      };
      const response = await SeriesServices.getAllSeries(payload, authToken);
      setAllSeries(response.data);
      // setSelectedSeries(response.data[0]._id)
    } catch (err) {
      console.log("Error coming while getting series", err);
    }
  };

  // To close the exam status popup container
  const handleCancelBtn = () => {
    setNewSeriesDuration("");
    setError("");
    setSeriesDurationInDays("");
    setDisableSeriesDurationSaveBtn(true);
    props.handleCancel();
  };

  // Handle exam status save button
  const handleSaveBtn = async () => {
    handlePurchaseStatus();
  };

  const handleSeriesDurationRadioBtns = (event, value) => {
    setNewSeriesDuration(value);
    setDisableSeriesDurationSaveBtn(false);
  };

  const handleSeriesDurationInDays = (event) => {
    if (event.target.value !== "") {
      setSeriesDurationInDays(Number(event.target.value));
    }
  };

  const handlePurchaseStatus = async () => {
    try {
      if (newSeriesDuration === "In Progress") {
        if (seriesDurationInDays === "") {
          setError("If in days selected then days cant be left empty");
          return;
        }
      }

      let authToken = decryptText(localStorage.getItem("aEmediat"));
      let payload = {
        purchaseIds: props.purchaseIds,
        seriesIds:
          props.seriesIds && props.seriesIds.length > 0
            ? props.seriesIds
            : [selectedSeries],
        seriesStatus: {
          days: seriesDurationInDays ? seriesDurationInDays : "NA", // active => enrolled ( Must Required for this statuses )
          status: newSeriesDuration, // active => enrolled => disabled => lifetime
        },
      };

      const response = await UsersServices.updateSeriesStatusForUser(
        payload,
        authToken
      );

      if (response.success) {
        Swal.fire({
          icon: "success",
          title: `Series status updated`,
          showConfirmButton: false,
          timer: 2500,
        });

        handleCancelBtn();
        props.handleSave();
      } else {
        setError(response.message);
        Swal.fire({
          icon: "error",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
      }
    } catch (err) {
      console.log("Error coming while updating purchase status", err);
    }
  };

  const handleSelectedSeries = (event) => {
    setSelectedSeries(event.target.value);
  };

  return (
    <SeriesStatusPopUpView
      props={props}
      handleCancelBtn={handleCancelBtn}
      handleSaveBtn={handleSaveBtn}
      allSeries={allSeries}
      selectedSeries={selectedSeries}
      newSeriesDuration={newSeriesDuration}
      error={error}
      seriesDurationInDays={seriesDurationInDays}
      disableSeriesDurationSaveBtn={disableSeriesDurationSaveBtn}
      handleSelectedSeries={handleSelectedSeries}
      handleSeriesDurationRadioBtns={handleSeriesDurationRadioBtns}
      handleSeriesDurationInDays={handleSeriesDurationInDays}
      seriesIds={props.seriesIds}
    />
  );
}

export default SeriesStatusPopUp;
