import React from 'react';

import BasicTableGrid from '@components/BasicTableGrid';
import moment from 'moment';
import './style.css';


import User from '@components/User';
import DateAndUser from '@components/DateAndUser';

function DetailedTicketInformationView(props) {
	return (
		<>
			<div className='detailedTicketInformationView'>
				<div className="accordion" id="detailedTicketInformationAccordion">
					<div className="accordion-item">
						<h2 className="accordion-header">
							<button className="accordion-button" data-bs-toggle="collapse" data-bs-target="#detailedTicketInformationCollapseOne">
								Lead Information
							</button>
						</h2>
						<div id="detailedTicketInformationCollapseOne" className="accordion-collapse collapse show" data-bs-parent="#detailedTicketInformationAccordion">
							<div className="accordion-body">

								<BasicTableGrid
									gridId='queryMain'
									// gridId='customerCareMain'
									data={props.leadDetails} // Data to be displayed
									fixedCols={0} // How many columns are fixed and not scrolling
									initialSize={[35,162,150,150,150,135,166]}
									// initialSorting={[{ "id": "Date Added", "desc": false }]}
									handleSelect={() => { }}
									length={20}

									onSortChange={(data) => { }
										// props.handleSort(data)
									}
									// columnsOptionPopUpRef={props.columnsOptionPopUpRef}
									columns={[
										{header: 'Agents touched',
											cell: (data) => {
												const info = data.row.original;
												return <div className='ms-4'>{
													info.agentsTouched?.map((agent, index)=> {
													 return <User key={index} imageText={agent.fullName} image={agent.displayPhoto}/>
												})}
												</div>
											}
										},
										{header: 'Conversation under',
											cell: (data) => {
												const info = data.row.original;
												return <div className='ms-4'>{
													info.conversionUnder?.map((agent, index)=> {
													return <User key={index} imageText={agent.fullName} image={agent.displayPhoto}/>
												})}
												</div>
											}
										},
										{header: 'Status',
											cell: (data) => {
												const info = data.row.original;
												return (
													info.status
												);
											}
										},
										{header: 'Type',
											cell: (data) => {
												const info = data.row.original;
												return info.type;
											}
										},
										{header: 'Category',
											cell: (data) => {
												const info = data.row.original;
												return info.category;
											}
										},
										{header: 'Source',
											cell: (data) => {
												const info = data.row.original;
												return info.source;
											}
										},
										{header: 'Created by',
											cell: (data) => {
												const agent = data.row.original.createdBy;
												return <User imageText={agent?.fullName} image={agent?.displayPhoto}/>
											}
										},
										{header: 'Follow up date/time',
											cell: (data) => {
												const info = data.row.original;
												return <DateAndUser
													date={info.followUpAt}
													image={''}
													imageText={''} />
											},
										},
										{header: 'Waiting till',
											cell: (data) => {
												const info = data.row.original;
												return <DateAndUser
													date={info.waitingTillAt}
													image={''}
													imageText={''} />
											},
										},
										{header: 'Open for',
											cell: (data) => {
												const info = data.row.original;
												return (info?.openedForDays);
											}
										},
										{header: 'Closing agent',
											cell: (data) => {
												const agent = data.row.original.lastClosingAgent;
												return <User imageText={agent?.fullName} image={agent?.displayPhoto}/>
											}
										},
										{header: 'Calls done',
											cell: (data) => {
												const info = data.row.original;
												return info.activities?.filter(activity=> activity.activityType==='call').length;
											}
										},
										{header: 'Call did not connect',
											cell: (data) => {
												const info = data.row.original;
												return info.activities?.filter(activity=> activity.activityType==='call' && activity.callStatus==='didNotConnected').length;
											}
										},
										{header: 'Hidden till',
											cell: (data) => {
												const info = data.row.original;
												return ("");
											}
										},
										{header: 'On release',
											cell: (data) => {
												const info = data.row.original;
												return (info.onRelease);
											}
										},
									]
									}

									stopPagination={true}
								/>



							</div>
						</div>
					</div>
				</div>


			</div>
		</>
	);
}

export default DetailedTicketInformationView;
