import React, { useState, useEffect, useRef } from "react";
import { defer, useLocation, useNavigate } from "react-router-dom";

import ApprovalsView from "./view";
import * as FormalRequestService from "@services/FormalRequest";
import Swal from "sweetalert2";
import { decryptText } from "@library/enc-dec";
import {
  getEventElementPosition,
  toggleFullscreen,
  convertToISOFormat,
} from "@helpers/common.js";
import { navigationData } from "./data";
import * as UsersServices from "@services/Users";
import * as FolderServices from "@services/Folders";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as ApprovalActions from "@redux/actions/Approvals";
import { produce } from "immer";

var listOfCheckedRows = [];

function Approvals(props) {
  const fullscreenElementRef = useRef(null);
  const columnsOptionPopUpRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        columnsOptionPopUpRef.current &&
        !columnsOptionPopUpRef.current.contains(event.target)
      ) {
        columnsOptionPopUpRef.current.style.display = "none";
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const [deferredData, setDeferredData] = useState({});

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const limit = Number(queryParams.get("limit"));
  const page = Number(queryParams.get("page"));

  const [disableBulkActionBtn, setDisableBulkActionBtn] = useState(true);

  const [popUp, setPopUp] = useState("");
  const [isPopUpVisible, setIsPopUpVisible] = useState(false);
  const [popUpPosition, setPopUpPosition] = useState({});

  const [selectedRow, setSelectedRow] = useState(null);
  const navigate = useNavigate();
  const [searchFilters, setSearchFilters] = useState(props.filters);

  useEffect(() => {
    getAllApprovals();
  }, [props.filters]);

  const getAllApprovals = async () => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      let payload = {
        pageNo: props.filters.page,
        limit: props.filters.limit,
        isSorted: props.filters.isSorted,
        sortBy: props.filters.sortBy,
        searchText: props.filters.searchText,
        startDate: props.filters.startDate
          ? convertToISOFormat(props.filters.startDate)
          : "",
        endDate: props.filters.endDate
          ? convertToISOFormat(props.filters.endDate)
          : "",
        // adminType: props.filters.adminType,
        requestType: props.filters.requestType,
        location: props.filters.pageType,
        status: props.filters.requestStatus,
      };

      var deferredResponse = defer({
        res: FormalRequestService.getAllFormalRequests(payload, authToken),
      });
      setDeferredData(deferredResponse);
      deferredResponse.data.res
        .then((response) => {
          // props.updateAllBSSForm(response);
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: error.message,
            showConfirmButton: false,
            timer: 2500,
          });
        });
    } catch (err) {
      console.log("Error coming while getting all BSS Forms", err);
    }
  };

  const handleSearch = (data) => {
    let updatedFilters = produce(props.filters, (draft) => {
      draft.page = 0;
      draft.searchText = data.text;
      draft.startDate = data.startDate;
      draft.endDate = data.endDate;
      draft.adminType = searchFilters.adminType;
      draft.requestType = searchFilters.requestType;
      draft.pageType = searchFilters.pageType;
      draft.requestStatus = searchFilters.requestStatus;
    });
    props.updateFilters(updatedFilters);
  };

  //Fn to check and uncheck all checkboxes
  const handleCheckBoxes = (selectedRows) => {
    listOfCheckedRows = selectedRows;
    setDisableBulkActionBtn(selectedRows.length > 0 ? false : true);
  };

  // To sort
  const handleSort = (data) => {
    const mapping = { "Date/Time": "createdAt", "Date Modified": "updatedAt" };
    var id = data[0]?.id ? mapping[data[0].id] : "";
    let updatedFilters = produce(props.filters, (draft) => {
      draft.sortBy = id;
      draft.isSorted = data[0]?.desc ? data[0].desc : false;
    });
    props.updateFilters(updatedFilters);
  };

  const handleColumnBtnClick = (event) => {
    handlePopUpClose();
    columnsOptionPopUpRef.current.style.display = "block";
    var position = getEventElementPosition(event);
    columnsOptionPopUpRef.current.style.top = position.top + "px";
    columnsOptionPopUpRef.current.style.left = position.left - 160 + "px";
    event.stopPropagation();
  };

  // To close the popup container
  const handlePopUpOpen = (event, popUpName) => {
    handlePopUpClose();
    setPopUp(popUpName);
    setIsPopUpVisible(true);
    let position = getEventElementPosition(event);
    setPopUpPosition(position);
    event.stopPropagation();
  };
  const handlePopUpClose = () => {
    setIsPopUpVisible(false);
    columnsOptionPopUpRef.current.style.display = "none";
  };
  // To handleSave buttons on the popup
  const handlePopUpSave = async (data) => {
    if (popUp === "requestStatus" && data[0]._id === "Approve") {
      approveRequest();
    } else if (popUp === "requestStatus" && data[0]._id === "Reject") {
      rejectRequest();
    } else if (popUp === "filterAdmin") {
      data = data.map((obj) => obj._id);
      let updatedFilters = produce(searchFilters, (draft) => {
        draft.adminType = data[0];
      });
      setSearchFilters(updatedFilters);
    } else if (popUp === "filterRequestType") {
      data = data.map((obj) => obj._id);
      let updatedFilters = produce(searchFilters, (draft) => {
        draft.requestType = data[0];
      });
      setSearchFilters(updatedFilters);
    } else if (popUp === "filterPageType") {
      data = data.map((obj) => obj._id);
      let updatedFilters = produce(searchFilters, (draft) => {
        draft.pageType = data[0];
      });
      setSearchFilters(updatedFilters);
    } else if (popUp === "filterStatus") {
      data = data.map((obj) => obj._id);
      let updatedFilters = produce(searchFilters, (draft) => {
        draft.requestStatus = data[0];
      });
      setSearchFilters(updatedFilters);
    }
    handlePopUpClose();
  };

  const handleRedirectToRequestPage = (event, requestId) => {
    //Handle setting default page while navigating
    let finalNavigationData = navigationData;

    navigate("/requestDetails/" + requestId, {
      state: { finalNavigationData },
    });
  };

  const handleStatusClick = (event, status, row) => {
    if (status === "pending") {
      handlePopUpOpen(event, "requestStatus");
      setSelectedRow(row);
    }
  };

  const approveRequest = async () => {
    let requestType = selectedRow.requestType;
    let requestData = selectedRow;
    let payload;
    let approvalFunction;

    if (requestType === "replaceCourse") {
      payload = {
        purchaseIds: requestData.users
          .filter((obj) => obj.purchaseId)
          .map((obj) => obj.purchaseId),
        replacedCourseId: requestData.replacedCourseId,
        folderType: requestData.folderType,
      };
      approvalFunction = UsersServices.replaceCourseForUsers;
    } else if (requestType === "disableAccount") {
      payload = {
        userIds: [...new Set(requestData.users.map((item) => item.userId))],
      };
      approvalFunction = UsersServices.disableUserAccount;
    } else if (requestType === "enableCourse") {
      payload = {
        purchaseIds: requestData.users
          .filter((obj) => obj.purchaseId)
          .map((obj) => obj.purchaseId),
        updateCourseEnableDate: requestData.courseEnableAt,
      };
      approvalFunction = UsersServices.updateCourseEnableDate;
    } else if (requestType === "createBSSForm") {
      payload = {
        users: requestData.users,
        instituteId: requestData.bssInstituteId,
        bssCourseId: requestData.bssCourseId,
        fromYear: requestData.bssFromDate, //Year in number
        toYear: requestData.bssEndDate, // year in number
      };
      approvalFunction = UsersServices.createBSSFormsForUsers;
    } else if (requestType === "deleteCourse") {
      payload = {
        purchaseIds: requestData.requestData.users
          .filter((obj) => obj.purchaseId)
          .map((obj) => obj.purchaseId),
      };
      approvalFunction = UsersServices.deleteCourseForUsers;
    } else if (requestType === "moveToArchive") {

      payload = {
        foldersIdList: [requestData.replaceFolderId],
        usersList: requestData.users,
        currentFolderId: requestData.currentFolderId,
        pageType: "user",
      };

      approvalFunction = FolderServices.moveToFolders;
    } else {
      Swal.fire({
        icon: "error",
        title: "Approval request type is not valid",
        showConfirmButton: false,
        timer: 2500,
      });
      return;
    }
    let authToken = decryptText(localStorage.getItem("aEmediat"));
    const response = await approvalFunction(payload, authToken);
    if (response.success) {
      FormalRequestService.updateFormalRequestStatus(
        { requestId: selectedRow._id, status: "approved" },
        authToken
      );
      Swal.fire({
        icon: "success",
        title: response.message,
        showConfirmButton: false,
        timer: 2500,
      });

      getAllApprovals()
    } else {
      Swal.fire({
        icon: "error",
        title: response.message,
        showConfirmButton: false,
        timer: 2500,
      });
    }
  };

  const rejectRequest = async () => {
    let authToken = decryptText(localStorage.getItem("aEmediat"));
    const response = await FormalRequestService.updateFormalRequestStatus(
      { requestId: selectedRow._id, status: "rejected" },
      authToken
    );
    if (response.success) {
      Swal.fire({
        icon: "success",
        title: response.message,
        showConfirmButton: false,
        timer: 2500,
      });
      
      getAllApprovals()
    } else {
      Swal.fire({
        icon: "error",
        title: response.message,
        showConfirmButton: false,
        timer: 2500,
      });
    }
  };

  const handleFilterPopUp = async (event, index) => {
    if (index === 0) {
      handlePopUpOpen(event, "filterAdmin", "null");
    } else if (index === 1) {
      handlePopUpOpen(event, "filterRequestType", null);
    } else if (index === 2) {
      handlePopUpOpen(event, "filterPageType", null);
    } else if (index === 3) {
      handlePopUpOpen(event, "filterStatus", null);
    }
  };

  const handleClearFilter = (index) => {
    let updatedFilters = produce(props.filters, (draft) => {
      if (index === 0) {
        draft.searchText = "";
      } else if (index === 1) {
        draft.startDate = "";
        draft.endDate = "";
      } else if (index === 2) {
        draft.adminType = "";
      } else if (index === 3) {
        draft.requestType = "";
      } else if (index === 4) {
        draft.pageType = "";
      } else if (index === 5) {
        draft.requestStatus = "";
      }
    });
    setSearchFilters(updatedFilters);
    props.updateFilters(updatedFilters);
  };

  return (
    <>
      <ApprovalsView
        deferredData={deferredData}
        disableBulkActionBtn={disableBulkActionBtn}
        handleSearch={handleSearch}
        filters={props.filters}
        handleCheckBoxes={handleCheckBoxes}
        handleSort={handleSort}
        // Fullscreen
        fullscreenElementRef={fullscreenElementRef}
        toggleFullscreen={() => toggleFullscreen(fullscreenElementRef)}
        //ColumnButton and popup
        columnsOptionPopUpRef={columnsOptionPopUpRef}
        handleColumnBtnClick={handleColumnBtnClick}
        handleRedirectToRequestPage={handleRedirectToRequestPage}
        // Start: Control Pop Up
        popUp={popUp}
        popUpPosition={popUpPosition}
        isPopUpVisible={isPopUpVisible}
        handlePopUpClose={handlePopUpClose}
        handlePopUpOpen={handlePopUpOpen}
        handlePopUpSave={handlePopUpSave}
        handleStatusClick={handleStatusClick}
        handleFilterPopUp={handleFilterPopUp}
        searchFilters={searchFilters}
        handleClearFilter={handleClearFilter}
      />
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    filters: state.approval.filters,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateFilters: ApprovalActions.updateFilters,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Approvals);
