import React, { useEffect, useState } from 'react';
import SidebarNavigationView from './view';
import { useLocation } from 'react-router-dom';

import HomeIcon from '@assets/sidebarNavigation/home.png';
import ContentIcon from '@assets/sidebarNavigation/content.png';
import SuperAdminIcon from '@assets/sidebarNavigation/superAdmin.png';
import AccounIcon from '@assets/sidebarNavigation/account.png';
import OffeIcon from '@assets/sidebarNavigation/offer.png';
import CommentIcon from '@assets/sidebarNavigation/comment.png';
import NotificatioIcon from '@assets/sidebarNavigation/notification.png';
import UseIcon from '@assets/sidebarNavigation/user.png';
import VerificatioIcon from '@assets/sidebarNavigation/verification.png';
import DynamicPagIcon from '@assets/sidebarNavigation/dynamicPage.png';
import CustomerCarIcon from '@assets/sidebarNavigation/customerCare.png';
import LeadsIcon from '@assets/sidebarNavigation/leads.png';
import CertificateIcon from '@assets/sidebarNavigation/certificate.png';
import BSSIcon from '@assets/sidebarNavigation/bss.png';
import ProfilePlaceholder from '@assets/sidebarNavigation/headerLogo.png';

import superAdmin from '@assets/userRoles/superadmin.png';
import accountDepartment from '@assets/userRoles/accountDepartment.png';
import designDepartment from '@assets/userRoles/designDepartment.png';
import productionDepartment from '@assets/userRoles/productionDepartment.png';
import customerCare from '@assets/userRoles/customerCare.png';

import { decryptText } from '@library/enc-dec';

import { useNavigate } from 'react-router-dom';

import * as LoginServices from '@services/Login';
import * as LoginActions from '@redux/actions/Login';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

function SidebarNavigation(props) {
	const navigate = useNavigate();
	const location = useLocation();
	const [currentUserProfile, setCurrentUserProfile] = useState(null);

	const navigationData = [
		{
			id: 'Home',
			title: 'Home',
			route: '/',
			icon: HomeIcon,
		},
		{
			title: 'Super Admin',
			route: '/superAdmin',
			icon: SuperAdminIcon,
		},
		{
			title: 'Analytics',
			route: '/analytics',
			icon: HomeIcon,
		},
		{
			title: 'Content',
			route: '/content',
			icon: ContentIcon,
		},
		{
			title: 'Users',
			route: '/users',
			icon: UseIcon,
		},
		{
			title: 'Accounts',
			route: '/accounts',
			icon: AccounIcon,
		},
		{
			title: 'Offers',
			route: '/offers',
			icon: OffeIcon,
		},
		{
			title: 'Comments',
			route: '/comments',
			icon: CommentIcon,
		},
		{
			title: 'Notifications',
			route: '/notification',
			icon: NotificatioIcon,
		},
		{
			title: 'Certificates',
			route: '/certificates',
			icon: CertificateIcon,
		},
		{
			title: 'Customer Care',
			route: '/customer-care',
			icon: CustomerCarIcon,
		},
		{
			title: 'Leads',
			route: '/leads',
			icon: LeadsIcon,
		},
		{
			title: 'Dynamic Pages',
			route: '/dynamic',
			icon: DynamicPagIcon,
		},
		{
			title: 'Verification',
			route: '/verification',
			icon: VerificatioIcon,
		},
		{
			title: 'BSS',
			route: '/bss',
			icon: BSSIcon,
		},
	];

	const[currentNavigation, setCurrentNavigation] = useState([])

	const logout = () => {
		window.localStorage.removeItem('aEmediat');
		window.localStorage.removeItem('aEmediatt');
		window.localStorage.removeItem('rEmedi');
		window.localStorage.removeItem('rcEmedi');
		window.localStorage.removeItem('_id');
		navigate('/login');
	};

	useEffect(() => {
		getUserProfileImage();
		getUserAccessData()
	}, []);

	useEffect(()=>{
		if(props.access?.roleCode === 0 )
		{
			setCurrentNavigation(navigationData)
			return
		}

		if(props.access?.pageRights)
		{
			let updatedNavigationData = navigationData.filter(obj=>props.access.pageRights.includes(obj.title))
		    setCurrentNavigation(updatedNavigationData)
			
		}else if(props.access?.roleCode !== 0){
			setCurrentNavigation([])
		}
	},[props.access])

	const getUserProfileImage = () => {
		try {
			if (localStorage.getItem('rEmedi') && localStorage.getItem('rcEmedi')) {
				const eMediRole = decryptText(localStorage.getItem('rEmedi'));
				const eMediRoleCode = localStorage.getItem('rcEmedi');

				if (eMediRole === 'superadmin' && eMediRoleCode === '0') {
					setCurrentUserProfile(superAdmin);
					// var navData = [...currentNavigation]
					// navData.splice(1,0,{title: 'Super Admin',
					// 						route: '/superAdmin',
					// 						icon: SuperAdminIcon,
					// 					})
				    // setCurrentNavigation(navData)
				} else if (eMediRole === 'customerCare' && eMediRoleCode === '1') {
					setCurrentUserProfile(customerCare);
				} else if (eMediRole === 'accountsModerator' && eMediRoleCode === '2') {
					setCurrentUserProfile(accountDepartment);
				} else if (eMediRole === 'designDepartment' && eMediRoleCode === '4') {
					setCurrentUserProfile(designDepartment);
				} else if (eMediRole === 'productionDepartment' && eMediRoleCode === '5') {
					setCurrentUserProfile(productionDepartment);
				}
			}
		} catch (err) {
			console.log('Error coming from getUserProfileImage()');
		}
	};

	const getUserAccessData=async()=>{
		if(!props.access)
		{
		let localToken = localStorage.getItem('aEmediat')
		if(localToken)
		{
			let authToken = decryptText(localToken);
			const response = await LoginServices.getUser(authToken);
			let data=response.data;
			let accessData = {
				userRights:data.userRights,
				bssRights: data.bssRights,
				customerCareRights:data.customerCareRights,
				leadsRights:data.leadsRights,
				pageRights:data['pageRights'],
				role: data.role,
				roleCode: data.roleCode
			}
			props.updateAccess(accessData)
		}
	}
	}

	return (
		<>
			<SidebarNavigationView
			    location={location}
				navigationData={currentNavigation}
				currentUserProfile={currentUserProfile}
				accessData={props.accessData}
				logout={() => {
					logout();
				}}
			/>
		</>
	);
}

function mapStateToProps(state) {
	return {
		access: state.login.access,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			updateAccess: LoginActions.updateAccess,
		},
		dispatch
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(SidebarNavigation);
