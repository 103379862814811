import React, {memo} from 'react';
import DetailedTicketInformationView from './view';

function DetailedTicketInformation(props) {	
	console.log("reloading", props)
	return (
		<>
			<DetailedTicketInformationView 
				{...props}
				/>
		</>
	);
}

export default memo(DetailedTicketInformation);
