import React, { useEffect, useState } from 'react';
import PopUpContainer from '@components/PopUpContainer';
import './style.css';

import TextInputV2 from '@components/TextInputV2';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
//DnD Import
import DnD from '@components/DnD';
import MoveBarIcon from '@assets/common/moveBarIcon.png';
import PlusCircleIcon from '@assets/common/plusCircleIcon.png';
import DeleteIcon from '@assets/common/deleteIcon.png';

function NewEligibilityPopUpView(props) {

	return (
		<PopUpContainer
           title={"Create/Update Eligibility"}
           isSearchEnabled={false}
           searchPlaceholder={""}
           isButtonsEnabled={true}
           style={{width:'378px', height:'383px'}}
           handleSearch={props.handleSearch}
           handleCancel={props.handleCancel}
           handleSave={props.handleSave}
        >
            <div className='d-flex flex-column gap-1 w-100'>
                <TextInputV2 
                    placeholder={"Profession name"}
                    value={props.profession}
                    required={true}
                    onChange={(event)=>{props.handleChange(event.target.value, 'profession')}}
                    size={'small'}
                />  
                <b>Qualifications: </b>
                <DragDropContext onDragEnd={props.onDragEnd}>
                    <Droppable droppableId='dataSet'>
                    {(provided) => (
									<div className="d-flex flex-column gap-4 w-100"
									ref={provided.innerRef} {...provided.droppableProps}>
										{props.qualifications?.map((qualification, index) => (
												<DnD
													kay={index}
													element={{_id:String(index), qualification}}
													index={index}
													newComponent={
                                                        <div className="d-flex gap-2 align-items-center" style={{fontSize:'14px'}}>
                                                            <img className="moveIcon" src={MoveBarIcon} style={{height:'5px'}}></img>
                                                            <TextInputV2 
                                                                placeholder={"Qualification name"}
                                                                value={qualification}
                                                                required={true}
                                                                onChange={(event)=>{props.handleChange(event.target.value, 'qualification', index)}}
                                                                size={'small'}
                                                            /> 
                                                            <img className="addReviewIcon ms-auto" src={PlusCircleIcon}
																onClick={(event) => {
																	props.handleAddQualification(event.target.value,index);
															}}
															/>
															<img className="addReviewIcon" src={DeleteIcon} 
																onClick={(event) => {
																	props.handleDeleteQualification(event, index)
																}}
															/>
                                                        </div>    
                                                    }
                                                    />
                                                ))}
                                                {provided.placeholder}
                                    </div>
                                    
                    )}
                    </Droppable>

                </DragDropContext>
                <center>
					{ props.qualifications?.length >0 ?
                    	'':
						<div className="bg-light w-100 p-3 mt-2">
							<button className="btn btn-dark d-flex gap-2 align-items-center w-50 justify-content-center" 
							style={{fontSize:'14px'}}
							onClick={(event) => {
								props.handleAddQualification(event, null);
							}}>
							<img className="addReviewIcon " src={PlusCircleIcon}
							/>
							Add
							</button>
						</div>
					}
				</center>
                      


            </div>
        </PopUpContainer>
	)
}

export default NewEligibilityPopUpView;
