import { useState, useEffect } from "react";
import "./dynamic.css";

import { Tabs, Tab } from "react-bootstrap";

import Home from "@pages/Dynamic/Home";
import Articles from "@pages/Dynamic/Articles";
import Mails from "@pages/Dynamic/Mails";

import { BrowserRouter as Router, useLocation } from "react-router-dom";

function DynamicView(props) {
  const location = useLocation();
  // const queryParams = new URLSearchParams(location.search);
  // const tab = queryParams.get("tab");
  // const defaultTab = tab === "articles" ? "articles" : "home";
  const selectedTab = props.selectedTab;
  console.log("selected tab in view", selectedTab)
  return (
    <>
      <div
        className="contentPageMainContainer dynamicPageTabs"
        style={{ marginLeft: "230px" }}
      >
        <h4 style={{ marginBottom: "20px", marginLeft: "20px" }}>
          {" "}
          Dynamic Pages{" "}
        </h4>

        <Tabs
          defaultActiveKey={selectedTab}
          id="myTabs"
          onSelect={props.handleTabSelect}
        >
          <Tab eventKey="home" title="Home Page">
            {selectedTab === "home" && (
              <>
                <Home />
              </>
            )}
          </Tab>

          <Tab eventKey="articles" title="Articles">
            {selectedTab === "articles" && <Articles />}
          </Tab>

          <Tab eventKey="mails" title="Mails">
            {selectedTab === "mails" && <Mails />}
          </Tab>
        </Tabs>
      </div>
    </>
  );
}

export default DynamicView;
