import React from "react";

import "./style.css";
import SearchInput from "@components/SearchInput";

function EditPaymentGatewayPopUpView(props) {
  return (
    <div className="paymentPopUpDiv">
      <SearchInput
        value={""}
        placeholder={"Search for payment gateway"}
        onChange={(event) => props.handleSearch(event.target.value)}
      />

      <div className="d-flex flex-column gap-2" style={{ color: "#252525" }}>
        {props.allPaymentGateways?.map((item, index) => {
          if (
            item.paymentGateway
              .toLowerCase()
              .includes(props.searchText?.toLowerCase())
          ) {
            const foundPaymentGateway = props.paymentGateways.find(
              (gateway) => gateway.paymentGateway === item.paymentGateway
            );

            const checked = foundPaymentGateway !== undefined;
            const discountChecked = foundPaymentGateway?.discount != null;
            return (
              <div className="d-flex ms-2 gap-2 align-items-center" key={index}>
                <div className="d-flex  gap-2 align-items-center">
                  <input
                    type="checkbox"
                    checked={checked}
                    onChange={(event) =>
                      props.handleGatewayCheck(item, !checked)
                    }
                  ></input>
                  <span>{item.paymentGateway}</span>
                </div>
                <div
                  className="ms-auto d-flex gap-2 align-items-center"
                  style={{ marginRight: "100px" }}
                >
                  <input
                    type="checkbox"
                    checked={discountChecked}
                    disabled={!checked}
                    onClick={(event) =>
                      props.handleDiscountCheck(item, !discountChecked)
                    }
                  ></input>
                  Extra discount
                  <input
                    type="number"
                    className="popup-form-input w-25"
                    value={foundPaymentGateway?.discount}
                    onChange={(event) => props.handleDiscount(event, item)}
                  />
                  %
                </div>
              </div>
            );
          }
        })}
      </div>

      <div className="d-flex justify-content-end gap-1">
        {props.error && props.error !== "" && (
          <div className="alert alert-danger w-50 text-center me-auto">
            {props.error}
          </div>
        )}
        <button onClick={props.handleCloseBtn} className="closeBtn">
          Cancel
        </button>
        <button onClick={props.handleSaveBtn} className="saveBtn">
          Save
        </button>
      </div>
    </div>
  );
}

export default EditPaymentGatewayPopUpView;
