import './style.css'
import profilePlaceholder from '@assets/common/placeholder.png';
const User = (props)=>{
    let imageSrc = props.image && props.image!=="null" ? props.image : profilePlaceholder;
    return <div className="user-image-container">
                <img src={imageSrc} className="image rounded-circle" />
                <span className="username">{props.imageText}</span>
            </div>
}


export default User