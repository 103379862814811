import ImpressionsContainer from '@components/Analytics/ImpressionsContainer'
import BasicTableGrid from '@components/BasicTableGrid';
import DistributionBar from '@components/Analytics/DistributionBar'
import FilterDate  from '@components/FilterDate';
import FilterIcon from '@assets/common/filter.png';
import DownIcon from '@assets/common/dropDown.png';
import { AgChartsReact } from 'ag-charts-react';
import PopUp from '@components/PopUp';
import SelectionPopUp from '@components/SelectionPopUp';
import PaymentGatewayDetails from '@components/Analytics/PaymentGatewayDetails';

import moment from 'moment';



function LeadsView(props){
    
    return <div className='AdminPageMainContainer bg-white d-flex gap-3 p-4 overflow-scroll'>

              <div className=" d-flex gap-2">
                    <div className='border rounded p-2 d-flex justify-content-center align-items-center' style={{height:'32px'}}
                        onClick={(e)=>props.handlePopUpOpen(e, 'dateSelectPopUp')}
                    >
                        <img style={{height: '15px'}} src={FilterIcon}/>
                    </div> 
                    <div className='filterSelect'><FilterDate title="Start Date" value={props.startDate} 
                    onChange={(event)=>{props.handleChange('startDate', event.target.value)}}/></div>
                    <div className='filterSelect'><FilterDate title="End Date" value={props.endDate} 
                    onChange={(event)=>{props.handleChange('endDate', event.target.value)}} /></div>
                </div>
                <div className="d-flex flex-column gap-2">
                    <div className="row mx-1" style={{height: '500px'}}>
                             
                            <div className={"col-12 border rounded p-0"}>
                            {(props.isLoading)?
                            <div className='w-100 h-100 d-flex'>

                                <div className="spinner-border text-danger m-auto" role="status">
                                <span className="visually-hidden">Loading...</span>
                                </div> 
                            </div>
                            :
                            <div className=' pb-4' style={props.viewGraphBy=='Views'?{height:'87%', justifyContent:'flex-end'}:{height:'100%'}}>
                                    {/* <AgChartsReact options={props.chartOptions} /> */}
                            </div>
                            }
                        </div>

                            
                    </div>
                    <div className="d-flex" style={{height: '400px'}}>
                                        
                        <BasicTableGrid 
                            gridId='AnalyticsOverview'
                            data={props.deferredData} // Data to be displayed
                            fixedCols={2} // How many columns are fixed and not scrolling
                            initialSize = {[35,499,183,258,150,133,262]}
                            initialSorting={[{"id": "Date Added","desc": false}]}
                            handleSelect={(selectedRows) => {
                                props.handleCheckBoxes(selectedRows);
                            }}
                            length={20}
                            onSortChange={(data) => {}
                                // props.handleSort(data)
                            }
                            columnsOptionPopUpRef={props.columnsOptionPopUpRef}
                            isColorEnabled={true}
                            isNavigationHidden={true}
                            columns={[
                            {header: "Columns",
                                cell: (data) => {
                                const info = data.row.original;
                                return (<div className="d-flex gap-2">
                                                    <div className="thumbnailContainer rounded bg-light" 
                                                    style={{backgroundImage:`url(${info.courseThumbnailUrl})`}}>
                                                    </div>
                                            <div className="d-flex flex-column justify-content-center gap-2 ms-2">
                                                <div>{info?.courseTitle} </div>
                                            </div>
                                        </div>);
                                },
                            },
                            {header: 'Page Views', 
                            cell: (data) => {
                                const info = data.row.original;
                                return <div>{info.pageViews}</div>}
                            },
                            {header: 'Unique Page Views', 
                            cell: (data) => {
                                const info = data.row.original;
                                return <div>{info.uniquePageViews}</div>}
                            },
                            {header: 'Sign-ups form page', 
                            cell: (data) => {
                                const info = data.row.original;
                                return <div>{info.signUpsFromPage}</div>}
                            },
                            {header: 'Views to conversion rate', 
                            cell: (data) => {
                                const info = data.row.original;
                                return <div>{info.viewsToConversionRate}</div>}
                            },
                            {header: 'Sign-up to conversion rate', 
                            cell: (data) => {
                                const info = data.row.original;
                                return <div>{info.signupToConversionRate}</div>}
                            },
                            {header: 'Payment Gateway', 
                            cell: (data) => {
                                const info = data.row.original;
                                return <DistributionBar 
                                        data = {info.paymentMethods}
                                      />}
                            },
                            {header: 'Net Amount', 
                            cell: (data) => {
                                const info = data.row.original;
                                return <div>{info.netPrice}</div>}
                            },
                            {header: 'Gross Amount', 
                            cell: (data) => {
                                const info = data.row.original;
                                return <div>{info.grossPrice}</div>}
                            },
                            {header: 'Payment Gateway Charges', 
                            cell: (data) => {
                                const info = data.row.original;
                                return <div>{info.paymentGatewayFees}</div>}
                            },
                            {header: 'Bank Settled Amount', 
                            cell: (data) => {
                                const info = data.row.original;
                                return <div>{info.settlementFees}</div>}
                            }
                            
                            ]}

                        />
                </div>
                </div>
                {props.popUp === "dateSelectPopUp"  &&<PopUp visible={props.isPopUpVisible}
				top={180}
				left={280}
				handleOutSideClick={props.handlePopUpClose}>
				<div style={{height: 'auto', overflow:'hidden'}}>
					{props.mainDateOption.map((option, index) => (
						<div
							key={index}
							onClick={(e) => option !=='Select' ?props.handlePopUpSave(e, option):props.handlePopUpOpen(e, 'dateYearPopUp')}
							className='settingOption d-flex'>
							{option}
							<img className='ms-auto' src={option.img} style={{height:'12px'}}/>
						</div>
					))}
				</div>
                </PopUp>}
                {props.popUp === "dateYearPopUp"  &&<PopUp visible={props.isPopUpVisible}
                    top={180}
                    left={280}
                    handleOutSideClick={props.handlePopUpClose}>
                    <div style={{height: 'auto', overflow:'hidden'}}>
                        {props.years.map((option, index) => (
                            <div
                                key={index}
                                onClick={(e) => props.handlePopUpOpen(e, 'dateMonthPopUp', option)}
                                className='settingOption d-flex'>
                                {option}
                                <img className='ms-auto' src={option.img} style={{height:'12px'}}/>
                            </div>
                        ))}
                    </div>
                </PopUp>}
                {props.popUp === "dateMonthPopUp"  &&<PopUp visible={props.isPopUpVisible}
                    top={180}
                    left={280}
                    handleOutSideClick={props.handlePopUpClose}>
                    <div style={{height: 'auto', overflow:'hidden'}}>
                        {props.months.map((option, index) => (
                            <div
                                key={index}
                                onClick={(e) => props.handlePopUpSave(e, index)}
                                className='settingOption d-flex'>
                                {option}
                                <img className='ms-auto' src={option.img} style={{height:'12px'}}/>
                            </div>
                        ))}
                    </div>
                </PopUp>}
    </div>
}

export default LeadsView