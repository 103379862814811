import React, { useEffect, useState, useRef } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { useParams } from "react-router-dom";
import ActivityView from "./view";
import ErrorView from "@components/ErrorView";
import { getEventElementPosition } from "@helpers/common.js";
import * as FolderServices from "@services/Folders";
import Swal from "sweetalert2";
import { decryptText } from "@library/enc-dec";
import * as UserActions from "@redux/actions/Users";
import { checkLoginAndRole } from "@helpers/Login/";

var roleCode, role;

function Activity(props) {
  var { id } = useParams();

  const [purchasesTabSelected, setPurchasesTabSelected] = useState(true);
  const [videoHistoryTabSelected, setVideoHistoryTabSelected] = useState(false);
  const [commentsTabSelected, setCommentsTabSelected] = useState(false);
  const [seriesTabSelected, setSeriesTabSelected] = useState(false);
  const [testsTabSelected, setTestsTabSelected] = useState(false);
  const columnsOptionPopUpRef = useRef(null);
  const [popUpPosition, setPopUpPosition] = useState({});
  const [popUp, setPopUp] = useState("");
  const [isPopUpVisible, setIsPopUpVisible] = useState(false);
  const [folders, setFolders] = useState([]);
  const [seriesIds, setSeriesIds] = useState([]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        columnsOptionPopUpRef.current &&
        !columnsOptionPopUpRef.current.contains(event.target)
      ) {
        columnsOptionPopUpRef.current.style.display = "none";
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    [role, roleCode] = checkLoginAndRole("users");
    getAllFolders();
  }, []);

  const getAllFolders = async () => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      const payload = {
        pageNo: "0",
        limit: "10000",
        isSorted: true,
        sortBy: "title",
        searchText: "",
        visibility: "active",
      };

      const response = await FolderServices.getAllFolders(payload, authToken);
      var allFolders = response.data
        .map((folder) => (folder.pageType === "user" ? folder : null))
        .filter(Boolean);

      if (roleCode === 2 || roleCode === "2") {
        if (props.access?.userRights) {
          allFolders = allFolders.filter((folder) =>
            props.access.userRights.includes(folder._id)
          );
        } else {
          allFolders = [];
        }
      }
      setFolders(allFolders);
    } catch (err) {
      console.log("Error coming while fetching all folders", err);
    }
  };

  const handleTabSelect = (eventKey) => {
    resetTabSelection();
    if (eventKey === "purchases") {
      setPurchasesTabSelected(true);
    } else if (eventKey === "videoHistory") {
      setVideoHistoryTabSelected(true);
    } else if (eventKey === "comments") {
      setCommentsTabSelected(true);
    } else if (eventKey === "series") {
      setSeriesTabSelected(true);
    } else if (eventKey === "tests") {
      setTestsTabSelected(true);
    }
  };

  const resetTabSelection = () => {
    setPurchasesTabSelected(false);
    setVideoHistoryTabSelected(false);
    setCommentsTabSelected(false);
    setSeriesTabSelected(false);
    setTestsTabSelected(false);
    props.updateSelectedPurchaseIds([]);
    setSeriesIds([]);
  };

  const handlePopUpOpen = (event, popUpName, data) => {
    handlePopUpClose();
    setPopUp(popUpName);
    setIsPopUpVisible(true);

    if (popUpName === "seriesStatus" && data) {
      const seriesId = data?.content?.seriesId;
      props.updateSelectedPurchaseIds([data._id]);
      setSeriesIds([seriesId]);
    }

    if (popUpName === "seriesStatus" && !data) {
      setSeriesIds([]);
    }

    let position = getEventElementPosition(event);
    setPopUpPosition(position);
    event.stopPropagation();
  };

  const handlePopUpClose = () => {
    setIsPopUpVisible(false);
    if (columnsOptionPopUpRef.current) {
      columnsOptionPopUpRef.current.style.display = "none";
    }
  };

  const moveToFolder = async (data) => {
    try {
      let authToken = decryptText(localStorage.getItem("aEmediat"));
      var payload = {
        foldersIdList: data,
        usersList: id ? [id] : [],
        currentFolderId: props.tab._id == "All" ? null : props.tab._id,
        pageType: "user",
      };
      const response = await FolderServices.moveToFolders(payload, authToken);

      if (response.success) {
        Swal.fire({
          icon: "success",
          title: `Moved Successfully`,
          showConfirmButton: false,
          timer: 2500,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: response.message,
          showConfirmButton: false,
          timer: 2500,
        });
      }
    } catch (err) {
      console.log("Error coming while moving to folders", err);
    }
  };

  const handlePopUpSave = async (data, event) => {
    try {
      handlePopUpClose();
      if (popUp === "moveTo") {
        moveToFolder(data);
      }
    } catch (err) {
      console.log("Error coming while saving popup data", err);
    }
  };

  return (
    <>
      {id ? (
        <ActivityView
          purchasesTabSelected={purchasesTabSelected}
          videoHistoryTabSelected={videoHistoryTabSelected}
          commentsTabSelected={commentsTabSelected}
          seriesTabSelected={seriesTabSelected}
          testsTabSelected={testsTabSelected}
          handleTabSelect={handleTabSelect}
          handlePopUpOpen={handlePopUpOpen}
          handlePopUpClose={handlePopUpClose}
          isPopUpVisible={isPopUpVisible}
          popUp={popUp}
          popUpPosition={popUpPosition}
          userId={id}
          folders={folders}
          handlePopUpSave={handlePopUpSave}
          selectedPurchaseIds={props.selectedPurchaseIds}
          seriesIds={seriesIds}
        />
      ) : (
        <ErrorView text={"Please Select/Create User "} />
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    tab: state.tabs.UserTab,
    userDetails: state.user.userDetails,
    access: state.login.access,
    selectedPurchaseIds: state.user.selectedPurchaseIds,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateSelectedPurchaseIds: UserActions.updateSelectedPurchaseIds,
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(Activity);
