

export const getPageViews = async (payload, authToken) => {
    try {
        const {startDate, endDate, viewType} = payload
        return await fetch(`${process.env.REACT_APP_BASE_URL}/getPageViews?startDate=${startDate}&endDate=${endDate}&viewType=${viewType}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: authToken
            },
        })
            .then((res) => res.json())
            .catch((error) => error.message);
    } catch (error) {
        console.error('Coming from Analysis services : getPageViews()', error.message);
        if (error.message === 'Network Error') {
            return error.message;
        }
    }
}

export const getRevenue = async (payload, authToken) => {
    try {
        const {startDate, endDate, revenueType} = payload
        return await fetch(`${process.env.REACT_APP_BASE_URL}/getRevenue?startDate=${startDate}&endDate=${endDate}&revenueType=${revenueType}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: authToken
            },
        })
            .then((res) => res.json())
            .catch((error) => error.message);
    } catch (error) {
        console.error('Coming from Analysis services : getRevenue()', error.message);
        if (error.message === 'Network Error') {
            return error.message;
        }
    }
}



export const getLeadReport = async (payload, authToken) => {
    try {
        const {startDate, endDate} = payload
        return await fetch(`${process.env.REACT_APP_BASE_URL}/getLeadReport?startDate=${startDate}&endDate=${endDate}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: authToken
            },
        })
            .then((res) => res.json())
            .catch((error) => error.message);
    } catch (error) {
        console.error('Coming from Analysis services : getLeadReport()', error.message);
        if (error.message === 'Network Error') {
            return error.message;
        }
    }
}